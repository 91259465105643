import * as firebase from "firebase/app";
import 'firebase/firestore';
import "firebase/auth";
const config = require("./app_config.json")

// Firebase 
export const firebase_app = firebase.initializeApp({
  
    apiKey: "AIzaSyDUEDVHp-EpDm_D4ZdRoCgpnog-Z7no7WI",
    authDomain: "gbncotiza.firebaseapp.com",
    projectId: "gbncotiza",
    storageBucket: "gbncotiza.appspot.com",
    messagingSenderId: "939189695447",
    appId: "1:939189695447:web:306bd2eafa8a55564fd192",
    measurementId: "G-BF53D9MTB8"

});

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new  firebase.auth.TwitterAuthProvider();
export const githubProvider = new  firebase.auth.GithubAuthProvider();
export const db =  firebase.firestore();


// Auth0
export const auth0 = ({
    domain : config.auth0.domain,
    clientId : config.auth0.clientID,
    redirectUri : config.auth0.redirectUri
})

// Jwt
export const Jwt_token = config.jwt_token

