import React,{useState,useEffect} from 'react';
import man from '../assets/images/dashboard/user.png';
import {Container,Row,Col,CardBody,Form,FormGroup,Input,Label,Button,Modal, ModalHeader, ModalBody, ModalFooter,CardHeader} from 'reactstrap'
import {firebase_app} from '../data/config'
import {handleResponse} from  '../services/fack.backend'
import {Login,LOGIN,YourName,Password,RememberMe,SignIn,SignUp, Operations} from '../constant';
import { toast } from 'react-toastify';
import {useHistory, Link } from 'react-router-dom';
import Axios from 'axios';
import logo from '../assets/images/logo.png';
import { useDispatch } from 'react-redux';
import {SetCurrentUser} from '../redux/common/actions'
import {useSelector} from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead';
import SweetAlert from 'sweetalert2'
import { Search} from 'react-feather';

// import {useForm} from 'react-hook-form'
// import TooltipForm from './tooltipForm'
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';




const Logins = (props) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const[alert,setalert] = useState(false)

  const [modal2, setModal2] = useState(false);
  const toggle2 = () =>{ 
     setOpen(false)
    setTimeout(() => {  
    setModal2(!modal2);  
  }, 100);
  }
  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);
  
  const modales = () =>{

      if(category == ""){
        SweetAlert.fire({title:"No se encontraron resultados", text:"Escribe en el campo para buscar una categoria", icon:"error"});        
      }
      else{
        
      setModal3(!modal3)
      Axios.get("https://fede633baa69.ngrok.io/categoria", {
          params: {
            foo: "%"+category+"%"
          }}).then(res => {
          res.data.forEach(data =>{
            const opcion = data.Codigo + " - " + data.Descripcion_Español
            setOptions(opciones=>[...opciones, opcion])
          })
        })
      setTimeout(() => {     
        setModal3(false);
        setModal2(!modal2)
    }, 2500);
    setTimeout(() => {  
    setOpen(true)
  }, 3000);
  }
  
}

  const [texsto, settexsto] = useState("");
  const seleccionando = () =>{
    setOpen(false)
    setModal3(!modal3)
    setModal2(false)
    setTimeout(() => {
    setModal3(false)
   
    setalert(true)
    autocompleteValues.forEach(function(elemento, indice, array) {
    console.log(elemento.title, indice)
    
})
    
    setcategoriareg(autocompleteValues.join())
    document.getElementById('hola1').style.display = 'none';
    document.getElementById('hola2').style.display = 'none';
    settexsto("COMPLETADO")
    SweetAlert.fire({title:"¡Felicidades!", text:"Has completado la seccion de categorias de producto, por favor continua con el registro", icon:"success"});
  }, 2500);
  }


  const [autocompleteValues, setAutocompleteValues] = useState([]);

  const handleChange = (event, value) => {

    setAutocompleteValues(value);
  };

  


  const multiple = false
    const [options,setOptions] = useState([])

    const [category,setcategory] = useState("");

     
    // useEffect(() => {
        
    // },[])
    
    
      const dispatch = useDispatch();
      const history = useHistory();
      const [email, setEmail] = useState("");
      const [password, setPassword] = useState("");
      const [loading,setLoading] = useState(false) 
      const currentUser= useSelector(state => state.Common.currentUser)
      
      const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );
    const [name, setName] = useState(
      localStorage.getItem('Name')
    );
      const [isuser, setisuser] = useState(localStorage.getItem("isUser"));
    
      const toggleform = () => {
        document.querySelector('.cont').classList.toggle('s--signup');
       }
       useEffect(() => {
        localStorage.setItem('profileURL', value);
        localStorage.setItem('Name', name);
        localStorage.setItem('isUser', isuser);
    }, [value,name,isuser]);
    
      const loginAuth = async (event) => {
        
    
        Axios.post('https://14c97a96305c.ngrok.io/gbn/login',
      {correo: email,
      password: password,}, 
    ).then((response) => { 
      
       if (response.data.message){
        setTimeout(() => {
          setValue(man);
          toast.error("Oppss.. el correo y la contraseña no son correctos o no coinciden");
      }, 500);


       }else{
         sessionStorage.setItem('currentuser', JSON.stringify(response.data[0]))

         
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: ({ email, password })
        };
        return fetch('/users/authenticate', requestOptions)
        .then(handleResponse)
        .then(user => {
           setValue(man);
           setisuser("true");
           setTimeout(()=>{
            
            // window.location.href = `${process.env.PUBLIC_URL}/#/widgets/General`
           }, 500)
          return user;
        });
       }
    });
        event.preventDefault();
        setLoading(true)
          await firebase_app.auth().signInWithEmailAndPassword(email, password).then(function () {
                  setTimeout(() => {
                    setValue(man);
                    setisuser("true");
                    // history.push(`${process.env.PUBLIC_URL}/widgets/General`);
                    window.location.href = (`${process.env.PUBLIC_URL}/#/widgets/Bienvenida`)
                    window.location.reload();
                  }, 900);
              
          
                  })
      }


  const [correoreg, setcorreoreg] = useState("");
  const [numeroreg, setnumeroreg] = useState("");
  const [nombrereg, setnombrereg] = useState("");
  const [apellidoreg, setapellidoreg] = useState("");
  const [empresareg, setempresareg] = useState("");
  const [passwordreg, setpasswordreg] = useState("");
  const [rolereg, setrolereg] = useState("");
  const [categoriareg, setcategoriareg] = useState ("");
  
  Axios.defaults.withCredencials = true;

  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  var fecha = hoy.getDate() + '-' + ( hoy.getMonth() + 1 ) + '-' + hoy.getFullYear();
  var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
  const fechaYHora = 'Fecha: ' + fecha + ' ' + 'Hora: ' + hora;
  
  


  const registro = () => {
    if(numeroreg == ""){
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }
    if(nombrereg == ""){
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }
    if(apellidoreg == ""){ 
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }
    if(empresareg ==""){
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }
    if(rolereg ==""){
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }
    if(correoreg == ""){
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }
    if(passwordreg ==""){
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }

    else{
      firebase_app.auth().createUserWithEmailAndPassword(correoreg, passwordreg).then(function () {
        setisuser("true")
        Axios.post('https://14c97a96305c.ngrok.io/gbn/registro',
              {correo: correoreg, 
              password: passwordreg,
              numero: numeroreg,
              nombre: nombrereg,
              primerapellido:apellidoreg,
              empresa: empresareg,
              role: rolereg,
              categoria: categoriareg,
              Fechadecreacion: fechaYHora,
        
            }).then(async(response) => {
              if(response.data.message){
                toast.warn("Introduce todos los datos correctamente", {
                  autoClose: 5000
                  })
              }else{
        
                await firebase_app.auth().signInWithEmailAndPassword(correoreg, passwordreg).then( (usercredential) => { 
                  Axios.post('https://14c97a96305c.ngrok.io/gbn/login',
                  {correo: correoreg,
                  password: passwordreg,}, 
                ).then((response) => {
                  sessionStorage.setItem('currentuser', JSON.stringify(response.data[0])) 
                })  
                  setTimeout(() => {
                    setValue(man);
                    setisuser("true");
                    // history.push(`${process.env.PUBLIC_URL}/widgets/General`);
                    window.location.href = (`${process.env.PUBLIC_URL}/#/widgets/Bienvenida`)
                    window.location.reload();
                  }, 900);
                
                })      
        
              }
            });
            }).catch((error) => {
              SweetAlert.fire({
                icon:"error",
                text:"El usuario ya existe o el correo es invalido",
                title:"Uppps...",    
                confirmButtonText: "Inicia sesión ahora",
                cancelButtonText:"Prueba con otro correo",
                cancelButtonColor: "#6596CC",
                reverseButtons: true,
                showCancelButton: true,
              }).then(async(result) => {
                if(result.isConfirmed == true){
                  document.querySelector('.cont').classList.toggle('s--signup');
                }
              });
            })
    }
      
};
const [open, setOpen] = useState(false);


return (
    
  <div className="page-wrapper">
  <Container fluid={true} className="p-0">
    <div className="authentication-main m-0">
      <Row>
        <Col md="12">
          <div className="auth-innerright">
            <div className="authentication-box">
              <CardBody className="h-100-d-center">
                <div className="cont text-center b-light">
                  <div> 
                    <Form className="theme-form">
                    <img className="blur-up lazyloaded light" 
                        src={logo}  alt=""
                        style={{
                        width:"250px",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"}}/>
                      <h3>{LOGIN}</h3>
                      <h6>{"Introduce tu correo y contraseña"}</h6>
                      <FormGroup>
                        <Label className="col-form-label pt-0">{YourName}</Label>
                        <Input className="form-control" type="text" onChange={e => setEmail(e.target.value)} defaultValue={email} required/>
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">{Password}</Label>
                        <Input className="form-control" type="password" onChange={e => setPassword(e.target.value)} defaultValue={password}  required=""/>
                      </FormGroup>
                       <div className="checkbox p-0">
                        <Input id="checkbox1" type="checkbox"/>
                        <Label for="checkbox1">{RememberMe}</Label>
                        </div> 
                      <FormGroup className="form-row mt-3 mb-0">
                        <Button color="primary btn-block" onClick= {loginAuth}>
                        {LOGIN}
                        </Button>
                        {/* } */}
                      </FormGroup>
                      <div className="social mt-3">
                      <div className="col-form-label pt-0">
                      <Col md="12">
                      <font color="#FFFFFF">
                            <h1>{'|'}</h1>
                            <h1>{'|'}</h1>
                            <h2>{'|'}</h2>
                                </font>
                        </Col>
                          </div>
                      </div>
                      </Form>
                    </div>
                    
                  <div className="sub-cont">
                    <div className="img">
                      <div className="img__text m--up">
                        <h2>{"¿Nuevo usuario?"}</h2>
                        <p>{"¿Quieres facilidad, seguridad y ahorros en las compras?, Registrate ahora."}</p>
                      </div>
                      <div className="img__text m--in">
                        <h2>{"Ya estas registrado?"}</h2>
                        <p>{"Si ya tienes una cuenta, inicia sesion"}</p>
                      </div>
                      <div className="img__btn" onClick={toggleform}><span className="m--up">{SignUp}</span><span className="m--in">{SignIn}</span></div>
                    </div>
                    <div>
                      <Form id="formulario" className="theme-form" onkeydown="return event.key != 'Enter';" action="." method="get">
                      <h5 className="text-center">{"¿Eres nuevo usuario?"}</h5>
                      {/* <h7 className="text-center">{"introduce los datos correspondientes"}</h7> */}
                      <Row form>
                      <Col sm="12">
                <Label className="col-form-label pt-0">{'Seleccionar función a desempeñar (Proveedor o Comprador)'}</Label>
                    <FormGroup className="was-validated">
                   <Input type="select" className="custom-select" onChange= {(e) => {setrolereg(e.target.value)}}  required>
                   <option >{""}</option>
                      <option >{"Proveedor"}</option>
                      <option >{"Comprador"}</option>
                </Input>
                <div className="invalid-feedback">{"Campo Obligatorio"}</div>                    
                </FormGroup>
                </Col>
                </Row>
                      
                      <Row form>
                      
                      <Col md="12">
                          <FormGroup> 

                          {/* <Label className="col-form-label pt-0">{texsto}</Label>                             */}
                        
                                           {/* <FormGroup>
                                           <div className="job-filter">
                                            <div id="hola1" className="faq-form">
                                                <Input className="form-control" type="text" onChange= {(e) => {setcategory(e.target.value)}} placeholder="Categoria de productos..." />
                                                <Search className="search-icon" />
                                            </div>
                                        </div>    
                                        </FormGroup> */}
                                           <Col md="12">
                                          
                     {/* <Button id="hola2" color="primary" className="sweet-12" name="advanceSuccess" onClick={modales}>{"Buscar Producto"}</Button> */}
                    <Modal isOpen={modal2} toggle2={toggle2} size="lg" style={{width:'530px'}} centered>
                        <ModalHeader toggle2={toggle2}>{"Elige uno o varios productos"}
                        </ModalHeader>
                        <ModalBody>
                        <Autocomplete
                           multiple
                           id="checkboxes-tags-demo"                          
                           options={options}
                           disableCloseOnSelect
                           getOptionLabel={(option) => option}
                           renderOption={(props, option, { selected }) => (
                             <li {...props}>
                               
                               <Checkbox
                                 icon={icon}                                  
                                 checkedIcon={checkedIcon}
                                 style={{ marginRight: 7 }}
                                 checked={selected}
                               />
                               {option}
                             </li>
                           )}
                           onChange={handleChange}
                           style={{ width: 500 }}
                           renderInput={(params) => (
                             <TextField {...params} label="Seleccione una o varias opciones" />
                           )}
                             />
                                                   <font color="#FFFFFF">
                            <h1>{'|'}</h1>
                            <h1>{'|'}</h1>
                            <h2>{'|'}</h2>
                                </font>
                                <font color="#FFFFFF">
                            <h1>{'|'}</h1>
                            <h1>{'|'}</h1>
                            <h2>{'|'}</h2>
                                </font>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle2}>{"Cerrar"}</Button>
                        <Button color="secondary" onClick={seleccionando}>{"Seleccionar"}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={modal3} toggle3={toggle3} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <Col md="12">
                      <div className="loader-box">
                        <div  className="loader-35"></div>
                      </div>
                    </Col>
                        </ModalBody>
                    </Modal>
                     </Col>
                          </FormGroup>                                
                       </Col>          
                        <Col md="12"className="was-validated">
                          <FormGroup>
                            <Input className="form-control" type="email" pattern="[^@\s]+@[^@\s]+" title="Email invalido"  onChange= {(e) => {setcorreoreg(e.target.value)}} placeholder="Correo Electrónico" required/>                                
                          </FormGroup>
                        </Col>
                        <Col md="12" className="was-validated">
                      <FormGroup>
                        <Input className="form-control" type="password"   onChange= {(e) => {setpasswordreg(e.target.value)}} placeholder="Contraseña (de 8 a mas caracteres)" minlength="8"required/>
                      </FormGroup>
                      </Col>
                       <Col md="6"className="was-validated">
                      <FormGroup> 
                        <Input className="form-control" type="text" id="Nombre" pattern="^\S+$" onChange= {(e) => {setnombrereg(e.target.value)}}placeholder="Nombre" required/>
                      </FormGroup>
                      </Col>
                      <Col md="6"className="was-validated">
                      <FormGroup> 
                        <Input className="form-control" type="text" pattern="^\S+$" onChange= {(e) => {setapellidoreg(e.target.value)}}placeholder="Apellido" required/>
                      </FormGroup>
                      </Col>
                      </Row>
                      <Row form>
                      <Col md="6"className="was-validated">
                      <FormGroup>
                        <Input className="form-control" type="text"  onChange= {(e) => {setempresareg(e.target.value)}} placeholder="Empresa" required/>
                      </FormGroup>
                      </Col>
                      <Col md="6"className="was-validated">
                          <FormGroup>
                            <Input className="form-control" inputmode="numeric" pattern="[0-9]{10}"  title="Introduzca 10 digitos" onChange= {(e) => {setnumeroreg(e.target.value)}} placeholder="Numero de teléfono" required/>
                          </FormGroup>
                        </Col>

                      </Row>
                     
                                
                      <Row form>
                        {/* <Col sm="12">
                          <a href="https://google.com" target="_blank" >
                        <div className="checkbox p-0">
                        <Input id="tm" type="checkbox"/> 
                        <Label for="tm">{'Aceptar terminos y condiciones'}</Label> 
                        </div> </a>
                        </Col> */}
                        <Col sm="12">                           
                        <Button  color="primary" onClick={registro}>{SignUp}</Button>
                        </Col>
                      </Row>
                      <div className="social mt-3">
                        <div className="form-row btn-showcase">
                        </div>
                      </div>
                    </Form>
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    </Container>
  </div>
);
}
export default Logins;