import React, { Fragment } from 'react';
import BreadCrumb from '../../layout/Breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,Button} from 'reactstrap'
import {Link,useHistory} from 'react-router-dom'
import { SimplePricingCard, Standard, LorumIpsum, Purchase, Business,Premium,Subscribe,PricingTableWithBorder,PricingTableWithRibbons,Popular,Extra,Pricings,StandardArray,Business_Premium_Array,ColorHighlight } from '../../constant';
const Pricing = (props) => {
    return (
        <Fragment>
        <BreadCrumb parent="Home" subparent="Price" title="Licencias"/>
        <Container fluid={true}>
        <Row>          
          <Col sm="12">
            <Card>
              <CardHeader> 
                <h5>{"Licencias para B2Buysel "}</h5>
              </CardHeader>
              <CardBody className="pricing-card-design-3">
                <Row className="pricing-content-ribbons">
                  <Col md="4">
                    <Card className="pricing-block text-center">
                      <div className="ribbon ribbon-bookmark ribbon-vertical-left ribbon-danger"><i className="icofont icofont-love"></i></div>
                      <svg x="0" y="0" viewBox="0 0 360 220">
                        <g>
                          <path fill="#133862" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061                                          c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243                                          s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48                                          L0.732,193.75z"></path>
                        </g>
                        <text transform="matrix(1 0 0 1 69.7256 116.2686)" fill="#fff" fontSize="55.4489">{""}</text>
                        <text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff" fontSize="29.0829">{""}</text>
                        <text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff" fontSize="15.4128">{""}</text>
                      </svg>
                      <div className="pricing-inner">
                        <h3 className="font-primary">{"GRATUITA"}</h3>
                        <ul className="pricing-inner"> 
                        <h6>{"Acceso a mandar propuesta de 3 RFQS"}</h6>
                              {/* <h6>{"Recibe todas las invitaciones de RFQS de su categoría, invitándolo a que pague la membresía para poder mandar propuesta"}</h6> */}
                            <li>
                              <h6>{"En las invitaciones no tiene acceso a información del comprador"}</h6>
                            </li>
                            <li>
                              <h6>{"Acceso temporal a la plataforma para visualizar los RFQS y mandar sus 3 propuestas. No se debe mostrar información de los compradores para este perfil "}</h6>
                            </li>
                        </ul>
                        {/* <Button color="primary" size="lg">{""}</Button> */}
                      </div>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="pricing-block text-center">
                      <svg x="0" y="0" viewBox="0 0 360 220">
                        <g>
                          <path fill="#133862" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061                                          c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243                                          s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48                                          L0.732,193.75z"></path>
                        </g>
                        <text transform="matrix(1 0 0 1 69.7256 116.2686)" fill="#fff" fontSize="55.4489">{"$50"}</text>
                        <text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff" fontSize="29.0829">{".00"}</text>
                        <text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff" fontSize="15.4128">{"USD/Mes"}</text>
                      </svg>
                      <div className="pricing-inner">
                        <h3 className="font-primary">{"Negocios"}</h3>
                        <ul className="pricing-inner">
                            <li>
                              <h6>{"Acceso a todos los RFQS de su categoría"}</h6>
                            </li>
                            <li>
                              <h6>{"Recibe todas las invitaciones de RFQS, con opción de mandar propuesta al que decida"}</h6>
                            </li>
                            <li>
                              <h6>{"En las invitaciones tiene acceso a toda la información del RFQ"}</h6>
                            </li>

                        </ul>
                        {/* <Link
                            to={`${process.env.PUBLIC_URL}/widgets/Check`}
                          > <Button color="primary" size="lg">{"Suscribirte"}</Button>
                           </Link>  */}
                      </div>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="pricing-block text-center">
                      <svg x="0" y="0" viewBox="0 0 360 220">
                        <g>
                          <path fill="#cc0b0a" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061                                          c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243                                          s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48                                          L0.732,193.75z"></path>
                        </g>
                        <text transform="matrix(1 0 0 1 69.7256 116.2686)" fill="#fff" fontSize="55.4489">{"$500"}</text>
                        <text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff" fontSize="29.0829">{".00"}</text>
                        <text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff" fontSize="15.4128">{"USD/Año"}</text>
                      </svg>
                      <div className="pricing-inner">
                        <h3 className="font-primary">{Premium}</h3>
                        <ul className="pricing-inner">
                        <li>
                              <h6>{"Acceso a todos los RFQS de su categoría"}</h6>
                            </li>
                            <li>
                              <h6>{"Recibe todas las invitaciones de RFQS, con opción de mandar propuesta al que decida"}</h6>
                            </li>
                            <li>
                              <h6>{"En las invitaciones tiene acceso a toda la información del RFQ"}</h6>
                            </li>
                        </ul>
                        {/* <Button color="primary" size="lg">{"Suscribirte"}</Button> */}
                      </div>
                    </Card>
                  </Col>
                 
                  
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      </Fragment>
    );
}

export default Pricing;