import logo from '../assets/images/creative-logo.png'
import logo_light from '../assets/images/creative-logo1.png'
import React, { Fragment, useState, useEffect , useRef} from 'react';
import {useSelector} from 'react-redux'
import logo_compact from '../assets/images/logo/compact-logo.png';
import { MENUITEMS } from './sidebar/menu';
import {Link} from 'react-router-dom'
import configDB from '../data/customizer/config';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import onClickOutside from "react-onclickoutside";

import {SetCurrentUser} from '../redux/common/actions'
import {Container,Row,Col,CardBody,Form,FormGroup,Input,Label,Button,Modal, ModalHeader, ModalBody, ModalFooter,CardHeader} from 'reactstrap'
const Sidebar = () => {
    
    const [mainmenu, setMainMenu] = useState(MENUITEMS);
    const switchToggle = useSelector(state => state.Common.switchToggle)
    const currentUser= useSelector(state => state.Common.currentUser)
    const [margin, setMargin] = useState(0);
    const [width, setWidth] = useState(0);
    const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
    const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
    const [hideRightArrow, setHideRightArrow] = useState(true);
    const [hideLeftArrow, setHideLeftArrow] = useState(true);
    const wrapper = useSelector(content => content.Customizer.sidebar_types.type) || configDB.data.settings.sidebar.type;
    const layout = useSelector(content => content.Customizer.layout)
    const sidebar_background_color = configDB.data.settings.sidebar_background_setting;
   
    


 
    useEffect(() => {
               
        window.addEventListener('resize', handleResize)
        handleResize();
         
        const currentUrl = window.location.pathname;
        mainmenu.filter(items => {
            if (items.path === currentUrl)
                setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
                if (subItems.path === currentUrl)
                    setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                    if (subSubItems.path === currentUrl){
                        setNavActive(subSubItems)
                        return true
                    }
                    else{
                        return false
                    }
                })
                return subItems
            })
            return items
        })

        const timeout = setTimeout(() => {
            const elmnt = document.getElementById("myDIV");
            const menuWidth = elmnt.offsetWidth;
            if (menuWidth > window.innerWidth || menuWidth < window.innerWidth ) {
                setHideRightArrow(false);
                setHideLeftArrowRTL(false);
            } else {
                setHideRightArrow(true);
                setHideLeftArrowRTL(true);
            }
        }, 500)

        return () => {
            // eslint-disable-next-line
            window.removeEventListener('resize', handleResize);
            clearTimeout(timeout);
        }

        // eslint-disable-next-line
    }, []);

    const handleResize = () => {
        
        setWidth(window.innerWidth - 500);
        
    }

    const setNavActive = (item) => {
        MENUITEMS.filter(menuItem => {
            
            if (menuItem !== item)
                menuItem.active = false
            if (menuItem.children && menuItem.children.includes(item))
                menuItem.active = true
                window.onclick = () =>{
                    menuItem.active = false
                    }
            if (menuItem.children) {
                menuItem.children.filter(submenuItems => {
                    if (submenuItems.children && submenuItems.children.includes(item)) {
                        menuItem.active = true
                        submenuItems.active = true
                        
                        return true
                    }
                    else{
                        return false
                    }
                })
            }
            return menuItem
        })
        
        item.active = !item.active
        setMainMenu({ mainmenu: MENUITEMS })
    }


    const [drop,setdrop] = useState(false)

    const toggletNavActive = (item) => {   
        if (!item.active) {
            
            MENUITEMS.forEach(a => {
                if (MENUITEMS.includes(item))
                    a.active = false
                if (!a.children) return false
                a.children.forEach(b => {
                    if (a.children.includes(item)) {
                        b.active = false
                    }
                    if (!b.children) return false
                    b.children.forEach(c => {
                        if (b.children.includes(item)) {
                            c.active = false
                        }
                    })
                })
            });
            
        }
        
    
        item.active = !item.active
        setMainMenu({ mainmenu: MENUITEMS })    

        if (drop == true) {
            window.addEventListener("click", function(event) {
                setdrop(!drop)
                item.active = !item.active
                setMainMenu({ mainmenu: MENUITEMS })  
            });
        }

       
    }

 


    const toggle = () => setdrop(!drop);


   
    

        
    
   
   


    const scrollToRight = () => {
        const elmnt = document.getElementById("myDIV");
        const menuWidth = elmnt.offsetWidth;
        const temp = menuWidth + margin;
        if (temp < menuWidth) {
            setMargin(-temp);
            setHideRightArrow(true);
        }
        else {
            setMargin(margin => margin += (-width));
            setHideLeftArrow(false);
        }
    }

    const scrollToLeft = () => {
        if (margin >= -width) {
            setMargin(0)
            setHideLeftArrow(true);
        }
        else {
            setMargin(margin => margin += width);
            setHideRightArrow(false);
        }
    }


    const scrollToLeftRTL = () => {
        
        if (margin < -width) {
            setMargin(margin => margin += -width);
            setHideLeftArrowRTL(true);
        }
        else {
            setMargin(margin => margin += -width);
            setHideRightArrowRTL(false);
        }
    }

    const scrollToRightRTL = () => {
        const temp = width + margin
        
        if (temp === 0) {
            setMargin(temp);
            setHideRightArrowRTL(true);
        }
        else {
            setMargin(margin => margin += width);
            setHideRightArrowRTL(false);
            setHideLeftArrowRTL(false);
        }
    }


           const obtenersesion = JSON.parse(sessionStorage.getItem('currentuser'))
           SetCurrentUser(obtenersesion)
        
  
    const [userType, setUserUserType] = useState("")

    useEffect(()=>{
        setUserUserType(currentUser.role)
    },[])
    // useEffect(()=>{
    //     if (sessionStorage.getItem('currentuser')){
    //         console.log('hay sesion iniciada')
    //        const obtenersesion = JSON.parse(sessionStorage.getItem('currentuser'))
    //        console.log("currentUser", obtenersesion.role); 
    //        setUserUserType(obtenersesion.role) 
    //        console.log("set", currentUser)
    //     }else{
    //         console.log('no existe el usuario')
    //     }
    // },[])

    const funcionlogo = () =>{

            window.location.href = `${process.env.PUBLIC_URL}/widgets/Bienvenida`
            
       
    }

    

    
    

    return (
        <Fragment>
            <div className={`page-sidebar ${switchToggle? 'open': sidebar_background_color}`}>
                <div className="main-header-left d-none d-lg-block">
                        <img src={logo} onClick={funcionlogo}  alt="" />
                </div>
                <div className="sidebar custom-scrollbar">
                    <ul
                        className="step2 sidebar-menu"
                        id="myDIV"
                        style={wrapper === 'horizontal_sidebar' ? layout === 'rtl' ?
                        { 'marginRight': margin + 'px' } : { 'marginLeft': margin + 'px' } : { margin: '0px' }}
                    >
                         <li className={`left-arrow ${layout === 'rtl' ? hideLeftArrowRTL ? 'd-none' : '' : hideLeftArrow ? 'd-none' : ''}`}
                            onClick={(wrapper === 'horizontal_sidebar' && layout === 'rtl') ? scrollToLeftRTL : scrollToLeft}><i className="fa fa-angle-left"></i></li>
                         {
                            MENUITEMS.map((menuItem, i) => 
                               
                                <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                                    {(menuItem.sidebartitle) ? <div className="sidebar-title">{menuItem.sidebartitle}</div>
                                        : ''}
                                    {(menuItem.type === 'sub') ?
                                        <a className="sidebar-header"  onClick={() => toggletNavActive(menuItem)}>
                                            <menuItem.icon onClick={toggle} />
                                    <span>{menuItem.title}</span>
                                            <i className="fa fa-angle-right pull-right"></i>
                                        </a>
                                        : ''}

                                        
                                        
                                    {(menuItem.type === 'link' && menuItem.title == "Lista de Proveedores"&& userType == "Comprador") ?   
                                                      
                                        <Link   className="sidebar-header" style={{background:"#840003"}} onClick={() => toggletNavActive(menuItem)} to={menuItem.path}>
                                            <menuItem.icon /><span>{menuItem.title}</span>
                                            {menuItem.children ?
                                                <i className="fa fa-angle-right pull-right"></i> : ''}
                                        </Link>
                                        : ''}
                                        {(menuItem.type === 'link' && menuItem.title == "Dashboard Comprador" && userType == "Comprador") ?   
                                                      
                                        <Link   className="sidebar-header" style={{background:"#840003"}} onClick={() => toggletNavActive(menuItem)} to={menuItem.path}>
                                            <menuItem.icon /><span>{menuItem.title}</span>
                                            {menuItem.children ?
                                                <i className="fa fa-angle-right pull-right"></i> : ''}
                                        </Link>
                                        : ''}


                                         {(menuItem.type === 'link' && menuItem.title == "Lista de Cotizaciones"&& userType == "Proveedor") ?   
                                                      
                                                      <Link   className="sidebar-header" style={{background:"#840003"}} onClick={() => toggletNavActive(menuItem)} to={menuItem.path}>
                                                          <menuItem.icon /><span>{menuItem.title}</span>
                                                          {menuItem.children ?
                                                              <i className="fa fa-angle-right pull-right"></i> : ''}
                                                      </Link>
                                                      : ''}
                                                      {(menuItem.type === 'link' && menuItem.title == "Dashboard Proveedor" && userType == "Proveedor") ?   
                                                                    
                                                      <Link   className="sidebar-header" style={{background:"#840003"}} onClick={() => toggletNavActive(menuItem)} to={menuItem.path}>
                                                          <menuItem.icon /><span>{menuItem.title}</span>
                                                          {menuItem.children ?
                                                              <i className="fa fa-angle-right pull-right"></i> : ''}
                                                      </Link>
                                                      : ''}
                                       
                                    {menuItem.children ?
                                        <ul 
                                            className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                                            style={menuItem.active ? { opacity: 1, transition: 'opacity 500ms ease-in' } : {}}
                                        >
                                        
                                            
                                            {menuItem.children.map((childrenItem, index) =>
                                                <li key={index} className={childrenItem.children ? childrenItem.active ? 'active' : '' : ''}>
                                                    {(childrenItem.type === 'sub') ?
                                                        <a href={childrenItem.path} onClick={() => toggletNavActive(childrenItem)} >
                                                            <i className="fa fa-circle"></i>{childrenItem.title} <i className="fa fa-angle-down pull-right"></i></a>
                                                    : ''}


                                                    {/* ESTO HACE QUE SIEMPRE SE IMPRIMAN AMBAS OPCIONES */}
                                                    {(childrenItem.type === 'link') && userType == "Ambos" && childrenItem.title == "Lista de RFQS" ?
                                                        <Link className={childrenItem.active ? '' : ''} onClick={() => toggletNavActive(childrenItem)} to={childrenItem.path}>
                                                            <i className="fa fa-circle"></i>{childrenItem.title}
                                                        </Link>
                                                    : ''}
                                                    
                                                    {(childrenItem.type === 'link') && userType == "Ambos" && childrenItem.title == "Proveedores" ?
                                                        <Link className={childrenItem.active ? '' : ''} onClick={() => toggletNavActive(childrenItem)} to={childrenItem.path}>
                                                            <i className="fa fa-circle"></i>{childrenItem.title}
                                                        </Link>
                                                    : ''}
                                                    {(childrenItem.type === 'link') && userType == "Ambos" && childrenItem.title == "Compradores" ?
                                                        <Link className={childrenItem.active ? '' : ''} onClick={() => toggletNavActive(childrenItem)} to={childrenItem.path}>
                                                            <i className="fa fa-circle"></i>{childrenItem.title}
                                                        </Link>
                                                    : ''}
                                                    {/* ESTO HACE QUE SIEMPRE SE IMPRIMA CUANDO ERES COMPRADOR */}
                                                    {(childrenItem.type === 'link') && userType == "Comprador" && childrenItem.title == "Lista de Proveedores" ?
                                                        <Link className={childrenItem.active ? '' : ''} onClick={() => toggletNavActive(childrenItem)} to={childrenItem.path}>
                                                            <i className="fa fa-circle"></i>{childrenItem.title}
                                                        </Link>
                                                    : ''}
                                                    {(childrenItem.type === 'link') && userType == "Comprador" && childrenItem.title == "Compradores" ?
                                                        <Link className={childrenItem.active ? '' : ''} onClick={() => toggletNavActive(childrenItem)} to={childrenItem.path}>
                                                            <i className="fa fa-circle"></i>{childrenItem.title}
                                                        </Link>
                                                    : ''}
                                                    
                                                    {/* ESTO HACE QUE SIEMPRE SE IMPRIMA CUANDO ERES PROVEEDOR */}
                                                    {(childrenItem.type === 'link') && userType == "Proveedor" && childrenItem.title == "Lista de RFQS" ?
                                                        <Link className={childrenItem.active ? '' : ''} onClick={() => toggletNavActive(childrenItem)} to={childrenItem.path}>
                                                            <i className="fa fa-circle"></i>{childrenItem.title}
                                                        </Link>
                                                    : ''}
                                                    {(childrenItem.type === 'link') && userType == "Proveedor" && childrenItem.title == "Proveedores" ?
                                                        <Link className={childrenItem.active ? '' : ''} onClick={() => toggletNavActive(childrenItem)} to={childrenItem.path}>
                                                            <i className="fa fa-circle"></i>{childrenItem.title}
                                                        </Link>
                                                    : ''}
                                                    





                                                    
                                                    
                                         





                                                </li>
                                            )}
                                            
                                        </ul>
                                        : ''}   
                                </li>

                            )
                        }
                         {/* <li className={`right-arrow ${layout === 'rtl' ? hideRightArrowRTL ? 'd-none' : '' : hideRightArrow ? 'd-none' : ''}`}
                            onClick={(wrapper === 'horizontal_sidebar' && layout === 'rtl') ? scrollToRightRTL : scrollToRight}><i className="fa fa-angle-right"></i></li> */}
                   </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default Sidebar;