import React from "react";
import connectionImg from "../../../assets/images/chatbuysel/connection.png";
import "../chat-app/NoChatSelected.css";

const NoChatSelected = () => {
  return (
    <div className="NoChatSelected">
      <img src={connectionImg} alt="Connection" style={{left: "0.281375px",position: "relative",top: "-57px"}} />
      <div className="NoChatSelected__description" style={{ position: "relative", top: "-107px"}}>
        <h1>Bienvenido a la sección de Chat</h1>
        <p>
        Contacta por chat para mejorar tus negocios con los usuarios de B2Buysel
        </p>
      </div>
    </div>
  );
};

export default NoChatSelected;