import React, {useState,Fragment, useEffect} from 'react'
import BreadCrumb from '../../layout/Breadcrumb'
import { Container, Row, Col, Card, CardBody, CardHeader, Table, ModalHeader, ModalBody, ModalFooter, Modal , FormGroup ,Button} from 'reactstrap'
import {RFQS} from "../../constant";
import RFQSRecibidosContadores from './common/RFQSRecibidosContadores'
import {useSelector} from 'react-redux'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import Tour from 'reactour'
import {disableBodyScroll,enableBodyScroll} from 'body-scroll-lock'

const steps = [
  {
      selector: '.spr1',
      content: 'Esta es la lista de RFQs creados por los compradores con mi categoria de productos de venta',
  },
  {
      selector: '.spr2',
      content: 'Esta es la tabla de RFQs que he aceptado y enviado',
  },
  {
    selector: '.spr3',
    content: 'Acepta y espera la respuesta de un comprador',
}
]





const DashboardProveedor = (props) => {



  const [opentour,setopentour] = useState(false)
  const closeTour = () => {
      setopentour(false);
    };

    const tuto = () =>{
      setTimeout(() => {
        setopentour(true);
      }, 350);
    }

    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);



  // la lista que tengo que recibir todos los rfqs donde su categoria sea = a la categoria del currentuser
  const [modale, setModale] = useState();
  const [list, setlist] = useState([])
  const [seglist, setseglist] = useState([])
  const [cate, setcate] = useState([])
  const currentUser= useSelector(state => state.Common.currentUser)

 
 console.log(currentUser.Categoria)
 
useEffect(()=>{
  setcate(currentUser.Categoria)
  axios.get('https://5d61ed013f4c.ngrok.io/gbn/proveedores', {
    params: {
      foo: currentUser.Categoria
    }}      
    ).then((response) => {
        setlist(response.data);
      });

},[])
useEffect(()=>{
  setcate(currentUser.Categoria)
  axios.get('https://5d61ed013f4c.ngrok.io/gbn/provmisacep', {
    params: {
      foo: currentUser.id
    }}      
    ).then((response) => {
        setseglist(response.data);
      });

},[])


const [seleccionado, setseleccionado] = useState({
  RFQsl:'',
  Empresa_solicitante:'',
  Categoria:'',
  Descripcion:'',
  Cantidad_Solicitada:'',
  Unidadmedida:'',
  Precioobj:'',
  diasdecredito:'', 
  Moneda:'', 
  Fechavigencia:'',
  lugaentrega:'',
  Estatussl:'',
  iduc:''
});
const toggle = (data) => {
  setseleccionado(data)
  setModale(!modale)
 }
const [pre, setpre] = useState ("");
 
 const enviar = () => { 
  
  toast.warn("Has aceptado este RFQ, se ha enviado un Email al comprador, espera una respuesta pronto", {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 10000
    });
    setTimeout(()=>{

      window.location.href = `${process.env.PUBLIC_URL}/#/widgets/dashboardproveedor`
     }, 2400)
     setTimeout(()=>{
      window.location.reload();                  
      }, 2800)

  axios.post('https://70ae6ed1cdbe.ngrok.io/gbn/aceptarprov', 
  
  {RFQsl:seleccionado.RFQsl,
  Empresa_solicitante:seleccionado.Empresa_solicitante,
  Categoria:seleccionado.Categoria,
  Descripcion:seleccionado.Descripcion,
  Cantidad_Solicitada:seleccionado.Cantidad_Solicitada,
  Unidadmedida:seleccionado.Unidadmedida,
  Precioobj:pre,
  diasdecredito:seleccionado.diasdecredito, 
  Moneda:seleccionado.Moneda, 
  Fechavigencia:seleccionado.Fechavigencia, 
  lugaentrega:seleccionado.lugaentrega, 
  Estatussl:seleccionado.Estatussl,
  Empresa_interesada:currentUser.Empresa,
  idui:currentUser.id,
  iduc:seleccionado.iduc
  }).then((response) => {
    axios.get('https://70ae6ed1cdbe.ngrok.io/gbn/usuariosanotificar2', { 
 params: {
  foo: seleccionado.Empresa_solicitante
}}    
        ).then((response) => {
            var usuariosanotificar = response.data
      usuariosanotificar.forEach(usuario=>{
        var params = {
          fromName:currentUser.Nombre, 
          toName:usuario.Nombre, 
          toEmail:usuario.Correo,
         
        }
          emailjs.send('service_ruxs3u7', 'template_np892ov', params, 'user_6Yiy6j6dBtbUgO2vEhYPP').then((res)=>{
             console.log('emailjs', res)
          })
        console.log('notificar a', usuario)
      })
      console.log('1', response)
  });
    
   
});
setModale(!modale)
};
const [modale2, setModale2] = useState();
const toggle2 = (data) => {
  setseleccionado(data)
  setModale2(!modale2) 
  
 }
const eliminar = () => { 

  toast.warn("Has descartado este RFQ de Aceptados", {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000
    });

    axios.delete('https://70ae6ed1cdbe.ngrok.io/gbn/dltaceptados', {
      params: {
        foo: seleccionado.RFQsl
      }}      
      ).then((response) => {
        setTimeout(()=>{
          window.location.href = `${process.env.PUBLIC_URL}/#/widgets/dashboardproveedor`
          window.location.reload();                  
          }, 2300)
        });
        setModale2(!modale2)
      }

    return (
      <Fragment>
        <Container fluid={true}>
        <Tour
          steps={steps}
          rounded={5}
          isOpen={opentour}
          disableInteraction={true}
          disableKeyboardNavigation={false}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
           />
          <Row>
          {/* <Col sm="12" xl="12" lg="12">
            <RFQSRecibidosContadores/>
          </Col> */}
          {/* <Col xl="12" className="xl-100 box-col-12">
                <Card className="widget-joins">
                  <Row>
                     <Col sm="6" className="pr-0">
                      <div className="media border-after-xs">
                        <div className="align-self-center mr-3">{"0%"}<i className="fa fa-angle-up ml-2"></i></div>
                        <div className="media-body details pl-3"><span className="mb-1">{New}</span>
                          <h4 className="mb-0 counter digits">{"0"}</h4>
                        </div>
                        <div className="media-body align-self-center"><ShoppingBag className="font-primary float-left ml-2"/></div>
                      </div>
                    </Col>
                    <Col sm="6" className="pl-0">
                      <div className="media">
                        <div className="align-self-center mr-3 digits">{"0%"}<i className="fa fa-angle-down ml-2"></i></div>
                        <div className="media-body details pl-3"><span className="mb-1">{Pending}</span>
                          <h4 className="mb-0 counter digits">{"0"}</h4>
                        </div>
                        <div className="media-body align-self-center"><Layers className="font-primary float-left ml-3"/></div>
                      </div>
                    </Col>
                    <Col sm="6" className="pr-0">
                      <div className="media border-after-xs">
                        <div className="align-self-center mr-3">{"0%"}<i className="fa fa-angle-up ml-2"></i></div>
                        <div className="media-body details pl-3 pt-0"><span className="mb-1">{Done}</span>
                          <h4 className="mb-0 counter digits">{"0"}</h4>
                        </div>
                        <div className="media-body align-self-center"><ShoppingCart className="font-primary float-left ml-2"/></div>
                      </div>
                    </Col>
                    <Col sm="6" className="pl-0">
                      <div className="media">
                        <div className="align-self-center mr-3">{"0%"}<i className="fa fa-angle-up ml-2"></i></div>
                        <div className="media-body details pl-3 pt-0"><span className="mb-1">{Cancel}</span>
                          <h4 className="mb-0 counter digits">{"0"}</h4>
                        </div>
                        <div className="media-body align-self-center"><DollarSign className="font-primary float-left ml-2"/></div>
                      </div>
                    </Col>
                  </Row>
                </Card>
          </Col> */}
            <Col xl="12" className="xl-100 box-col-12">
              <Card>
                <CardHeader>
                  <h5 className="mb-0 f-24">{"RFQS de mi categoria"}</h5>
                  {/* <Button className="btn btn-success pull-right">{Descargar} {Lista}</Button> */}
                  
                </CardHeader>
                <Col >
             <CardBody className="blockquote text-center">
            <Button className="btn-pill" color="primary" size="lg"  onClick={tuto}>{"Tutorial"}</Button>
            </CardBody>
                                </Col>
                <CardBody>
                  <div className="spr1 user-status table-responsive">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th scope="col">{"RFQ"}</th>
                          <th scope="col">{"Empresa Solicitante"}</th>
                          <th scope="col">{"Categoria"}</th>
                          <th scope="col">{"Descripción"}</th>
                          <th scope="col">{"Cantidad Solicitada"}</th>
                          <th scope="col">{"Unidad de Medida"}</th>
                          <th scope="col">{"Dias de acreditación"}</th>
                          <th scope="col">{"Moneda"}</th>
                          <th scope="col">{"Fecha de Vigencia"}</th>
                          <th scope="col">{"Lugar de entrega"}</th>
                          <th scope="col">{"Estatus"}</th>
                          <th scope="col">{"Acciones"}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {list.map(data => (
                        <tr >
                        <td>{data.RFQsl}</td>
                        <td>{data.Empresa_solicitante}</td>
                        <td>{data.Categoria}</td>
                        <td>{data.Descripcion}</td>
                        <td>{data.Cantidad_Solicitada}</td>
                        <td>{data.Unidadmedida}</td>
                        <td>{data.diasdecredito}</td>
                        <td>{data.Moneda}</td>
                        <td>{data.Fechavigencia}</td>
                        <td>{data.lugaentrega}</td>
                        <td>
                          <div className={`span badge badge-pill badge-primary`}>{data.Estatussl}</div>
                        </td>
                        <td><Button onClick={() => toggle(data)} >{"Aceptar"}</Button></td> 
                         <Modal isOpen={modale} toggle={toggle} className="modal-body"  centered={true}>
                         <ModalHeader toggle={toggle}>{'Aceptar RFQ'}
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                        <label> Precio objetivo: </label>
                        <input className="form-control" onChange= {(e) => {setpre(e.target.value)}} placeholder='$'/>
                        </FormGroup>
                        <FormGroup>
                        <label> RFQ: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.RFQsl} />
                        </FormGroup>
                        <FormGroup>
                        <label> Categoria: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Categoria}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Empresa Solicitante:</label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Empresa_solicitante}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Producto Solicitado: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Descripcion}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Cantidad Solicitada: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Cantidad_Solicitada}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Fecha de vigencia: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Fechavigencia}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Estatus </label>
                        <input className="form-control"   readOnly type="text" value={seleccionado && seleccionado.Estatussl}/>
                        {/* añadir campo de attachment */}
                        </FormGroup>
                        
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle}>{'Cerrar'}</Button>
                        <Button color="secondary" onClick= {enviar}>{'Aceptar y enviar'}</Button>
                        
                        </ModalFooter>
                    </Modal>
                      </tr>
                      ))}                     
                      </tbody>
                   </Table>
                  </div>
                </CardBody>
                <CardHeader>
                  <h5 className="mb-0 f-24">{"Mis RFQS Aceptados"}</h5>
                </CardHeader>
                <CardBody>
                  <div className="spr2 user-status table-responsive">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th scope="col">{"RFQ"}</th>
                          <th scope="col">{"Empresa Solicitante"}</th>
                          <th scope="col">{"Categoria"}</th>
                          <th scope="col">{"Descripción"}</th>
                          <th scope="col">{"Cantidad Solicitada"}</th>
                          <th scope="col">{"Unidad de Medida"}</th>
                          <th scope="col">{"Precio Objetivo"}</th>
                          <th scope="col">{"Dias de acreditación"}</th>
                          <th scope="col">{"Moneda"}</th>
                          <th scope="col">{"Fecha de Vigencia"}</th>
                          <th scope="col">{"Lugar de entrega"}</th>
                          <th scope="col">{"Estatus"}</th>
                          <th scope="col">{"Acciones"}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {seglist.map(data => (
                        <tr >
                        <td>{data.RFQsl}</td>
                        <td>{data.Empresa_solicitante}</td>
                        <td>{data.Categoria}</td>
                        <td>{data.Descripcion}</td>
                        <td>{data.Cantidad_Solicitada}</td>
                        <td>{data.Unidadmedida}</td>
                        <td>{data.Precioobj}</td>
                        <td>{data.diasdecredito}</td>
                        <td>{data.Moneda}</td>
                        <td>{data.Fechavigencia}</td>
                        <td>{data.lugaentrega}</td>
                        <td>
                          <div className={`span badge badge-pill badge-primary`}>{data.Estatussl}</div>
                        </td>
                        <td><Button color="primary" onClick={() => toggle2(data)} >{"Eliminar"}</Button></td>
                        <Modal isOpen={modale2} toggle2={toggle2} className="modal-body"  centered={true}>
                         <ModalHeader toggle2={toggle2}>{'¿Estas seguro de eliminar este RFQ?'}
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                        <label>
                 <h5> Si eliminas este RFQ, este no sera ofertado</h5>                 
              </label>
              <h6>{seleccionado.RFQsl}</h6>
              <h6>{seleccionado.Descripcion}</h6>
                        </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle2}>{'Cancelar'}</Button>
                        <Button color="success" className="sweet-8" name="alertSuccess" onClick= {eliminar}>{'Confirmar y eliminar'}</Button>                        
                        </ModalFooter>
                    </Modal>
                        </tr>
                      ))}                     
                      </tbody>
                   </Table>
                  </div>
                </CardBody>
                <CardHeader></CardHeader>
              </Card>
            </Col>
                        
          </Row>
        </Container>
        </Fragment>
    )
}

export default DashboardProveedor
