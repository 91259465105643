import React, { useState, useEffect } from "react";
import { Container, Media, Row, Col, Card, CardBody, CardHeader, Button,Label, Table, CardFooter, input, form, Input,Modal, ModalHeader, ModalBody, ModalFooter,Form,FormGroup} from 'reactstrap'
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ChatIcon from "@material-ui/icons/Chat";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IconButton } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import {useSelector} from 'react-redux'
import prov from "../../../assets/images/user/prov.png"
import comp from "../../../assets/images/user/comp.png"
import port from "../../../assets/images/user-card/contact.jpg"
import axios from 'axios';
import "./Sidebarxd.css";
import "./Contacto.css";



const Sidebarxd =({ setCanShowChat})  => {
  const currentUser= useSelector(state => state.Common.currentUser)
  

  const [anchorEl, setAnchorEl] = useState(null);


 
  
 

  const enviarmsj = (data) => {
    localStorage.removeItem('chatselec')
    setCanShowChat(false)
    setcargador(true)
    axios.get('https://5d61ed013f4c.ngrok.io/gbn/infocontact', {
          params: {
            foo: data.id
          }}      
          ).then((response) => {
            
            localStorage.setItem('chatselec', JSON.stringify(response.data));
            
            setTimeout(()=>{       
              setmodal(false)
              setcargador(false)
              setCanShowChat(true)
              
              }, 3000)
    
          
            });
     
                
  }





  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  


    const [imgp, setimgp] = useState("");
    const [perfilimg, setperfilimg] = useState("");

    
    const imgsrx = "https://8751991ba23d.ngrok.io/gbn/"+perfilimg;

    useEffect(()=>{
    axios.get('https://8751991ba23d.ngrok.io/gbn/miimg',{
      params: {
        foo: currentUser.id
      }})
      .then((res) => {
        setperfilimg(res.data[0])
      })
    },[])
     
    useEffect(()=>{
      axios.get('https://8751991ba23d.ngrok.io/gbn/request',{
      params: {
        foo: currentUser.id
      }}).then((response) => {
        setimgp(response.data.length);
      });
    },[])


      const Imagendeperfil = () =>{

        if (imgp == 0) {
    
          if(currentUser.role =="Proveedor") {
    
            return(
              <IconButton>
              <div className="circle-dashed-box" >
                                <div className="circle-dashed"></div>
                              <img className="img-30 rounded-circle mr-0" style={{position: "relative",left: "0px",top:"-2.797px"}}src={prov} alt=""/> </div>
                            </IconButton>
    
            )
          }if (currentUser.role == "Comprador") {
    
            return (
              <IconButton>
              <div className="circle-dashed-box">
                                <div className="circle-dashed"></div>
                           <img className="img-30 rounded-circle mr-0" style={{position: "relative",left: "0px",top:"-2.797px"}} src={comp} alt=""/></div>
           </IconButton> 
    
            )
    
          }
    
    
        }
        if (imgp == 1) {
    
          return (
            <IconButton>
            <div className="circle-dashed-box">
                              <div className="circle-dashed"></div>
                         <img className="img-30 rounded-circle mr-0" style={{position: "relative",left: "0px",top:"-2.797px"}} src={imgsrx} alt=""/></div>
         </IconButton> 
          )
    
        }
  
      }

      const Imagentarjeta = () =>{

        if (imgp == 0) {
    
          if(currentUser.role =="Proveedor") {
    
            return(
              <div className="card-profile">
                      <img body className="rounded-circle" src={prov} alt=""/>
                    </div>
    
            )
          }if (currentUser.role == "Comprador") {
    
            return (
              <div className="card-profile">
                      <img body className="rounded-circle" src={comp} alt=""/>
                    </div>
    
            )
    
          }
    
    
        }
        if (imgp == 1) {
    
          return (
            <div className="card-profile">
                      <img className="rounded-circle img-100" src={imgsrx} alt=""/>
                    </div>
          )
    
        }
  
      }


      

   
    
      const [users, setusers] = useState([])

      const URL ="https://38e1745833eb.ngrok.io/gbn/listprov"
      const getData = async () =>{
        const response = axios.get(URL);
        
        return response;
        
      }
      useEffect(() => {
        getData().then(( response ) =>{
          setusers(response.data);
      
        })
      }, [])


  

      
      const [mitarjeta, setmitarjeta] = useState(false);
      const [modal, setmodal] = useState(false);
      const [cargador, setcargador] = useState(false)
      const [conversations, setconversations] = useState([])
      const [ons, setons] = useState("")
      const [sati, setsati] = useState("")

      

    
        
      useEffect(() => {
        axios.get('https://5d61ed013f4c.ngrok.io/gbn/thornot', {
          params: {
            foo: currentUser.id
          }}      
          ).then((response) => {
              // setconversations(response.data);
             
              if(response.data.length == 0 )  {
                axios.get('https://5d61ed013f4c.ngrok.io/gbn/conversationsde', {
                  params: {
                    foo: currentUser.id,
                    fool: currentUser.id
                  }}      
                  ).then((response) => {
                        setconversations(response.data)
                        document.getElementById('rem').style.display = 'none';
                  })
              }else {
                axios.get('https://5d61ed013f4c.ngrok.io/gbn/conversationsre', {
                  params: {
                    foo: currentUser.id,
                    fool: currentUser.id
                  }}      
                  ).then((response) => {
                        setconversations(response.data)
                        document.getElementById('dest').style.display = 'none';
                  })
              }




            });
          
          }, );
          
          
           
  

       

      
     

    
      
        function pasar(data) {
  setCanShowChat(false);
  localStorage.removeItem('chatselec');
  if(data.id_destinatario == currentUser.id) {
    axios.get('https://5d61ed013f4c.ngrok.io/gbn/infocontact', {
      params: {
        foo: data.id_remitente,
      }
    }
    ).then((response) => {
  
      localStorage.setItem('chatselec', JSON.stringify(response.data));
      setCanShowChat(true);
  
  
    });
  }if(data.id_remitente == currentUser.id) {
    axios.get('https://5d61ed013f4c.ngrok.io/gbn/infocontact', {
    params: {
      foo: data.id_destinatario,
    }
  }
  ).then((response) => {

    localStorage.setItem('chatselec', JSON.stringify(response.data));
    setCanShowChat(true);


  });
  }
  

}


      //  const Contactcard = () =>{

       
      //   if (descrip == "Proveedor") {
      //     return (
      //       <div className="contact__avatar">
      //       {/* <AccountCircleIcon /> */}       
      //                               <div className="circle-dashed"></div>
      //                             <img className="img-30 rounded-circle mr-0" src={prov} style={{left: "-2.98613px", position: "relative",top: "8.02064px"}}alt=""/> 
      //     </div>   
      //     )
      //   }
      //   if (descrip == "Comprador") {
      //     return (
      //       <div className="contact__avatar">
      //       {/* <AccountCircleIcon /> */}       
      //                               <div className="circle-dashed"></div>
      //                             <img className="img-30 rounded-circle mr-0" src={comp} style={{left: "-2.98613px", position: "relative",top: "8.02064px"}}alt=""/> 
      //     </div>   
      //     )
      //   }
   

      //  }
      const envconf = () =>{
        window.location.href = `${process.env.PUBLIC_URL}/#/users/userEdit`
      }


      const toggle = () => {
        setmodal(false)
      }

      const toggletarjeta = () => {
        setmitarjeta(false)
      }
      const togglecargador = () => {
        setcargador(false)
      }
      const mens = () =>{

        setmodal(true)
      }

      const miperfil = () =>{
        setAnchorEl(null);
        setmitarjeta(true)
      }



  return (
    <div className="sidebarxd">
      <div className="sidebarxd__header">
        <div className="sidebarxd__header_left">
           <Imagendeperfil />
          {currentUser.Nombre}{" "}{currentUser.Apellido}
        </div>       
        <div className="sidebarxd__header_right">
          {/* <IconButton>
            <DonutLargeIcon />
          </IconButton> */}
          <IconButton onClick={mens}>
            <ChatIcon />
          </IconButton>
          <Modal isOpen={modal} toggle={toggle} size="lg" centered>
          <ModalHeader toggle={toggle}>
                        </ModalHeader>
                        <ModalBody>
                        <CardBody className="card-body p-0">
                    <div className="sales-product-table crypto-table-market sass-table table-responsive">
                      <Table borderless>
                        <tbody>
                          {users.map(data =>(
                                 <tr key={data.id}>
                                 <td>
                                   <div className="media"><img className="img-fluid rounded-circle img-40 mr-3" src={"data.imagepath"} alt=""/>
                                     <div className="media-body"><span className="f-w-600 d-block">{data.Nombre}</span></div>
                                   </div>
                                 </td>
                                 <td className="text-center"><span className="f-w-600 d-block">{data.Empresa}</span></td>
                                 <td>
                                 <Button color="light" outline className="txt-dark" onClick={() =>enviarmsj(data)}>{"Enviar mensaje"}</Button>
                                 </td>
                               </tr>
                          ))}
                        </tbody> 
                      </Table>
                    </div>
                  </CardBody>
                      
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle}>{"Cerrar"}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={cargador} toggle={togglecargador} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <Col md="12">
                      <div className="loader-box">
                        <div  className="loader-19"></div>
                      </div>
                    </Col>
                        </ModalBody>
                    </Modal>



          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={miperfil}>Mi perfil</MenuItem>
            <Modal isOpen={mitarjeta} toggle={toggletarjeta} size="lg" style={{width:'530px'}} centered>
              <ModalHeader toggle={toggletarjeta}>
                        </ModalHeader>
                        <ModalBody>
                        <Card className="custom-card">
                    <CardHeader>
                      <Media body className="img-fluid" src={port} alt=""/>
                    </CardHeader>
                    <Imagentarjeta/>
                  <ul className="card-social">
                    <li><i className="fa fa-facebook"></i></li>
                    <li><i className="fa fa-google-plus"></i></li>
                    <li><i className="fa fa-twitter"></i></li>
                    <li><i className="fa fa-instagram"></i></li>
                    <li><i className="fa fa-rss"></i></li>
                  </ul>
                  <div className="text-center profile-details">
                    <h4>{currentUser.Nombre}</h4>
                    <h6>{currentUser.role}</h6>
                  </div>
                  <CardFooter className="row">
                    <Col sm="4 col-4">
                      <h6>Cotizaciones</h6>
                      <h3 className="counter">0</h3>
                    </Col>
                    <Col sm="4 col-4">
                      <h6>Empresa:</h6>
                      <h3><span className="counter">{currentUser.Empresa}</span>K</h3>
                    </Col>
                    <Col sm="4 col-4">
                      <h6>Negocios pactados</h6>
                      <h3><span className="counter">0</span></h3>
                    </Col>
                  </CardFooter>
                </Card>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggletarjeta}>{"Cancelar"}</Button>
                        </ModalFooter>
                    </Modal>
            <MenuItem onClick={envconf}>Configuración</MenuItem>
          </Menu>
        </div>
      </div>

      {/* <div className="sidebarxd__search">
        <div className="sidebarxd__searchContainer">
          <IconButton>
            <SearchOutlined />
          </IconButton>
          <input type="text" placeholder="Search or start new chat" />
        </div>
      </div> */}

    

      <div className="sidebarxd__chats">
       {conversations.map(data => (
         
      <div className="contact" >
      <div className="contact__avatar">
        {/* <AccountCircleIcon /> */}       
                                  <div className="circle-dashed"></div>
           <img className="img-30 rounded-circle mr-0" src={prov} style={{left: "-2.98613px", position: "relative",top: "8.02064px"}}alt=""/> 
        </div>  
      <div className="contact__text"onClick={() => pasar(data)}>
        
      <div id="rem"> 
        <h2  style={{top: "0px",position: "relative",left: "15px"}}>{data.name_destinatario}</h2>
        </div>
        <div id="dest"> 
        <h2  style={{top: "0px",position: "relative",left: "15px"}}>{data.name_remitente}</h2>
        </div>

        {/* <p style={{top: "-0.188px",position: "relative",left: "15px"}}>{data.text}</p> */}
        
      </div>
    
    </div>
     ))}
 </div>
 




    </div>
  );
};
export default Sidebarxd;
