import React, {useState,useEffect} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import man from '../../assets/images/dashboard/user.png'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import {Users,Settings,LogOut} from 'react-feather'
import {firebase_app} from '../../data/config'
import { Admin,WebDesigner,Profile,Setting,LogOuts, LightButton } from "../../constant";
import {withRouter} from 'react-router-dom'
import {useSelector} from 'react-redux'
import prov from "../../assets/images/dashboard/prov.png"
import comp from "../../assets/images/dashboard/comp.png"
import axios from 'axios';

const UserActivity = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const currentUser= useSelector(state => state.Common.currentUser)
    const [profile, setProfile] = useState('');
    const [name, setName] = useState('')
    // auth0 profile
    const {logout} = useAuth0()
    const authenticated = JSON.parse(localStorage.getItem("authenticated"));
    const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"))

    
    useEffect(() => {
        setProfile(localStorage.getItem('profileURL' || man));
        setName(localStorage.getItem('Name'))
    },[]);

    const Logout_From_Firebase = () => {
      localStorage.removeItem('profileURL')
      localStorage.removeItem('token');
      firebase_app.auth().signOut()
      props.history.push(`${process.env.PUBLIC_URL}/login`)
    }
  
    const  Logout_From_Auth0 = () =>  {
      localStorage.removeItem("auth0_profile")
      localStorage.setItem("authenticated",false)
      props.history.push(`${process.env.PUBLIC_URL}/login`)
      logout()
    }
    
    const Configuracion = () => {
      props.history.push(`${process.env.PUBLIC_URL}/users/userEdit`)
    } 
    const Licencias = () =>{
      props.history.push(`${process.env.PUBLIC_URL}/price/Pricing`)
    }
    

   
    const [imgp, setimgp] = useState("");
    const [perfilimg, setperfilimg] = useState("");

    
    const imgsrx = "https://8751991ba23d.ngrok.io/"+perfilimg;

    useEffect(()=>{
    axios.get('https://8751991ba23d.ngrok.io/gbn/miimg',{
      params: {
        foo: currentUser.id
      }})
      .then((res) => {
        setperfilimg(res.data[0])
      })
    },[])
     
      axios.get('https://8751991ba23d.ngrok.io/gbn/request',{
      params: {
        foo: currentUser.id
      }}).then((response) => {
        setimgp(response.data.length);
      });

      const Imagendeperfil = () =>{

        if (imgp == 0) {
    
          if(currentUser.role =="Proveedor") {
    
            return(
              <span className="media user-header"><img className="mr-2 rounded-circle img-35" src={prov} style={{width:"35px",height:"35px"}} alt=""/>
              </span>
    
            )
          }if (currentUser.role == "Comprador") {
    
            return (
              <span className="media user-header"><img  className="mr-2 rounded-circle img-35" src={comp} style={{width:"35px",height:"35px"}} alt=""/>
              </span>
    
            )
    
          }
    
    
        }
        if (imgp == 1) {
    
          return (
            <span className="media user-header"><img  className="mr-2 rounded-circle img-35" src={imgsrx} style={{width:"35px",height:"35px"}} alt=""/>
            </span>
          )
    
        }
  
      }

    return(
      
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
    <DropdownToggle className="step1"> 
            
            <Imagendeperfil/>
            {/* <span className="media user-header"><img className="mr-2 rounded-circle img-35" src={authenticated ? auth0_profile.picture : profile} style={{width:"35px",height:"35px"}} alt=""/>
            </span> */}
    </DropdownToggle>
    <DropdownMenu className="p-0">
    <ul className="profile-dropdown">
            <li className="gradient-primary-1">
              <h6 className="mb-0">{currentUser.Nombre}</h6>
              <h6 className="mb-0">{currentUser.Apellido}</h6>
            </li>
           <li className="btn-link text-capitalize"   style={{cursor:'pointer',color:"#133862"}} onClick ={Configuracion}><Settings/>{"Editar Perfil"}</li> 
            {/* <li className="btn-link text-capitalize" style={{cursor:'pointer'}}onClick ={Licencias}><Users/>{"Licencias"}</li>  */}
            <li className="btn-link text-capitalize" style={{cursor:'pointer',color:"#133862"}} onClick={authenticated ? Logout_From_Auth0 : Logout_From_Firebase}><LogOut/>{"Cerrar sesion"}</li>
      </ul>
    </DropdownMenu>
    </Dropdown>
    )
}

export default withRouter(UserActivity)