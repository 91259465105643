import React,{useState,Fragment } from "react";
import Chat from "./chat";
import NoChatSelected from "./NoChatSelected";
import Sidebarxd from "./Sidebarxd";



const HomePage = () => {
    
    const [canShowChat, setCanShowChat] = useState(false);
    
    return (
      <div style={{ display: "flex" }}>
        <Sidebarxd setCanShowChat={setCanShowChat}/>
        {canShowChat ?  <Chat   /> : <NoChatSelected />}
      </div>
    );
  };

export default HomePage;