import React,{useState,Fragment,useEffect} from 'react';
import {Container,Row,Col,Card,Form,FormGroup,Label,Input,Button} from 'reactstrap' 
import SweetAlert from 'sweetalert2'
import {useSelector} from 'react-redux'
import axios from 'axios';
import {firebase_app} from '../../data/config'


const Resetpwd = (props) => {

//   const currentUser= useSelector(state => state.Common.currentUser)
//   const [contra1, setcontra1] = useState("")
//   const [contra2, setcontra2] = useState("")
//   const [envcontra, setenvcontra] = useState("")
//   firebase_app.auth().signInWithEmailAndPassword(currentUser.Correo, currentUser.Contraseña).then( (usercredential) => {    

//   })
  
//   const cambiarcontra = () => {


//     if (contra1 == contra2) {
     
//         var user = firebase_app.auth()
//         user.updatePassword(currentPassword).then(() => {
//           axios.put("https://70ae6ed1cdbe.ngrok.io/gbn/passwordedit", {
//             contra:contra1,
//             id:currentUser.id
//            }).then(res => {
            
//              SweetAlert.fire({title:"Completado", text:"Se han efectuado los cambios exitosamente", icon:"success"});          
//            })
//          })
         
//     }else {
//       SweetAlert.fire({title:"ERROR", text:"Las contraseñas no coinciden, intentelo nuevamente", icon:"error"});
//     }
  

  

// }

    return (
    <div className="page-wrapper">
      <Container fluid={true}>
        {/* <div className="authentication-main mt-0">
          <Row>
            <Col md="12" className="p-0">
              <div className="auth-innerright auth-minibox">
                <div className="authentication-box auth-minibox1">
                  <div className="text-center"><img src={require("../../assets/images/other-images/creative-logo1.png")} alt=""/></div>
                  <Card className="mt-4 p-4">
                    <Form className="theme-form">
                      <h5 className="f-16 mb-3 f-w-600">{"Reestablecer contraseña"}</h5>
                      <FormGroup>
                        <Label className="col-form-label">{"Nueva contraseña"}</Label>
                        <Input className="form-control" type="password" onChange={e => setcontra1(e.target.value)} placeholder="*****"/>
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">{"Escribe nuevamente la contraseña"}</Label>
                        <Input className="form-control" type="password" onChange={e => setcontra2(e.target.value)} placeholder="*****"/>
                      </FormGroup>
                      <FormGroup className="form-row mb-0">
                        <Col md="2">
                          <Button color="primary" onClick={cambiarcontra}>{"Guardar"}</Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </div> */}
      </Container>
    </div>
    );
}

export default Resetpwd;