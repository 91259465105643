import React from 'react'
import { MessageSquare} from 'react-feather';
import BellDropdown from './BellDropdown'
import UserActivity from './UserActivity'
import DropletHeader from './Droplet'
import Search from './Search'
import {RightSidebarToggle} from '../../redux/common/actions'
import { useSelector, useDispatch } from 'react-redux';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
const Rightbar = () => {
  const mobileRightTog = useSelector(state => state.Common.mobileRightToggle)
  const rightSidebarToggle = useSelector(state => state.Common.rightSidebarToggle)
  const dispatch = useDispatch();

    const chat = () => {
      window.location.href = `${process.env.PUBLIC_URL}/#/Chat-app/chat`
    }

    return(
          <div className="nav-right col pull-right right-menu">
            <ul className={`nav-menus ${mobileRightTog ? 'open': ''}`}>
            <li>
                <BellDropdown/>
              </li>
            
            <li onClick={chat}><a  style={{cursor:'pointer'}}><ForumOutlinedIcon/></a></li>
              <li> 
               <UserActivity/>
              </li>
              
            </ul>
          </div> 
    )
}


export default Rightbar