import React, {useState,Fragment, useEffect, Component} from 'react'
import BreadCrumb from '../../layout/Breadcrumb'
import { Container, Row, Col, Card, CardBody, CardHeader, Button, Table,ModalHeader, ModalBody, ModalFooter, Modal, FormGroup} from 'reactstrap'
import {Link} from "react-router-dom";
import {RFQS} from "../../constant";
import RFQSEnviadosContadores from './common/RFQSEnviadosContadores'
import {useSelector} from 'react-redux'
import axios from 'axios';
import Axios from 'axios';
import { List } from 'react-feather';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com'
import {Default_Step,Pearls_Step_States} from '../../constant/index'
import {PearlsStepStates} from "../advance/Steps/StepsComponent";
import StepZilla from 'react-stepzilla'
import Tour from 'reactour'
import {disableBodyScroll,enableBodyScroll} from 'body-scroll-lock'
const pearlsstepstates =[{name: 'Step 9',component:<PearlsStepStates/>}]

const steps = [
  {
      selector: '.paso1',
      content: 'Pulsa este boton para crear un RFQ',
  },
  {
      selector: '.paso2',
      content: 'Aqui encontraremos la lista de nuestros RFQs creados',
  },
  {
    selector: '.paso3',
    content: 'Esta es la lista de RFQs que hemos asignado ',
}

]



const DashboardComprador = (props) => {
  //tour
  const [opentour,setopentour] = useState(false)
  const closeTour = () => {
      setopentour(false);
    };

    const tuto = () =>{
      setTimeout(() => {
        setopentour(true);
      }, 350);
    }

    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);

//lodemas

  const [modale, setModale] = useState();
  const [modale2, setModale2] = useState();
  const [cargador, setcargador] = useState();
  const [masig, setmasig] = useState();
  const [list, setlist] = useState([])
  const [seglist, setseglist] = useState([])
  const [empresa, setempresa] = useState("");
  const [terlist, setterlist] = useState([]);
  
  const currentUser= useSelector(state => state.Common.currentUser)

   

  useEffect(() => {
    axios.get('https://8751991ba23d.ngrok.io/gbn/asig', {
      params: {
        foo: currentUser.id
      }}      
      ).then((response) => {
          setterlist(response.data);        
        });
      }, []);

    
      useEffect(() => {
    axios.get('https://8751991ba23d.ngrok.io/gbn/compradores', {
      params: {
        foo: currentUser.id
      }}      
      ).then((response) => {
          setseglist(response.data);          
        });
      }, []);





        

const [seleccionado, setseleccionado] = useState({
  RFQsl:'',
  Empresa_solicitante:'',
  Categoria:'',
  Descripcion:'',
  Cantidad_solicitada:'',
  Unidadmedida:'',
  Precioobj:'',
  diasdecredito:'', 
  Moneda:'', 
  Fechavigencia:'',
  lugaentrega:'',
  Estatussl:'',
  Empresa_interesada:'',
  idui:''
  
});

const [seleccionado2, setseleccionado2] = useState({
  RFQsl:'',
  Empresa_solicitante:'',
  Categoria:'',
  Descripcion:'',
  Cantidad_solicitada:'',
  Unidadmedida:'',
  Precioobj:'',
  diasdecredito:'', 
  Moneda:'', 
  Fechavigencia:'',
  lugaentrega:'',
  Estatussl:'',
  Empresa_interesada:'',
  idui:'',
  iduc:''
  
});
 const toggle2 = (data) => {
  setseleccionado(data)
  setModale2(!modale2) 
  
 }

  

const toggle = () => {
  
          setModale(!modale)
     
 }

 const toggle3 = (data) => {
   setseleccionado(data)
   setcargador(true)
   setTimeout(()=>{
    setModale(!modale)
    setcargador(false)           
}, 2500)
 }


axios.get('https://8751991ba23d.ngrok.io/gbn/aceptados', {
  params: {
    foo: seleccionado.RFQsl
  }}      
      ).then((response) => {
          setlist(response.data);
        });

      
 const toggle4 = (data) =>{
   setseleccionado2(data)
   setmasig(true)

 }

 
        const aceptarycontinuar = () => { 
          toast.error("Felicidades, tu RFQ ha sido asignado a un Proveedor, se ha enviado un Email para proceder a la entrega del producto", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000
          });
            axios.post('https://70ae6ed1cdbe.ngrok.io/gbn/ganadores', 
      
      {RFQsl:seleccionado2.RFQsl,
      Empresa_solicitante:seleccionado2.Empresa_solicitante,
      Categoria:seleccionado2.Categoria,
      Descripcion:seleccionado2.Descripcion,
      Cantidad_Solicitada:seleccionado2.Cantidad_Solicitada,
      Unidadmedida:seleccionado2.Unidadmedida,
      Precioobj:seleccionado2.Precioobj,
      diasdecredito:seleccionado2.diasdecredito, 
      Moneda:seleccionado2.Moneda, 
      Fechavigencia:seleccionado2.Fechavigencia, 
      lugaentrega:seleccionado2.lugaentrega, 
      Estatussl:seleccionado2.Estatussl,
      idug:seleccionado2.idui,
      iduc:seleccionado2.iduc
      }).then((response) => {
        Axios.get('https://70ae6ed1cdbe.ngrok.io/gbn/usuariosanotificar3', { 
          params: {
           foo: seleccionado.ui
         }}    
                 ).then((response) => {
                  axios.delete('https://70ae6ed1cdbe.ngrok.i/gbn/dltsegundalista', {
                    params: {
                      foo: seleccionado.RFQsl
                    }}      
                    )
                     var usuariosanotificar = response.data
               usuariosanotificar.forEach(usuario=>{
                 var params = {
                   fromName:currentUser.Nombre, 
                   toName:usuario.Nombre, 
                   toEmail:usuario.Correo,
                   
                 }
                   emailjs.send('service_ruxs3u7', 'template_np892ov', params, 'user_6Yiy6j6dBtbUgO2vEhYPP').then((res)=>{
                      console.log('emailjs', res)
                   })
               })
           });
        
    });
    setModale(!modale)
    setmasig(false)
    setTimeout(()=>{
      window.location.reload();                  
      }, 3500)
    };
        
        const eliminar = () => { 

          toast.warn("Tu RFQ se ha eliminado exitosamente", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000
            });

            axios.delete('https://70ae6ed1cdbe.ngrok.io/gbn/dltsegundalista', {
              params: {
                foo: seleccionado.RFQsl
              }}      
              ).then((response) => {

                });
                setModale2(!modale2)

                  // window.location.href = `${process.env.PUBLIC_URL}/#/widgets/dashboardcomprador`

                 setTimeout(()=>{
                  window.location.reload();                  
                  }, 800)
              }

              const crearRFQbutton = () => { 
                setTimeout(()=>{
                  window.location.href = `${process.env.PUBLIC_URL}/#/widgets/crearRFQ`
                  
                 }, 300)
                 
              }

    return (
      <Fragment>
        <Tour
          steps={steps}
          rounded={5}
          isOpen={opentour}
          disableInteraction={true}
          disableKeyboardNavigation={false}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
           />
        <Container fluid={true}>
        <Row>

    
          {/* <Col sm="12" xl="12" lg="12">
            <RFQSEnviadosContadores/>
          </Col> */}



          {/* <Col xl="12" className="xl-100 box-col-12">
                <Card className="widget-joins">
                  <Row>
                     <Col sm="6" className="pr-0">
                      <div className="media border-after-xs">
                        <div className="align-self-center mr-3">{"0%"}<i className="fa fa-angle-up ml-2"></i></div>
                        <div className="media-body details pl-3"><span className="mb-1">{New}</span>
                          <h4 className="mb-0 counter digits">{"0"}</h4>
                        </div>
                        <div className="media-body align-self-center"><ShoppingBag className="font-primary float-left ml-2"/></div>
                      </div>
                    </Col>
                    <Col sm="6" className="pl-0">
                      <div className="media">
                        <div className="align-self-center mr-3 digits">{"0"}<i className="fa fa-angle-down ml-2"></i></div>
                        <div className="media-body details pl-3"><span className="mb-1">{Pending}</span>
                          <h4 className="mb-0 counter digits">{"0"}</h4>
                        </div>
                        <div className="media-body align-self-center"><Layers className="font-primary float-left ml-3"/></div>
                      </div>
                    </Col>
                    <Col sm="6" className="pr-0">
                      <div className="media border-after-xs">
                        <div className="align-self-center mr-3">{"0%"}<i className="fa fa-angle-up ml-2"></i></div>
                        <div className="media-body details pl-3 pt-0"><span className="mb-1">{Done}</span>
                          <h4 className="mb-0 counter digits">{"0"}</h4>
                        </div>
                        <div className="media-body align-self-center"><ShoppingCart className="font-primary float-left ml-2"/></div>
                      </div>
                    </Col>
                    <Col sm="6" className="pl-0">
                      <div className="media">
                        <div className="align-self-center mr-3">{"0%"}<i className="fa fa-angle-up ml-2"></i></div>
                        <div className="media-body details pl-3 pt-0"><span className="mb-1">{Cancel}</span>
                          <h4 className="mb-0 counter digits">{"0"}</h4>
                        </div>
                        <div className="media-body align-self-center"><DollarSign className="font-primary float-left ml-2"/></div>
                      </div>
                    </Col>
                  </Row>
                </Card>
          </Col> */}
          <Col sm="12">
            <Card>
              <CardHeader>
              </CardHeader>
              <CardBody>
                <StepZilla steps={pearlsstepstates} showSteps={false}  showNavigation={false}/>
              </CardBody>
              </Card>
          </Col>
          <Col >
             <CardBody className="blockquote text-center">
            <Button className="btn-pill" color="primary" size="lg"  onClick={tuto}>{"Tutorial"}</Button>
            </CardBody>
                                </Col>
          <Col xl="12" className="xl-100 box-col-12">
            <Card>
              <CardHeader>
                <h5 className="mb-0 f-24">{RFQS} {"Enviados"}</h5>
                {/* <Button className="btn btn-success pull-right">{Descargar} {Lista}</Button> */}
               <Button className="paso1 pull-right" color="secondary" onClick = {crearRFQbutton}> {'Crear RFQ'}</Button>
              </CardHeader>              
              <CardBody>
                <div className="paso2 user-status table-responsive">
                  <Table borderless>
                    <thead>
                      <tr>
                      <th scope="col">{"RFQ"}</th>
                          <th scope="col">{"Categoria"}</th>
                          <th scope="col">{"Descripción"}</th>
                          <th scope="col">{"Cantidad Solicitada"}</th>
                          <th scope="col">{"Unidad de Medida"}</th>
                          {/* <th scope="col">{"Precio Objetivo"}</th> */}
                          <th scope="col">{"Dias de acreditación"}</th>
                          <th scope="col">{"Moneda"}</th>
                          <th scope="col">{"Fecha de Vigencia"}</th>
                          <th scope="col">{"Lugar de entrega"}</th>
                          <th scope="col">{"Estatus"}</th>
                          <th scope="col">{"Acciones"}</th>
                      </tr>
                    </thead>
                    <tbody>
                    {seglist.map(data => (
                      <tr >
                         <td>{data.RFQsl}</td>
                         <td>{data.Categoria}</td>
                         <td>{data.Descripcion}</td>
                         <td>{data.Cantidad_solicitada}</td>
                         <td>{data.Unidadmedida}</td>
                         {/* <td>{data.Precioobj}</td> */}
                         <td>{data.diasdecredito}</td>
                         <td>{data.Moneda}</td>
                         <td>{data.Fechavigencia}</td>
                         <td>{data.lugaentrega}</td>
                         <td>
                           <div className={`span badge badge-pill badge-primary`}>{data.Estatussl}</div>
                         </td>
                         <td><Button  className="paso4" color="primary" onClick={() => toggle3(data)} >{"Propuestas"}</Button> 
                         <td><Button className="paso5" color="secondary" onClick={() => toggle2(data)} >{"Eliminar"}</Button></td> </td>
                         <Modal isOpen={cargador} toggle3={toggle3} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <Col md="12">
                      <div className="loader-box">
                        <div  className="loader-35"></div>
                      </div>
                    </Col>
                        </ModalBody>
                    </Modal>
                         <Modal isOpen={modale} toggle={toggle} className="modal-body" size="lg" centered={true}>
                         <ModalHeader toggle={toggle}>{'Propuestas de Proveedores'}
                        </ModalHeader>
                        <ModalBody>
                        <CardBody>
                        <div className="user-status table-responsive">
                        <Table borderless>
                      <thead>
                        <tr>
                          <th scope="col">{"RFQ"}</th>
                          <th scope="col">{"Empresa Interesada"}</th>
                          <th scope="col">{"Precio ofertado"}</th>
                          <th scope="col">{"Acciones"}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {list.map(data => (
                        <tr >
                        <td>{data.RFQsl}</td>
                        <td>{data.Empresa_solicitante}</td>
                        <td>{data.Precioobj}</td>
                        <td><Button onClick={() => toggle4(data)}  >{"Aceptar"}</Button></td>
                        </tr>
                        ))} 
                         </tbody>
                         </Table>
                         </div>
                         </CardBody>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={masig} toggle4={toggle4} className="modal-body"  centered={true}>
                         <ModalHeader >{''}
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                        <label>
                 <h5> Si Aceptas este RFQ, asignaras un ganador a la oferta y estara concluida tu compra, ¿deseas continuar?</h5>                 
              </label>
              </FormGroup>
                        <FormGroup>
                        <label> Precio Final : </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.Precioobj} />
                        </FormGroup>
                        <FormGroup>
                        <label> RFQ: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.RFQsl} />
                        </FormGroup>
                        <FormGroup>
                        <label> Categoria: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.Categoria}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Empresa Solicitante:</label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.Empresa_solicitante}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Producto Solicitado: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.Descripcion}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Cantidad Solicitada: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.Cantidad_Solicitada}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Fecha de vigencia: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.Fechavigencia}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Estatus </label>
                        <input className="form-control"   readOnly type="text" value={seleccionado2 && seleccionado2.Estatussl}/>
                        {/* añadir campo de attachment */}
                        </FormGroup>
                        
                        </ModalBody>
                        <ModalFooter>
                        <Button color="secondary" onClick={toggle4}>{'Cerrar'}</Button>
                        <Button color="primary"onClick={aceptarycontinuar} >{'Aceptar y continuar'}</Button>
                        
                        </ModalFooter>
                    </Modal>
                    
                    <Modal isOpen={modale2} toggle2={toggle2} className="modal-body"  centered={true}>
                         <ModalHeader toggle2={toggle2}>{'¿Estas seguro de eliminar este RFQ?'}
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                        <label>
                 <h5> Si eliminas este RFQ, será borrado y los proveedores no podrán verlo jamas</h5>                 
              </label>
              <h6>{seleccionado.RFQsl}</h6>
              <h6>{seleccionado.Descripcion}</h6>
                        </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="secondary" onClick={toggle2}>{'Cancelar'}</Button>
                        <Button color="primary" className="sweet-8" name="alertSuccess" onClick= {eliminar}>{'Confirmar y eliminar'}</Button>                        
                        </ModalFooter>
                    </Modal>
                      </tr>
                    ))} 
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              
              <CardHeader>
                <h5 className="mb-0 f-24">{"Lista de RFQS asignados"}</h5>
              </CardHeader>
              <CardBody>
                <div className="paso3 user-status table-responsive">
                  <Table borderless>
                    <thead>
                      <tr>
                      <th scope="col">{"RFQ"}</th>
                   
                          <th scope="col">{"Categoria"}</th>
                          <th scope="col">{"Descripción"}</th>
                          <th scope="col">{"Cantidad Solicitada"}</th>
                          <th scope="col">{"Unidad de Medida"}</th>
                          <th scope="col">{"Dias de acreditación"}</th>
                          <th scope="col">{"Moneda"}</th>
                          <th scope="col">{"Fecha de Vigencia"}</th>
                          <th scope="col">{"Lugar de entrega"}</th>
                          <th scope="col">{"Precio Final"}</th>
                          <th scope="col">{"Estatus"}</th>
                      </tr>
                    </thead>
                    <tbody>
                    {terlist.map(data => (
                      <tr >
                         <td>{data.RFQsl}</td>
                  
                         <td>{data.Categoria}</td>
                         <td>{data.Descripcion}</td>
                         <td>{data.Cantidad_Solicitada}</td>
                         <td>{data.Unidadmedida}</td>
                         <td>{data.diasdecredito}</td>
                         <td>{data.Moneda}</td>
                         <td>{data.Fechavigencia}</td>
                         <td>{data.lugaentrega}</td> 
                         <td>{data.Precioobj}</td>
                         <td>
                           <div className={`span badge badge-pill badge-primary`}>{data.Estatussl}</div>
                         </td>
                         </tr>
                    ))} 
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
{/* otra tabla */}
        </Row>
        </Container>
        </Fragment>
    )
}

export default DashboardComprador
