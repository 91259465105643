
import u1 from '../../../assets/images/dashboard/boy-2.png'
import u2 from '../../../assets/images/user/4.jpg'
import u3 from '../../../assets/images/user/1.jpg'
import u4 from '../../../assets/images/user/5.jpg'
import u5 from '../../../assets/images/user/6.jpg'
import u6 from '../../../assets/images/user/7.jpg'


export const clients = [
  {
    imagepath: u1,
    title: "Nick Stone",
    position: "Developer",
    desc: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia"
  },
  {
    imagepath: u1,
    title: "Poio klot",
    position: "Designer",
    desc: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia"
  },
  {
    imagepath: u1,
    title: "Scarlett john",
    position: "ceo",
    desc: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia"
  }
]

export const cartProducts = [
        {
            id: 1,
            Codigo: 10100000,
            Descripcion_Español: "ANIMALES VIVOS",
            Descripcion_Ingles: null
        },
        {
            id: 2,
            Codigo: 10110000,
            Descripcion_Español: "PRODUCTOS PARA ANIMALES DOMESTICOS",
            Descripcion_Ingles: null
        },
        {
            id: 3,
            Codigo: 10120000,
            Descripcion_Español: "COMIDA DE ANIMALES",
            Descripcion_Ingles: null
        },
        {
            id: 4,
            Codigo: 10130000,
            Descripcion_Español: "RECIPIENTES Y HABITAT PARA ANIMALES",
            Descripcion_Ingles: null
        },
        {
            id: 5,
            Codigo: 10140000,
            Descripcion_Español: "PRODUCTOS DE TALABARTERIA Y ARREO",
            Descripcion_Ingles: null
        },
        {
            id: 6,
            Codigo: 10160000,
            Descripcion_Español: "PRODUCTOS DE FLORICULTURA Y SILVICULTURA",
            Descripcion_Ingles: null
        },
        {
            id: 7,
            Codigo: 10170000,
            Descripcion_Español: "FERTILIZANTES Y NUTRIENTES PARA PLANTAS Y HERBICIDAS",
            Descripcion_Ingles: null
        },
        {
            id: 8,
            Codigo: 10190000,
            Descripcion_Español: "PRODUCTOS PARA EL CONTROL DE PLAGAS",
            Descripcion_Ingles: null
        },
        {
            id: 9,
            Codigo: 10500000,
            Descripcion_Español: "FOLLAJE CORTADO FRESCO",
            Descripcion_Ingles: null
        },
        {
            id: 10,
            Codigo: 11100000,
            Descripcion_Español: "MINERALES, MINERALES METALICOS Y METALES",
            Descripcion_Ingles: null
        },
        {
            id: 11,
            Codigo: 11110000,
            Descripcion_Español: "TIERRA Y PIEDRA",
            Descripcion_Ingles: null
        },
        {
            id: 12,
            Codigo: 11120000,
            Descripcion_Español: "PRODUCTOS NO COMESTIBLES DE PLANTA Y SILVICULTURA",
            Descripcion_Ingles: null
        },
        {
            id: 13,
            Codigo: 11130000,
            Descripcion_Español: "PRODUCTOS ANIMALES NO COMESTIBLES",
            Descripcion_Ingles: null
        },
        {
            id: 14,
            Codigo: 11140000,
            Descripcion_Español: "CHATARRA Y MATERIALES DE DESECHO",
            Descripcion_Ingles: null
        },
        {
            id: 15,
            Codigo: 11150000,
            Descripcion_Español: "FIBRA, HILOS E HILADOS",
            Descripcion_Ingles: null
        },
        {
            id: 16,
            Codigo: 11160000,
            Descripcion_Español: "TEJIDOS Y MATERIALES DE CUERO",
            Descripcion_Ingles: null
        },
        {
            id: 17,
            Codigo: 11170000,
            Descripcion_Español: "ALEACIONES",
            Descripcion_Ingles: null
        },
        {
            id: 18,
            Codigo: 12130000,
            Descripcion_Español: "MATERIALES EXPLOSIVOS",
            Descripcion_Ingles: null
        },
        {
            id: 19,
            Codigo: 12140000,
            Descripcion_Español: "ELEMENTOS Y GASES",
            Descripcion_Ingles: null
        },
        {
            id: 20,
            Codigo: 12160000,
            Descripcion_Español: "ADITIVOS",
            Descripcion_Ingles: null
        },
        {
            id: 21,
            Codigo: 12170000,
            Descripcion_Español: "COLORANTES",
            Descripcion_Ingles: null
        },
        {
            id: 22,
            Codigo: 12180000,
            Descripcion_Español: "CERAS Y ACEITES",
            Descripcion_Ingles: null
        },
        {
            id: 23,
            Codigo: 12190000,
            Descripcion_Español: "SOLVENTES",
            Descripcion_Ingles: null
        },
        {
            id: 24,
            Codigo: 12350000,
            Descripcion_Español: "COMPUESTOS Y MEZCLAS",
            Descripcion_Ingles: null
        },
        {
            id: 25,
            Codigo: 13100000,
            Descripcion_Español: "CAUCHO Y ELASTOMEROS",
            Descripcion_Ingles: null
        },
        {
            id: 26,
            Codigo: 13110000,
            Descripcion_Español: "RESINAS Y COLOFONIAS Y OTROS MATERIALES DERIVADOS DE RESINA",
            Descripcion_Ingles: null
        },
        {
            id: 27,
            Codigo: 14100000,
            Descripcion_Español: "MATERIALES DE PAPEL",
            Descripcion_Ingles: null
        },
        {
            id: 28,
            Codigo: 14110000,
            Descripcion_Español: "PRODUCTOS DE PAPEL",
            Descripcion_Ingles: null
        },
        {
            id: 29,
            Codigo: 14120000,
            Descripcion_Español: "PAPEL PARA USO INDUSTRIAL",
            Descripcion_Ingles: null
        },
        {
            id: 30,
            Codigo: 15100000,
            Descripcion_Español: "COMBUSTIBLES",
            Descripcion_Ingles: null
        },
        {
            id: 31,
            Codigo: 15110000,
            Descripcion_Español: "COMBUSTIBLES GASEOSOS Y ADITIVOS",
            Descripcion_Ingles: null
        },
        {
            id: 32,
            Codigo: 15120000,
            Descripcion_Español: "LUBRICANTES, ACEITES, GRASAS Y ANTICORROSIVOS",
            Descripcion_Ingles: null
        },
        {
            id: 33,
            Codigo: 15130000,
            Descripcion_Español: "COMBUSTIBLE PARA REACTORES NUCLEARES",
            Descripcion_Ingles: null
        },
        {
            id: 34,
            Codigo: 20100000,
            Descripcion_Español: "MAQUINARIA Y EQUIPO DE MINERIA Y EXPLOTACION DE CANTERAS",
            Descripcion_Ingles: null
        },
        {
            id: 35,
            Codigo: 20110000,
            Descripcion_Español: "EQUIPO DE PERFORACION Y EXPLOTACION DE POZOS",
            Descripcion_Ingles: null
        },
        {
            id: 36,
            Codigo: 20120000,
            Descripcion_Español: "EQUIPO PARA PERFORACION Y EXPLORACION DE PETROLEO Y GAS",
            Descripcion_Ingles: null
        },
        {
            id: 37,
            Codigo: 20130000,
            Descripcion_Español: "MATERIALES PARA  PERFORACION Y OPERACIONES DE PETROLEO Y GAS",
            Descripcion_Ingles: null
        },
        {
            id: 38,
            Codigo: 20140000,
            Descripcion_Español: "EQUIPO DE PRODUCCION Y OPERACION DE PETROLEO Y GAS",
            Descripcion_Ingles: null
        },
        {
            id: 39,
            Codigo: 21100000,
            Descripcion_Español: "MAQUINARIA Y EQUIPO PARA AGRICULTURA, SILVICULTURA Y PAISAJISMO",
            Descripcion_Ingles: null
        },
        {
            id: 40,
            Codigo: 21110000,
            Descripcion_Español: "EQUIPO DE PESCA Y ACUICULTURA",
            Descripcion_Ingles: null
        },
        {
            id: 41,
            Codigo: 22100000,
            Descripcion_Español: "MAQUINARIA Y EQUIPO PESADO DE CONSTRUCCION",
            Descripcion_Ingles: null
        },
        {
            id: 42,
            Codigo: 23100000,
            Descripcion_Español: "MAQUINARIA PARA EL PROCESAMIENTO DE MATERIAS PRIMAS",
            Descripcion_Ingles: null
        },
        {
            id: 43,
            Codigo: 23110000,
            Descripcion_Español: "MAQUINARIA PARA EL PROCESAMIENTO DE PETROLEO",
            Descripcion_Ingles: null
        },
        {
            id: 44,
            Codigo: 23120000,
            Descripcion_Español: "MAQUINARIA Y ACCESORIOS DE TEXTILES Y TEJIDOS",
            Descripcion_Ingles: null
        },
        {
            id: 45,
            Codigo: 23130000,
            Descripcion_Español: "MAQUINARIA Y EQUIPOS LAPIDARIOS",
            Descripcion_Ingles: null
        },
        {
            id: 46,
            Codigo: 23140000,
            Descripcion_Español: "MAQUINARIA DE REPARACION Y ACCESORIOS PARA MARROQUINERIA",
            Descripcion_Ingles: null
        },
        {
            id: 47,
            Codigo: 23150000,
            Descripcion_Español: "MAQUINARIA, EQUIPO Y SUMINISTROS DE PROCESOS INDUSTRIALES",
            Descripcion_Ingles: null
        },
        {
            id: 48,
            Codigo: 23160000,
            Descripcion_Español: "MAQUINAS, EQUIPO Y SUMINISTROS PARA FUNDICION",
            Descripcion_Ingles: null
        },
        {
            id: 49,
            Codigo: 23180000,
            Descripcion_Español: "EQUIPO INDUSTRIAL PARA ALIMENTOS Y BEBIDAS",
            Descripcion_Ingles: null
        },
        {
            id: 50,
            Codigo: 23190000,
            Descripcion_Español: "MEZCLADORES Y SUS PARTES Y ACCESORIOS",
            Descripcion_Ingles: null
        },
        {
            id: 51,
            Codigo: 23200000,
            Descripcion_Español: "EQUIPAMIENTO PARA TRANSFERENCIA DE MASA",
            Descripcion_Ingles: null
        },
        {
            id: 52,
            Codigo: 23210000,
            Descripcion_Español: "MAQUINARIA DE FABRICACION ELECTRONICA, EQUIPO Y ACCESORIOS",
            Descripcion_Ingles: null
        },
        {
            id: 53,
            Codigo: 23220000,
            Descripcion_Español: "EQUIPO Y MAQUINARIA DE PROCESAMIENTO DE POLLOS",
            Descripcion_Ingles: null
        },
        {
            id: 54,
            Codigo: 23230000,
            Descripcion_Español: "EQUIPO Y MAQUINARIA DE PROCESAMIENTO DE MADERA Y ASERRADO",
            Descripcion_Ingles: null
        },
        {
            id: 55,
            Codigo: 23240000,
            Descripcion_Español: "MAQUINARIA Y ACCESORIOS PARA CORTAR METALES",
            Descripcion_Ingles: null
        },
        {
            id: 56,
            Codigo: 23250000,
            Descripcion_Español: "MAQUINARIA Y ACCESORIOS PARA EL FORMADO DE METALES",
            Descripcion_Ingles: null
        },
        {
            id: 57,
            Codigo: 23260000,
            Descripcion_Español: "MAQUINARIA Y ACCESORIOS PARA HACER PROTOTIPOS RAPIDOS",
            Descripcion_Ingles: null
        },
        {
            id: 58,
            Codigo: 23270000,
            Descripcion_Español: "MAQUINARIA Y ACCESORIOS Y SUMINISTROS PARA SOLDADURA DE TODAS LAS CLASES",
            Descripcion_Ingles: null
        },
        {
            id: 59,
            Codigo: 23280000,
            Descripcion_Español: "MAQUINARIA PARA EL TRATAMIENTO DE METAL",
            Descripcion_Ingles: null
        },
        {
            id: 60,
            Codigo: 23290000,
            Descripcion_Español: "HERRAMIENTAS DE MAQUINADO INDUSTRIAL",
            Descripcion_Ingles: null
        },
        {
            id: 61,
            Codigo: 23300000,
            Descripcion_Español: "MAQUINARIA Y EQUIPO PARA CABLE",
            Descripcion_Ingles: null
        },
        {
            id: 62,
            Codigo: 24100000,
            Descripcion_Español: "MAQUINARIA Y EQUIPO PARA MANEJO DE MATERIALES",
            Descripcion_Ingles: null
        },
        {
            id: 63,
            Codigo: 24110000,
            Descripcion_Español: "RECIPIENTES Y ALMACENAMIENTO",
            Descripcion_Ingles: null
        },
        {
            id: 64,
            Codigo: 24120000,
            Descripcion_Español: "MATERIALES DE EMPAQUE",
            Descripcion_Ingles: null
        },
        {
            id: 65,
            Codigo: 24130000,
            Descripcion_Español: "REFRIGERACION INDUSTRIAL",
            Descripcion_Ingles: null
        },
        {
            id: 66,
            Codigo: 24140000,
            Descripcion_Español: "SUMINISTROS DE EMBALAJE",
            Descripcion_Ingles: null
        },
        {
            id: 67,
            Codigo: 25100000,
            Descripcion_Español: "VEHICULOS DE MOTOR",
            Descripcion_Ingles: null
        },
        {
            id: 68,
            Codigo: 25110000,
            Descripcion_Español: "TRANSPORTE MARITIMO",
            Descripcion_Ingles: null
        },
        {
            id: 69,
            Codigo: 25120000,
            Descripcion_Español: "MAQUINARIA Y EQUIPO PARA FERROCARRIL Y TRANVIAS",
            Descripcion_Ingles: null
        },
        {
            id: 70,
            Codigo: 25130000,
            Descripcion_Español: "AERONAVES",
            Descripcion_Ingles: null
        },
        {
            id: 71,
            Codigo: 25160000,
            Descripcion_Español: "BICICLETAS NO MOTORIZADAS",
            Descripcion_Ingles: null
        },
        {
            id: 72,
            Codigo: 25170000,
            Descripcion_Español: "COMPONENTES Y SISTEMAS DE TRANSPORTE",
            Descripcion_Ingles: null
        },
        {
            id: 73,
            Codigo: 25180000,
            Descripcion_Español: "CARROCERIAS Y REMOLQUES",
            Descripcion_Ingles: null
        },
        {
            id: 74,
            Codigo: 25190000,
            Descripcion_Español: "EQUIPO PARA SERVICIOS DE TRANSPORTE",
            Descripcion_Ingles: null
        },
        {
            id: 75,
            Codigo: 25200000,
            Descripcion_Español: "SISTEMAS  Y COMPONENTES Y EQUIPOS AEROESPACIALES",
            Descripcion_Ingles: null
        },
        {
            id: 76,
            Codigo: 26100000,
            Descripcion_Español: "FUENTES DE ENERGIA",
            Descripcion_Ingles: null
        },
        {
            id: 77,
            Codigo: 26110000,
            Descripcion_Español: "BATERIAS Y GENERADORES Y TRANSMISION DE ENERGIA CINETICA",
            Descripcion_Ingles: null
        },
        {
            id: 78,
            Codigo: 26120000,
            Descripcion_Español: "ALAMBRES, CABLES Y ARNESES",
            Descripcion_Ingles: null
        },
        {
            id: 79,
            Codigo: 26130000,
            Descripcion_Español: "GENERACION DE ENERGIA",
            Descripcion_Ingles: null
        },
        {
            id: 80,
            Codigo: 26140000,
            Descripcion_Español: "MAQUINARIA Y EQUIPO PARA ENERGIA ATOMICA O NUCLEAR",
            Descripcion_Ingles: null
        },
        {
            id: 81,
            Codigo: 27110000,
            Descripcion_Español: "HERRAMIENTAS DE MANO",
            Descripcion_Ingles: null
        },
        {
            id: 82,
            Codigo: 27120000,
            Descripcion_Español: "MAQUINARIA Y EQUIPO HIDRAULICO",
            Descripcion_Ingles: null
        },
        {
            id: 83,
            Codigo: 27130000,
            Descripcion_Español: "MAQUINARIA Y EQUIPO NEUMATICO",
            Descripcion_Ingles: null
        },
        {
            id: 84,
            Codigo: 27140000,
            Descripcion_Español: "HERRAMIENTAS ESPECIALIZADAS AUTOMOTRICES",
            Descripcion_Ingles: null
        },
        {
            id: 85,
            Codigo: 30100000,
            Descripcion_Español: "COMPONENTES ESTRUCTURALES Y FORMAS BASICAS",
            Descripcion_Ingles: null
        },
        {
            id: 86,
            Codigo: 30110000,
            Descripcion_Español: "HORMIGON, CEMENTO Y YESO",
            Descripcion_Ingles: null
        },
        {
            id: 87,
            Codigo: 30120000,
            Descripcion_Español: "CARRETERAS Y PAISAJE",
            Descripcion_Ingles: null
        },
        {
            id: 88,
            Codigo: 30130000,
            Descripcion_Español: "PRODUCTOS DE CONSTRUCCION ESTRUCTURALES",
            Descripcion_Ingles: null
        },
        {
            id: 89,
            Codigo: 30140000,
            Descripcion_Español: "AISLAMIENTO",
            Descripcion_Ingles: null
        },
        {
            id: 90,
            Codigo: 30150000,
            Descripcion_Español: "MATERIALES PARA ACABADO DE EXTERIORES",
            Descripcion_Ingles: null
        },
        {
            id: 91,
            Codigo: 30160000,
            Descripcion_Español: "MATERIALES DE ACABADO DE INTERIORES",
            Descripcion_Ingles: null
        },
        {
            id: 92,
            Codigo: 30170000,
            Descripcion_Español: "PUERTAS Y VENTANAS Y VIDRIO",
            Descripcion_Ingles: null
        },
        {
            id: 93,
            Codigo: 30180000,
            Descripcion_Español: "INSTALACIONES DE PLOMERIA",
            Descripcion_Ingles: null
        },
        {
            id: 94,
            Codigo: 30190000,
            Descripcion_Español: "EQUIPO DE APOYO PARA CONSTRUCCION Y MANTENIMIENTO",
            Descripcion_Ingles: null
        },
        {
            id: 95,
            Codigo: 30240000,
            Descripcion_Español: "COMPONENTES DE CONSTRUCCION DE ESTRUCTURA PORTATIL",
            Descripcion_Ingles: null
        },
        {
            id: 96,
            Codigo: 30250000,
            Descripcion_Español: "ESTRUCTURAS Y MATERIALES DE MINERIA SUBTERRANEA",
            Descripcion_Ingles: null
        },
        {
            id: 97,
            Codigo: 30260000,
            Descripcion_Español: "MATERIALES ESTRUCTURALES",
            Descripcion_Ingles: null
        },
        {
            id: 98,
            Codigo: 31100000,
            Descripcion_Español: "PIEZAS DE FUNDICION Y ENSAMBLES DE PIEZAS DE FUNDICION",
            Descripcion_Ingles: null
        },
        {
            id: 99,
            Codigo: 31110000,
            Descripcion_Español: "EXTRUSIONES",
            Descripcion_Ingles: null
        },
        {
            id: 100,
            Codigo: 31120000,
            Descripcion_Español: "PIEZAS FUNDIDAS MAQUINADAS",
            Descripcion_Ingles: null
        },
        {
            id: 101,
            Codigo: 31130000,
            Descripcion_Español: "FORJADURAS",
            Descripcion_Ingles: null
        },
        {
            id: 102,
            Codigo: 31140000,
            Descripcion_Español: "MOLDURAS",
            Descripcion_Ingles: null
        },
        {
            id: 103,
            Codigo: 31150000,
            Descripcion_Español: "CUERDA, CADENA, CABLE, ALAMBRE Y CORREA",
            Descripcion_Ingles: null
        },
        {
            id: 104,
            Codigo: 31160000,
            Descripcion_Español: "FERRETERIA",
            Descripcion_Ingles: null
        },
        {
            id: 105,
            Codigo: 31170000,
            Descripcion_Español: "RODAMIENTOS, COJINETES RUEDAS Y ENGRANAJES",
            Descripcion_Ingles: null
        },
        {
            id: 106,
            Codigo: 31190000,
            Descripcion_Español: "MATERIALES DE AFILADO PULIDO Y ALISADO",
            Descripcion_Ingles: null
        },
        {
            id: 107,
            Codigo: 31200000,
            Descripcion_Español: "ADHESIVOS Y SELLADORES",
            Descripcion_Ingles: null
        },
        {
            id: 108,
            Codigo: 31210000,
            Descripcion_Español: "PINTURAS Y BASES Y ACABADOS",
            Descripcion_Ingles: null
        },
        {
            id: 109,
            Codigo: 31220000,
            Descripcion_Español: "EXTRACTOS DE TEÑIR Y DE CURTIR",
            Descripcion_Ingles: null
        },
        {
            id: 110,
            Codigo: 31230000,
            Descripcion_Español: "MATERIA PRIMA EN PLACAS O BARRAS LABRADAS",
            Descripcion_Ingles: null
        },
        {
            id: 111,
            Codigo: 31240000,
            Descripcion_Español: "OPTICA INDUSTRIAL",
            Descripcion_Ingles: null
        },
        {
            id: 112,
            Codigo: 31250000,
            Descripcion_Español: "SISTEMAS DE CONTROL NEUMATICO, HIDRAULICO O ELECTRICO",
            Descripcion_Ingles: null
        },
        {
            id: 113,
            Codigo: 31260000,
            Descripcion_Español: "CUBIERTAS, CAJAS Y ENVOLTURAS",
            Descripcion_Ingles: null
        },
        {
            id: 114,
            Codigo: 31270000,
            Descripcion_Español: "PIEZAS HECHAS A MAQUINA",
            Descripcion_Ingles: null
        },
        {
            id: 115,
            Codigo: 31280000,
            Descripcion_Español: "COMPONENTES DE PLACA Y ESTAMPADOS",
            Descripcion_Ingles: null
        },
        {
            id: 116,
            Codigo: 31290000,
            Descripcion_Español: "EXTRUSIONES MAQUINADAS",
            Descripcion_Ingles: null
        },
        {
            id: 117,
            Codigo: 31300000,
            Descripcion_Español: "FORJAS LABRADAS",
            Descripcion_Ingles: null
        },
        {
            id: 118,
            Codigo: 31310000,
            Descripcion_Español: "ENSAMBLES DE TUBERIA FABRICADA",
            Descripcion_Ingles: null
        },
        {
            id: 119,
            Codigo: 31320000,
            Descripcion_Español: "ENSAMBLES FABRICADOS DE MATERIAL EN BARRAS",
            Descripcion_Ingles: null
        },
        {
            id: 120,
            Codigo: 31330000,
            Descripcion_Español: "ENSAMBLES ESTRUCTURALES FABRICADOS",
            Descripcion_Ingles: null
        },
        {
            id: 121,
            Codigo: 31340000,
            Descripcion_Español: "ENSAMBLES DE LAMINA FABRICADO",
            Descripcion_Ingles: null
        },
        {
            id: 122,
            Codigo: 31350000,
            Descripcion_Español: "ENSAMBLES DE TUBERIA FABRICADA",
            Descripcion_Ingles: null
        },
        {
            id: 123,
            Codigo: 31360000,
            Descripcion_Español: "ENSAMBLES DE PLACA FABRICADOS",
            Descripcion_Ingles: null
        },
        {
            id: 124,
            Codigo: 31370000,
            Descripcion_Español: "REFRACTARIOS",
            Descripcion_Ingles: null
        },
        {
            id: 125,
            Codigo: 31380000,
            Descripcion_Español: "IMANES Y MATERIALES MAGNETICOS",
            Descripcion_Ingles: null
        },
        {
            id: 126,
            Codigo: 31390000,
            Descripcion_Español: "MAQUINADOS",
            Descripcion_Ingles: null
        },
        {
            id: 127,
            Codigo: 31400000,
            Descripcion_Español: "EMPAQUES",
            Descripcion_Ingles: null
        },
        {
            id: 128,
            Codigo: 31410000,
            Descripcion_Español: "SELLOS",
            Descripcion_Ingles: null
        },
        {
            id: 129,
            Codigo: 31420000,
            Descripcion_Español: "PARTES SINTERIZADAS",
            Descripcion_Ingles: null
        },
        {
            id: 130,
            Codigo: 32100000,
            Descripcion_Español: "CIRCUITOS IMPRESOS, CIRCUITOS INTEGRADOS Y MICRO ENSAMBLAJES",
            Descripcion_Ingles: null
        },
        {
            id: 131,
            Codigo: 32110000,
            Descripcion_Español: "DISPOSITIVO SEMICONDUCTOR DISCRETO",
            Descripcion_Ingles: null
        },
        {
            id: 132,
            Codigo: 32120000,
            Descripcion_Español: "COMPONENTES PASIVOS DISCRETOS",
            Descripcion_Ingles: null
        },
        {
            id: 133,
            Codigo: 32130000,
            Descripcion_Español: "PIEZAS DE COMPONENTES Y HARDWARE ELECTRONICOS Y ACCESORIOS",
            Descripcion_Ingles: null
        },
        {
            id: 134,
            Codigo: 32140000,
            Descripcion_Español: "DISPOSITIVOS DE TUBO ELECTRONICO Y ACCESORIOS",
            Descripcion_Ingles: null
        },
        {
            id: 135,
            Codigo: 32150000,
            Descripcion_Español: "DISPOSITIVOS Y COMPONENTES Y ACCESORIOS DE CONTROL DE AUTOMATIZACION",
            Descripcion_Ingles: null
        },
        {
            id: 136,
            Codigo: 39100000,
            Descripcion_Español: "LAMPARAS Y BOMBILLAS Y COMPONENTES PARA LAMPARAS",
            Descripcion_Ingles: null
        },
        {
            id: 137,
            Codigo: 39110000,
            Descripcion_Español: "ILUMINACION, ARTEFACTOS Y ACCESORIOS",
            Descripcion_Ingles: null
        },
        {
            id: 138,
            Codigo: 39120000,
            Descripcion_Español: "EQUIPOS, SUMINISTROS Y COMPONENTES ELECTRICOS",
            Descripcion_Ingles: null
        },
        {
            id: 139,
            Codigo: 39130000,
            Descripcion_Español: "DISPOSITIVOS Y ACCESORIOS Y SUMINISTROS DE MANEJO DE CABLE ELECTRICO",
            Descripcion_Ingles: null
        },
        {
            id: 140,
            Codigo: 40100000,
            Descripcion_Español: "CALEFACCION, VENTILACION Y CIRCULACION DEL AIRE",
            Descripcion_Ingles: null
        },
        {
            id: 141,
            Codigo: 40140000,
            Descripcion_Español: "DISTRIBUCION DE FLUIDOS Y GAS",
            Descripcion_Ingles: null
        },
        {
            id: 142,
            Codigo: 40150000,
            Descripcion_Español: "BOMBAS Y COMPRESORES INDUSTRIALES",
            Descripcion_Ingles: null
        },
        {
            id: 143,
            Codigo: 40160000,
            Descripcion_Español: "FILTRADO Y PURIFICACION INDUSTRIAL",
            Descripcion_Ingles: null
        },
        {
            id: 144,
            Codigo: 40170000,
            Descripcion_Español: "INSTALACIONES DE TUBOS Y ENTUBAMIENTOS",
            Descripcion_Ingles: null
        },
        {
            id: 145,
            Codigo: 40180000,
            Descripcion_Español: "INSTALACIONES DE TUBOS Y TUBERIAS",
            Descripcion_Ingles: null
        },
        {
            id: 146,
            Codigo: 41100000,
            Descripcion_Español: "EQUIPO DE LABORATORIO Y CIENTIFICO",
            Descripcion_Ingles: null
        },
        {
            id: 147,
            Codigo: 41110000,
            Descripcion_Español: "INSTRUMENTOS DE MEDIDA, OBSERVACION Y ENSAYO",
            Descripcion_Ingles: null
        },
        {
            id: 148,
            Codigo: 41120000,
            Descripcion_Español: "SUMINISTROS Y ACCESORIOS DE LABORATORIO",
            Descripcion_Ingles: null
        },
        {
            id: 149,
            Codigo: 42120000,
            Descripcion_Español: "EQUIPOS Y SUMINISTROS VETERINARIOS",
            Descripcion_Ingles: null
        },
        {
            id: 150,
            Codigo: 42130000,
            Descripcion_Español: "TELAS Y VESTIDOS MEDICOS",
            Descripcion_Ingles: null
        },
        {
            id: 151,
            Codigo: 42140000,
            Descripcion_Español: "SUMINISTROS, PRODUCTOS DE TRATAMIENTO Y CUIDADO DEL ENFERMO",
            Descripcion_Ingles: null
        },
        {
            id: 152,
            Codigo: 42150000,
            Descripcion_Español: "EQUIPOS Y SUMINISTROS DENTALES",
            Descripcion_Ingles: null
        },
        {
            id: 153,
            Codigo: 42160000,
            Descripcion_Español: "EQUIPO DE DIALISIS Y SUMINISTROS",
            Descripcion_Ingles: null
        },
        {
            id: 154,
            Codigo: 42170000,
            Descripcion_Español: "PRODUCTOS PARA LOS SERVICIOS MEDICOS DE URGENCIAS Y CAMPO",
            Descripcion_Ingles: null
        },
        {
            id: 155,
            Codigo: 42180000,
            Descripcion_Español: "PRODUCTOS DE EXAMEN Y CONTROL DEL PACIENTE",
            Descripcion_Ingles: null
        },
        {
            id: 156,
            Codigo: 42190000,
            Descripcion_Español: "PRODUCTOS DE CENTRO MEDICO",
            Descripcion_Ingles: null
        },
        {
            id: 157,
            Codigo: 42200000,
            Descripcion_Español: "PRODUCTOS DE HACER IMAGENES DIAGNOSTICAS MEDICAS Y DE MEDICINA NUCLEAR",
            Descripcion_Ingles: null
        },
        {
            id: 158,
            Codigo: 42210000,
            Descripcion_Español: "AYUDA PARA PERSONAS CON DESAFIOS FISICOS PARA VIVIR INDEPENDIENTE",
            Descripcion_Ingles: null
        },
        {
            id: 159,
            Codigo: 42220000,
            Descripcion_Español: "PRODUCTOS PARA ADMINISTRACION INTRAVENOSA Y ARTERIAL",
            Descripcion_Ingles: null
        },
        {
            id: 160,
            Codigo: 42230000,
            Descripcion_Español: "NUTRICION CLINICA",
            Descripcion_Ingles: null
        },
        {
            id: 161,
            Codigo: 42240000,
            Descripcion_Español: "PRODUCTOS MEDICINALES DE DEPORTES Y ORTOPEDICOS Y PROTESIS",
            Descripcion_Ingles: null
        },
        {
            id: 162,
            Codigo: 42250000,
            Descripcion_Español: "PRODUCTOS DE REHABILITACION Y TERAPIA OCUPACIONAL Y FISICA",
            Descripcion_Ingles: null
        },
        {
            id: 163,
            Codigo: 42260000,
            Descripcion_Español: "EQUIPO Y SUMINISTROS POST MORTEM Y FUNERARIOS",
            Descripcion_Ingles: null
        },
        {
            id: 164,
            Codigo: 42270000,
            Descripcion_Español: "PRODUCTOS DE RESUCITACION, ANESTESIA Y RESPIRATORIO",
            Descripcion_Ingles: null
        },
        {
            id: 165,
            Codigo: 42280000,
            Descripcion_Español: "PRODUCTOS PARA LA ESTERILIZACION MEDICA",
            Descripcion_Ingles: null
        },
        {
            id: 166,
            Codigo: 42290000,
            Descripcion_Español: "PRODUCTOS QUIRURGICOS",
            Descripcion_Ingles: null
        },
        {
            id: 167,
            Codigo: 42300000,
            Descripcion_Español: "SUMINISTROS PARA FORMACION Y ESTUDIOS DE MEDICINA",
            Descripcion_Ingles: null
        },
        {
            id: 168,
            Codigo: 42310000,
            Descripcion_Español: "PRODUCTOS PARA EL CUIDADO DE HERIDAS",
            Descripcion_Ingles: null
        },
        {
            id: 169,
            Codigo: 42320000,
            Descripcion_Español: "IMPLANTES ORTOPEDICOS QUIRURGICOS",
            Descripcion_Ingles: null
        },
        {
            id: 170,
            Codigo: 43190000,
            Descripcion_Español: "DISPOSITIVOS DE COMUNICACIONES Y ACCESORIOS",
            Descripcion_Ingles: null
        },
        {
            id: 171,
            Codigo: 43200000,
            Descripcion_Español: "COMPONENTES PARA TECNOLOGIA DE LA INFORMACION, DIFUSION O TELECOMUNICACIONES",
            Descripcion_Ingles: null
        },
        {
            id: 172,
            Codigo: 43210000,
            Descripcion_Español: "EQUIPO INFORMATICO Y ACCESORIOS",
            Descripcion_Ingles: null
        },
        {
            id: 173,
            Codigo: 43220000,
            Descripcion_Español: "EQUIPOS O PLATAFORMAS Y ACCESORIOS DE REDES MULTIMEDIA O DE VOZ Y DATOS",
            Descripcion_Ingles: null
        },
        {
            id: 174,
            Codigo: 43230000,
            Descripcion_Español: "SOFTWARE",
            Descripcion_Ingles: null
        },
        {
            id: 175,
            Codigo: 44100000,
            Descripcion_Español: "MAQUINARIA, SUMINISTROS Y ACCESORIOS DE OFICINA",
            Descripcion_Ingles: null
        },
        {
            id: 176,
            Codigo: 44110000,
            Descripcion_Español: "ACCESORIOS DE OFICINA Y ESCRITORIO",
            Descripcion_Ingles: null
        },
        {
            id: 177,
            Codigo: 44120000,
            Descripcion_Español: "SUMINISTROS DE OFICINA",
            Descripcion_Ingles: null
        },
        {
            id: 178,
            Codigo: 45100000,
            Descripcion_Español: "EQUIPO DE IMPRENTA Y PUBLICACION",
            Descripcion_Ingles: null
        },
        {
            id: 179,
            Codigo: 45110000,
            Descripcion_Español: "EQUIPOS DE AUDIO Y VIDEO PARA PRESENTACION Y COMPOSICION",
            Descripcion_Ingles: null
        },
        {
            id: 180,
            Codigo: 45120000,
            Descripcion_Español: "EQUIPO DE VIDEO, FILMACION O FOTOGRAFIA",
            Descripcion_Ingles: null
        },
        {
            id: 181,
            Codigo: 45130000,
            Descripcion_Español: "MEDIOS FOTOGRAFICOS Y DE GRABACION",
            Descripcion_Ingles: null
        },
        {
            id: 182,
            Codigo: 45140000,
            Descripcion_Español: "SUMINISTROS FOTOGRAFICOS PARA CINE",
            Descripcion_Ingles: null
        },
        {
            id: 183,
            Codigo: 46100000,
            Descripcion_Español: "ARMAS LIGERAS Y MUNICION",
            Descripcion_Ingles: null
        },
        {
            id: 184,
            Codigo: 46110000,
            Descripcion_Español: "ARMAS DE GUERRA CONVENCIONALES",
            Descripcion_Ingles: null
        },
        {
            id: 185,
            Codigo: 46120000,
            Descripcion_Español: "MISILES",
            Descripcion_Ingles: null
        },
        {
            id: 186,
            Codigo: 46130000,
            Descripcion_Español: "COHETES Y SUBSISTEMAS",
            Descripcion_Ingles: null
        },
        {
            id: 187,
            Codigo: 46140000,
            Descripcion_Español: "LANZADORES",
            Descripcion_Ingles: null
        },
        {
            id: 188,
            Codigo: 46160000,
            Descripcion_Español: "SEGURIDAD Y CONTROL PUBLICO",
            Descripcion_Ingles: null
        },
        {
            id: 189,
            Codigo: 46170000,
            Descripcion_Español: "SEGURIDAD, VIGILANCIA Y DETECCION",
            Descripcion_Ingles: null
        },
        {
            id: 190,
            Codigo: 46180000,
            Descripcion_Español: "SEGURIDAD Y PROTECCION PERSONAL",
            Descripcion_Ingles: null
        },
        {
            id: 191,
            Codigo: 46200000,
            Descripcion_Español: "EQUIPO DE ENTRENAMIENTO DE SEGURIDAD FISICA E INDUSTRIAL, DEFENSA Y ORDEN PUBLICO",
            Descripcion_Ingles: null
        },
        {
            id: 192,
            Codigo: 47100000,
            Descripcion_Español: "TRATAMIENTO, SUMINISTROS Y ELIMINACION DE AGUA Y AGUAS RESIDUALES",
            Descripcion_Ingles: null
        },
        {
            id: 193,
            Codigo: 47110000,
            Descripcion_Español: "EQUIPO INDUSTRIAL DE LAVANDERIA Y LAVADO EN SECO",
            Descripcion_Ingles: null
        },
        {
            id: 194,
            Codigo: 47120000,
            Descripcion_Español: "EQUIPO DE ASEO",
            Descripcion_Ingles: null
        },
        {
            id: 195,
            Codigo: 47130000,
            Descripcion_Español: "SUMINISTROS DE ASEO Y LIMPIEZA",
            Descripcion_Ingles: null
        },
        {
            id: 196,
            Codigo: 48100000,
            Descripcion_Español: "EQUIPOS DE SERVICIOS DE ALIMENTACION PARA INSTITUCIONES",
            Descripcion_Ingles: null
        },
        {
            id: 197,
            Codigo: 48110000,
            Descripcion_Español: "MAQUINAS EXPENDEDORAS",
            Descripcion_Ingles: null
        },
        {
            id: 198,
            Codigo: 48120000,
            Descripcion_Español: "EQUIPO DE JUEGO O DE APOSTAR",
            Descripcion_Ingles: null
        },
        {
            id: 199,
            Codigo: 48130000,
            Descripcion_Español: "EQUIPO Y MATERIALES FUNERARIOS",
            Descripcion_Ingles: null
        },
        {
            id: 200,
            Codigo: 49100000,
            Descripcion_Español: "COLECCIONABLES Y CONDECORACIONES",
            Descripcion_Ingles: null
        },
        {
            id: 201,
            Codigo: 49120000,
            Descripcion_Español: "EQUIPOS Y ACCESORIOS PARA ACAMPADA Y EXTERIORES",
            Descripcion_Ingles: null
        },
        {
            id: 202,
            Codigo: 49130000,
            Descripcion_Español: "EQUIPOS DE PESCA Y CAZA",
            Descripcion_Ingles: null
        },
        {
            id: 203,
            Codigo: 49140000,
            Descripcion_Español: "EQUIPOS PARA DEPORTES ACUATICOS",
            Descripcion_Ingles: null
        },
        {
            id: 204,
            Codigo: 49150000,
            Descripcion_Español: "EQUIPOS PARA DEPORTES DE INVIERNO",
            Descripcion_Ingles: null
        },
        {
            id: 205,
            Codigo: 49160000,
            Descripcion_Español: "EQUIPOS DEPORTIVOS PARA CAMPOS Y CANCHAS",
            Descripcion_Ingles: null
        },
        {
            id: 206,
            Codigo: 49170000,
            Descripcion_Español: "EQUIPOS DE GIMNASIA Y BOXEO",
            Descripcion_Ingles: null
        },
        {
            id: 207,
            Codigo: 49180000,
            Descripcion_Español: "JUEGOS, EQUIPO DE TIRO Y MESA",
            Descripcion_Ingles: null
        },
        {
            id: 208,
            Codigo: 49200000,
            Descripcion_Español: "EQUIPO PARA ENTRENAMIENTO FISICO",
            Descripcion_Ingles: null
        },
        {
            id: 209,
            Codigo: 49210000,
            Descripcion_Español: "OTROS DEPORTES",
            Descripcion_Ingles: null
        },
        {
            id: 210,
            Codigo: 49220000,
            Descripcion_Español: "EQUIPO  Y ACCESORIOS PARA DEPORTES",
            Descripcion_Ingles: null
        },
        {
            id: 211,
            Codigo: 49240000,
            Descripcion_Español: "EQUIPO DE RECREO, PARQUES INFANTILES Y EQUIPO Y SUMINISTROS DE NATACION Y DE SPA",
            Descripcion_Ingles: null
        },
        {
            id: 212,
            Codigo: 50110000,
            Descripcion_Español: "PRODUCTOS DE CARNE Y AVES DE CORRAL",
            Descripcion_Ingles: null
        },
        {
            id: 213,
            Codigo: 50120000,
            Descripcion_Español: "PESCADOS Y MARISCOS",
            Descripcion_Ingles: null
        },
        {
            id: 214,
            Codigo: 50130000,
            Descripcion_Español: "PRODUCTOS LACTEOS Y HUEVOS",
            Descripcion_Ingles: null
        },
        {
            id: 215,
            Codigo: 50150000,
            Descripcion_Español: "ACEITES Y GRASAS COMESTIBLES",
            Descripcion_Ingles: null
        },
        {
            id: 216,
            Codigo: 50160000,
            Descripcion_Español: "CHOCOLATES, AZUCARES, EDULCORANTES Y PRODUCTOS DE CONFITERIA",
            Descripcion_Ingles: null
        },
        {
            id: 217,
            Codigo: 50170000,
            Descripcion_Español: "CONDIMENTOS Y CONSERVANTES",
            Descripcion_Ingles: null
        },
        {
            id: 218,
            Codigo: 50180000,
            Descripcion_Español: "PRODUCTOS DE PANADERIA",
            Descripcion_Ingles: null
        },
        {
            id: 219,
            Codigo: 50190000,
            Descripcion_Español: "ALIMENTOS PREPARADOS Y CONSERVADOS",
            Descripcion_Ingles: null
        },
        {
            id: 220,
            Codigo: 50200000,
            Descripcion_Español: "BEBIDAS",
            Descripcion_Ingles: null
        },
        {
            id: 221,
            Codigo: 50210000,
            Descripcion_Español: "TABACO Y PRODUCTOS DE FUMAR Y SUBSTITUTOS",
            Descripcion_Ingles: null
        },
        {
            id: 222,
            Codigo: 50220000,
            Descripcion_Español: "PRODUCTOS DE CEREALES Y LEGUMBRES",
            Descripcion_Ingles: null
        },
        {
            id: 223,
            Codigo: 50340000,
            Descripcion_Español: "FRUTA CONGELADA",
            Descripcion_Ingles: null
        },
        {
            id: 224,
            Codigo: 51100000,
            Descripcion_Español: "MEDICAMENTOS ANTIINFECCIOSOS",
            Descripcion_Ingles: null
        },
        {
            id: 225,
            Codigo: 51110000,
            Descripcion_Español: "AGENTES ANTITUMORALES",
            Descripcion_Ingles: null
        },
        {
            id: 226,
            Codigo: 51120000,
            Descripcion_Español: "MEDICAMENTOS CARDIOVASCULARES",
            Descripcion_Ingles: null
        },
        {
            id: 227,
            Codigo: 51130000,
            Descripcion_Español: "MEDICAMENTOS HEMATOLOGOS",
            Descripcion_Ingles: null
        },
        {
            id: 228,
            Codigo: 51140000,
            Descripcion_Español: "MEDICAMENTOS PARA EL SISTEMA NERVIOSO CENTRAL",
            Descripcion_Ingles: null
        },
        {
            id: 229,
            Codigo: 51150000,
            Descripcion_Español: "MEDICAMENTOS PARA EL SISTEMA NERVIOSO AUTONOMO",
            Descripcion_Ingles: null
        },
        {
            id: 230,
            Codigo: 51160000,
            Descripcion_Español: "MEDICAMENTOS QUE AFECTAN AL SISTEMA RESPIRATORIO",
            Descripcion_Ingles: null
        },
        {
            id: 231,
            Codigo: 51170000,
            Descripcion_Español: "MEDICAMENTOS QUE AFECTAN AL SISTEMA GASTROINTESTINAL",
            Descripcion_Ingles: null
        },
        {
            id: 232,
            Codigo: 51180000,
            Descripcion_Español: "HORMONAS Y ANTAGONISTAS HORMONALES",
            Descripcion_Ingles: null
        },
        {
            id: 233,
            Codigo: 51190000,
            Descripcion_Español: "AGENTES QUE AFECTAN EL AGUA Y LOS ELECTROLITOS",
            Descripcion_Ingles: null
        },
        {
            id: 234,
            Codigo: 51200000,
            Descripcion_Español: "MEDICAMENTOS INMUNOMODULADORES",
            Descripcion_Ingles: null
        },
        {
            id: 235,
            Codigo: 51210000,
            Descripcion_Español: "CATEGORIAS DE MEDICAMENTOS VARIOS",
            Descripcion_Ingles: null
        },
        {
            id: 236,
            Codigo: 51240000,
            Descripcion_Español: "FARMACOS QUE AFECTAN A LOS OIDOS, LOS OJOS, LA NARIZ Y LA PIEL",
            Descripcion_Ingles: null
        },
        {
            id: 237,
            Codigo: 51250000,
            Descripcion_Español: "SUPLEMENTOS ALIMENTICIOS VETERINARIOS",
            Descripcion_Ingles: null
        },
        {
            id: 238,
            Codigo: 52100000,
            Descripcion_Español: "REVESTIMIENTOS DE SUELOS",
            Descripcion_Ingles: null
        },
        {
            id: 239,
            Codigo: 52120000,
            Descripcion_Español: "ROPA DE CAMA, MANTELERIAS, PAÑOS DE COCINA Y TOALLAS",
            Descripcion_Ingles: null
        },
        {
            id: 240,
            Codigo: 52130000,
            Descripcion_Español: "TRATAMIENTOS DE VENTANAS",
            Descripcion_Ingles: null
        },
        {
            id: 241,
            Codigo: 52140000,
            Descripcion_Español: "APARATOS ELECTRODOMESTICOS",
            Descripcion_Ingles: null
        },
        {
            id: 242,
            Codigo: 52150000,
            Descripcion_Español: "UTENSILIOS DE COCINA DOMESTICOS",
            Descripcion_Ingles: null
        },
        {
            id: 243,
            Codigo: 52160000,
            Descripcion_Español: "ELECTRONICA DE CONSUMO",
            Descripcion_Ingles: null
        },
        {
            id: 244,
            Codigo: 52170000,
            Descripcion_Español: "TRATAMIENTOS DE PARED DOMESTICA",
            Descripcion_Ingles: null
        },
        {
            id: 245,
            Codigo: 53100000,
            Descripcion_Español: "ROPA",
            Descripcion_Ingles: null
        },
        {
            id: 246,
            Codigo: 53110000,
            Descripcion_Español: "CALZADO",
            Descripcion_Ingles: null
        },
        {
            id: 247,
            Codigo: 53120000,
            Descripcion_Español: "MALETAS, BOLSOS DE MANO, MOCHILAS Y ESTUCHES",
            Descripcion_Ingles: null
        },
        {
            id: 248,
            Codigo: 53130000,
            Descripcion_Español: "ARTICULOS DE TOCADOR Y CUIDADO PERSONAL",
            Descripcion_Ingles: null
        },
        {
            id: 249,
            Codigo: 53140000,
            Descripcion_Español: "FUENTES Y ACCESORIOS DE COSTURA",
            Descripcion_Ingles: null
        },
        {
            id: 250,
            Codigo: 54100000,
            Descripcion_Español: "JOYERIA",
            Descripcion_Ingles: null
        },
        {
            id: 251,
            Codigo: 54110000,
            Descripcion_Español: "RELOJES",
            Descripcion_Ingles: null
        },
        {
            id: 252,
            Codigo: 55100000,
            Descripcion_Español: "MEDIOS IMPRESOS",
            Descripcion_Ingles: null
        },
        {
            id: 253,
            Codigo: 55110000,
            Descripcion_Español: "MATERIAL ELECTRONICO DE REFERENCIA",
            Descripcion_Ingles: null
        },
        {
            id: 254,
            Codigo: 55120000,
            Descripcion_Español: "ETIQUETADO Y ACCESORIOS",
            Descripcion_Ingles: null
        },
        {
            id: 255,
            Codigo: 56100000,
            Descripcion_Español: "MUEBLES DE ALOJAMIENTO",
            Descripcion_Ingles: null
        },
        {
            id: 256,
            Codigo: 56110000,
            Descripcion_Español: "MUEBLES COMERCIALES E INDUSTRIALES",
            Descripcion_Ingles: null
        },
        {
            id: 257,
            Codigo: 56120000,
            Descripcion_Español: "MOBILIARIO INSTITUCIONAL, ESCOLAR Y EDUCATIVO Y ACCESORIOS",
            Descripcion_Ingles: null
        },
        {
            id: 258,
            Codigo: 56130000,
            Descripcion_Español: "MUEBLES Y ACCESORIOS PARA MERCHANDISING",
            Descripcion_Ingles: null
        },
        {
            id: 259,
            Codigo: 56140000,
            Descripcion_Español: "ADORNOS PARA EL HOGAR",
            Descripcion_Ingles: null
        },
        {
            id: 260,
            Codigo: 60100000,
            Descripcion_Español: "MATERIALES DIDACTICOS PROFESIONALES Y DE DESARROLLO Y ACCESORIOS Y SUMINISTROS",
            Descripcion_Ingles: null
        },
        {
            id: 261,
            Codigo: 60120000,
            Descripcion_Español: "EQUIPO, ACCESORIOS Y SUMINISTROS DE ARTE Y MANUALIDADES",
            Descripcion_Ingles: null
        },
        {
            id: 262,
            Codigo: 60130000,
            Descripcion_Español: "INSTRUMENTOS MUSICALES, PIEZAS Y ACCESORIOS",
            Descripcion_Ingles: null
        },
        {
            id: 263,
            Codigo: 60140000,
            Descripcion_Español: "JUGUETES Y JUEGOS",
            Descripcion_Ingles: null
        },
        {
            id: 264,
            Codigo: 70100000,
            Descripcion_Español: "PESQUERIAS Y ACUICULTURA",
            Descripcion_Ingles: null
        },
        {
            id: 265,
            Codigo: 70110000,
            Descripcion_Español: "HORTICULTURA",
            Descripcion_Ingles: null
        },
        {
            id: 266,
            Codigo: 70120000,
            Descripcion_Español: "SERVICIOS DE ANIMALES VIVOS",
            Descripcion_Ingles: null
        },
        {
            id: 267,
            Codigo: 70130000,
            Descripcion_Español: "PREPARACION, GESTION Y PROTECCION DEL TERRENO Y DEL SUELO",
            Descripcion_Ingles: null
        },
        {
            id: 268,
            Codigo: 70140000,
            Descripcion_Español: "PRODUCCION, GESTION Y PROTECCION DE CULTIVOS",
            Descripcion_Ingles: null
        },
        {
            id: 269,
            Codigo: 70160000,
            Descripcion_Español: "FAUNA Y FLORA SILVESTRES",
            Descripcion_Ingles: null
        },
        {
            id: 270,
            Codigo: 70170000,
            Descripcion_Español: "DESARROLLO Y VIGILANCIA DE RECURSOS HIDRAULICOS",
            Descripcion_Ingles: null
        },
        {
            id: 271,
            Codigo: 71100000,
            Descripcion_Español: "SERVICIOS DE MINERIA",
            Descripcion_Ingles: null
        },
        {
            id: 272,
            Codigo: 71110000,
            Descripcion_Español: "SERVICIOS DE PERFORACION Y PROSPECCION PETROLIFERA Y DE GAS",
            Descripcion_Ingles: null
        },
        {
            id: 273,
            Codigo: 71120000,
            Descripcion_Español: "SERVICIOS DE CONSTRUCCION Y PERFORACION DE POZOS",
            Descripcion_Ingles: null
        },
        {
            id: 274,
            Codigo: 71130000,
            Descripcion_Español: "SERVICIOS DE AUMENTO DE LA EXTRACCION Y PRODUCCION DE GAS Y PETROLEO",
            Descripcion_Ingles: null
        },
        {
            id: 275,
            Codigo: 71140000,
            Descripcion_Español: "SERVICIOS DE RESTAURACION Y RECUPERACION DE GAS Y PETROLEO",
            Descripcion_Ingles: null
        },
        {
            id: 276,
            Codigo: 71150000,
            Descripcion_Español: "SERVICIOS DE PROCESAMIENTO Y GESTION DE DATOS DE PETROLEO Y GAS",
            Descripcion_Ingles: null
        },
        {
            id: 277,
            Codigo: 71160000,
            Descripcion_Español: "SERVICIOS DE GERENCIA DE PROYECTOS EN POZOS DE PETROLEO Y GAS",
            Descripcion_Ingles: null
        },
        {
            id: 278,
            Codigo: 72100000,
            Descripcion_Español: "SERVICIOS DE MANTENIMIENTO Y REPARACIONES DE CONSTRUCCIONES E INSTALACIONES",
            Descripcion_Ingles: null
        },
        {
            id: 279,
            Codigo: 72110000,
            Descripcion_Español: "SERVICIOS DE CONSTRUCCION DE EDIFICACIONES RESIDENCIALES",
            Descripcion_Ingles: null
        },
        {
            id: 280,
            Codigo: 72120000,
            Descripcion_Español: "SERVICIOS DE CONSTRUCCION DE EDIFICACIONES NO RESIDENCIALES",
            Descripcion_Ingles: null
        },
        {
            id: 281,
            Codigo: 72140000,
            Descripcion_Español: "SERVICIOS DE CONSTRUCCION PESADA",
            Descripcion_Ingles: null
        },
        {
            id: 282,
            Codigo: 72150000,
            Descripcion_Español: "SERVICIOS DE MANTENIMIENTO Y CONSTRUCCION DE COMERCIO ESPECIALIZADO",
            Descripcion_Ingles: null
        },
        {
            id: 283,
            Codigo: 73100000,
            Descripcion_Español: "INDUSTRIAS DE PLASTICOS Y PRODUCTOS QUIMICOS",
            Descripcion_Ingles: null
        },
        {
            id: 284,
            Codigo: 73110000,
            Descripcion_Español: "INDUSTRIAS DE LA MADERA Y EL PAPEL",
            Descripcion_Ingles: null
        },
        {
            id: 285,
            Codigo: 73120000,
            Descripcion_Español: "INDUSTRIAS DEL METAL Y DE MINERALES",
            Descripcion_Ingles: null
        },
        {
            id: 286,
            Codigo: 73130000,
            Descripcion_Español: "INDUSTRIAS DE ALIMENTOS Y BEBIDAS",
            Descripcion_Ingles: null
        },
        {
            id: 287,
            Codigo: 73140000,
            Descripcion_Español: "INDUSTRIAS DE FIBRAS, TEXTILES Y DE TEJIDOS",
            Descripcion_Ingles: null
        },
        {
            id: 288,
            Codigo: 73150000,
            Descripcion_Español: "SERVICIOS DE APOYO A LA FABRICACION",
            Descripcion_Ingles: null
        },
        {
            id: 289,
            Codigo: 73160000,
            Descripcion_Español: "FABRICACION DE MAQUINARIA Y EQUIPO DE TRANSPORTE",
            Descripcion_Ingles: null
        },
        {
            id: 290,
            Codigo: 73170000,
            Descripcion_Español: "FABRICACION DE PRODUCTOS ELECTRICOS E INSTRUMENTOS DE PRECISION",
            Descripcion_Ingles: null
        },
        {
            id: 291,
            Codigo: 73180000,
            Descripcion_Español: "SERVICIOS DE MAQUINADO Y PROCESADO",
            Descripcion_Ingles: null
        },
        {
            id: 292,
            Codigo: 76100000,
            Descripcion_Español: "SERVICIOS DE DESCONTAMINACION",
            Descripcion_Ingles: null
        },
        {
            id: 293,
            Codigo: 76110000,
            Descripcion_Español: "SERVICIOS DE ASEO Y LIMPIEZA",
            Descripcion_Ingles: null
        },
        {
            id: 294,
            Codigo: 76120000,
            Descripcion_Español: "ELIMINACION Y TRATAMIENTO DE DESECHOS",
            Descripcion_Ingles: null
        },
        {
            id: 295,
            Codigo: 76130000,
            Descripcion_Español: "LIMPIEZA DE RESIDUOS TOXICOS Y PELIGROSOS",
            Descripcion_Ingles: null
        },
        {
            id: 296,
            Codigo: 77100000,
            Descripcion_Español: "GESTION MEDIOAMBIENTAL",
            Descripcion_Ingles: null
        },
        {
            id: 297,
            Codigo: 77120000,
            Descripcion_Español: "SEGUIMIENTO, CONTROL Y REHABILITACION DE LA CONTAMINACION",
            Descripcion_Ingles: null
        },
        {
            id: 298,
            Codigo: 77130000,
            Descripcion_Español: "SERVICIOS DE SEGUIMIENTO, CONTROL O REHABILITACION DE CONTAMINANTES",
            Descripcion_Ingles: null
        },
        {
            id: 299,
            Codigo: 78100000,
            Descripcion_Español: "TRANSPORTE DE CORREO Y CARGA",
            Descripcion_Ingles: null
        },
        {
            id: 300,
            Codigo: 78110000,
            Descripcion_Español: "TRANSPORTE DE PASAJEROS",
            Descripcion_Ingles: null
        },
        {
            id: 301,
            Codigo: 78120000,
            Descripcion_Español: "MANEJO Y EMBALAJE DE MATERIAL",
            Descripcion_Ingles: null
        },
        {
            id: 302,
            Codigo: 78130000,
            Descripcion_Español: "ALMACENAJE",
            Descripcion_Ingles: null
        },
        {
            id: 303,
            Codigo: 78140000,
            Descripcion_Español: "SERVICIOS DE TRANSPORTE",
            Descripcion_Ingles: null
        },
        {
            id: 304,
            Codigo: 78180000,
            Descripcion_Español: "SERVICIOS DE MANTENIMIENTO O REPARACIONES DE TRANSPORTES",
            Descripcion_Ingles: null
        },
        {
            id: 305,
            Codigo: 80100000,
            Descripcion_Español: "SERVICIOS DE ASESORIA DE GESTION",
            Descripcion_Ingles: null
        },
        {
            id: 306,
            Codigo: 80110000,
            Descripcion_Español: "SERVICIOS DE RECURSOS HUMANOS",
            Descripcion_Ingles: null
        },
        {
            id: 307,
            Codigo: 80120000,
            Descripcion_Español: "SERVICIOS LEGALES",
            Descripcion_Ingles: null
        },
        {
            id: 308,
            Codigo: 80130000,
            Descripcion_Español: "SERVICIOS INMOBILIARIOS",
            Descripcion_Ingles: null
        },
        {
            id: 309,
            Codigo: 80140000,
            Descripcion_Español: "COMERCIALIZACION Y DISTRIBUCION",
            Descripcion_Ingles: null
        },
        {
            id: 310,
            Codigo: 80150000,
            Descripcion_Español: "POLITICA COMERCIAL Y SERVICIOS",
            Descripcion_Ingles: null
        },
        {
            id: 311,
            Codigo: 80160000,
            Descripcion_Español: "SERVICIOS DE ADMINISTRACION DE EMPRESAS",
            Descripcion_Ingles: null
        },
        {
            id: 312,
            Codigo: 81100000,
            Descripcion_Español: "SERVICIOS PROFESIONALES DE INGENIERIA",
            Descripcion_Ingles: null
        },
        {
            id: 313,
            Codigo: 81110000,
            Descripcion_Español: "SERVICIOS INFORMATICOS",
            Descripcion_Ingles: null
        },
        {
            id: 314,
            Codigo: 81120000,
            Descripcion_Español: "ECONOMIA",
            Descripcion_Ingles: null
        },
        {
            id: 315,
            Codigo: 81130000,
            Descripcion_Español: "ESTADISTICA",
            Descripcion_Ingles: null
        },
        {
            id: 316,
            Codigo: 81140000,
            Descripcion_Español: "TECNOLOGIAS DE FABRICACION",
            Descripcion_Ingles: null
        },
        {
            id: 317,
            Codigo: 81150000,
            Descripcion_Español: "SERVICIOS DE PEDOLOGIA",
            Descripcion_Ingles: null
        },
        {
            id: 318,
            Codigo: 81160000,
            Descripcion_Español: "ENTREGA DE SERVICIOS DE TECNOLOGIA DE INFORMACION",
            Descripcion_Ingles: null
        },
        {
            id: 319,
            Codigo: 82100000,
            Descripcion_Español: "PUBLICIDAD",
            Descripcion_Ingles: null
        },
        {
            id: 320,
            Codigo: 82110000,
            Descripcion_Español: "ESCRITURA Y TRADUCCIONES",
            Descripcion_Ingles: null
        },
        {
            id: 321,
            Codigo: 82120000,
            Descripcion_Español: "SERVICIOS DE REPRODUCCION",
            Descripcion_Ingles: null
        },
        {
            id: 322,
            Codigo: 82130000,
            Descripcion_Español: "SERVICIOS FOTOGRAFICOS",
            Descripcion_Ingles: null
        },
        {
            id: 323,
            Codigo: 82140000,
            Descripcion_Español: "DISEÑO GRAFICO",
            Descripcion_Ingles: null
        },
        {
            id: 324,
            Codigo: 82150000,
            Descripcion_Español: "ARTISTAS E INTERPRETES PROFESIONALES",
            Descripcion_Ingles: null
        },
        {
            id: 325,
            Codigo: 83100000,
            Descripcion_Español: "SERVICIOS PUBLICOS",
            Descripcion_Ingles: null
        },
        {
            id: 326,
            Codigo: 83110000,
            Descripcion_Español: "SERVICIOS DE MEDIOS DE TELECOMUNICACIONES",
            Descripcion_Ingles: null
        },
        {
            id: 327,
            Codigo: 83120000,
            Descripcion_Español: "SERVICIOS DE INFORMACION",
            Descripcion_Ingles: null
        },
        {
            id: 328,
            Codigo: 84100000,
            Descripcion_Español: "FINANZAS DE DESARROLLO",
            Descripcion_Ingles: null
        },
        {
            id: 329,
            Codigo: 84110000,
            Descripcion_Español: "SERVICIOS DE CONTABILIDAD Y AUDITORIAS",
            Descripcion_Ingles: null
        },
        {
            id: 330,
            Codigo: 84120000,
            Descripcion_Español: "BANCA E INVERSIONES",
            Descripcion_Ingles: null
        },
        {
            id: 331,
            Codigo: 84130000,
            Descripcion_Español: "SERVICIOS DE SEGUROS Y PENSIONES",
            Descripcion_Ingles: null
        },
        {
            id: 332,
            Codigo: 84140000,
            Descripcion_Español: "AGENCIAS DE CREDITO",
            Descripcion_Ingles: null
        },
        {
            id: 333,
            Codigo: 85100000,
            Descripcion_Español: "SERVICIOS INTEGRALES DE SALUD",
            Descripcion_Ingles: null
        },
        {
            id: 334,
            Codigo: 85110000,
            Descripcion_Español: "PREVENCION Y CONTROL DE ENFERMEDADES",
            Descripcion_Ingles: null
        },
        {
            id: 335,
            Codigo: 85120000,
            Descripcion_Español: "PRACTICA MEDICA",
            Descripcion_Ingles: null
        },
        {
            id: 336,
            Codigo: 85130000,
            Descripcion_Español: "CIENCIA MEDICA, INVESTIGACION Y EXPERIMENTACION",
            Descripcion_Ingles: null
        },
        {
            id: 337,
            Codigo: 85140000,
            Descripcion_Español: "MEDICINA ALTERNATIVA Y HOLISTICA",
            Descripcion_Ingles: null
        },
        {
            id: 338,
            Codigo: 85150000,
            Descripcion_Español: "SERVICIOS ALIMENTICIOS Y DE NUTRICION",
            Descripcion_Ingles: null
        },
        {
            id: 339,
            Codigo: 85160000,
            Descripcion_Español: "SERVICIOS DE MANTENIMIENTO, RENOVACION Y REPARACION DE EQUIPO MEDICO QUIRURGICO",
            Descripcion_Ingles: null
        },
        {
            id: 340,
            Codigo: 85170000,
            Descripcion_Español: "SERVICIOS DE MUERTE Y SOPORTE AL FALLECIMIENTO",
            Descripcion_Ingles: null
        },
        {
            id: 341,
            Codigo: 86100000,
            Descripcion_Español: "FORMACION PROFESIONAL",
            Descripcion_Ingles: null
        },
        {
            id: 342,
            Codigo: 86110000,
            Descripcion_Español: "SISTEMAS EDUCATIVOS ALTERNATIVOS",
            Descripcion_Ingles: null
        },
        {
            id: 343,
            Codigo: 86120000,
            Descripcion_Español: "INSTITUCIONES EDUCATIVAS",
            Descripcion_Ingles: null
        },
        {
            id: 344,
            Codigo: 86130000,
            Descripcion_Español: "SERVICIOS EDUCATIVOS ESPECIALIZADOS",
            Descripcion_Ingles: null
        },
        {
            id: 345,
            Codigo: 86140000,
            Descripcion_Español: "INSTALACIONES EDUCATIVAS",
            Descripcion_Ingles: null
        },
        {
            id: 346,
            Codigo: 90100000,
            Descripcion_Español: "RESTAURANTES Y CATERING (SERVICIOS DE COMIDAS Y BEBIDAS)",
            Descripcion_Ingles: null
        },
        {
            id: 347,
            Codigo: 90110000,
            Descripcion_Español: "INSTALACIONES HOTELERAS, ALOJAMIENTOS Y CENTROS DE ENCUENTROS",
            Descripcion_Ingles: null
        },
        {
            id: 348,
            Codigo: 90120000,
            Descripcion_Español: "FACILITACION DE VIAJES",
            Descripcion_Ingles: null
        },
        {
            id: 349,
            Codigo: 90130000,
            Descripcion_Español: "ARTES INTERPRETATIVAS",
            Descripcion_Ingles: null
        },
        {
            id: 350,
            Codigo: 90150000,
            Descripcion_Español: "SERVICIOS DE ENTRETENIMIENTO",
            Descripcion_Ingles: null
        },
        {
            id: 351,
            Codigo: 91100000,
            Descripcion_Español: "ASPECTO PERSONAL",
            Descripcion_Ingles: null
        },
        {
            id: 352,
            Codigo: 91110000,
            Descripcion_Español: "ASISTENCIA DOMESTICA Y PERSONAL",
            Descripcion_Ingles: null
        },
        {
            id: 353,
            Codigo: 92100000,
            Descripcion_Español: "ORDEN PUBLICO Y SEGURIDAD",
            Descripcion_Ingles: null
        },
        {
            id: 354,
            Codigo: 92110000,
            Descripcion_Español: "SERVICIOS MILITARES O DEFENSA NACIONAL",
            Descripcion_Ingles: null
        },
        {
            id: 355,
            Codigo: 92120000,
            Descripcion_Español: "SEGURIDAD Y PROTECCION PERSONAL",
            Descripcion_Ingles: null
        },
        {
            id: 356,
            Codigo: 93100000,
            Descripcion_Español: "SISTEMAS E INSTITUCIONES POLITICAS",
            Descripcion_Ingles: null
        },
        {
            id: 357,
            Codigo: 93110000,
            Descripcion_Español: "CONDICIONES SOCIOPOLITICAS",
            Descripcion_Ingles: null
        },
        {
            id: 358,
            Codigo: 93120000,
            Descripcion_Español: "RELACIONES INTERNACIONALES",
            Descripcion_Ingles: null
        },
        {
            id: 359,
            Codigo: 93130000,
            Descripcion_Español: "AYUDA Y ASISTENCIA HUMANITARIA",
            Descripcion_Ingles: null
        },
        {
            id: 360,
            Codigo: 93140000,
            Descripcion_Español: "SERVICIOS COMUNITARIOS Y SOCIALES",
            Descripcion_Ingles: null
        },
        {
            id: 361,
            Codigo: 93150000,
            Descripcion_Español: "SERVICIOS DE ADMINISTRACION Y FINANCIACION PUBLICA",
            Descripcion_Ingles: null
        },
        {
            id: 362,
            Codigo: 93160000,
            Descripcion_Español: "TRIBUTACION",
            Descripcion_Ingles: null
        },
        {
            id: 363,
            Codigo: 93170000,
            Descripcion_Español: "POLITICA Y REGULACION COMERCIAL",
            Descripcion_Ingles: null
        },
        {
            id: 364,
            Codigo: 94120000,
            Descripcion_Español: "CLUBES",
            Descripcion_Ingles: null
        },
        {
            id: 365,
            Codigo: 95100000,
            Descripcion_Español: "PARCELAS DE TIERRA",
            Descripcion_Ingles: null
        },
        {
            id: 366,
            Codigo: 95110000,
            Descripcion_Español: "VIAS",
            Descripcion_Ingles: null
        },
        {
            id: 367,
            Codigo: 95120000,
            Descripcion_Español: "ESTRUCTURAS Y EDIFICIOS PERMANENTES",
            Descripcion_Ingles: null
        },
        {
            id: 368,
            Codigo: 95130000,
            Descripcion_Español: "ESTRUCTURAS Y EDIFICIOS MOVILES",
            Descripcion_Ingles: null
        },
        {
            id: 369,
            Codigo: 95140000,
            Descripcion_Español: "ESTRUCTURAS Y EDIFICIOS PREFABRICADOS",
            Descripcion_Ingles: null
        }
  ]
    
export const  employeeStatus= [
    {
      imagepath: u2,
      title: "John Deo",
      online: "14+ online",
      position: "Designer",
      skill: "30%",
      exp: "2 year",
      classname: "primary"
    },
    {
      imagepath: u3,
      title: "Holio Mako",
      online: "250+ online",
      position: "Developer",
      skill: "70%",
      exp: "3 year",
      classname: "secondary"
    },
    {
      imagepath: u4,
      title: "Mohsib lara",
      online: "99+ online",
      position: "tester",
      skill: "60%",
      exp: "5 month",
      classname: "primary"
    },
    {
      imagepath: u5,
      title: "Hileri Soli",
      online: "150+ online",
      position: "Designer",
      skill: "25%",
      exp: "3 month",
      classname: "secondary"
    },
    {
      imagepath: u6,
      title: "Pusiz bia",
      online: "19+ online",
      position: "Developer",
      skill: "90%",
      exp: "5 year",
      classname: "primary"
    }
  ]