import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Button
} from "reactstrap";
import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';
import  axios from 'axios';
import SweetAlert from 'sweetalert2'
 
// const stripe =  loadStripe('pk_test_51KjKIpD9HgsUwBcnewdBsCZ681PI9i4MOgmBhNt5CC4CKYdhVD7uY53wfl8Brtghjs6qxnnta5hIRRq0pp8LQ7Wg00RwxuBIT5');

const stripePromise = loadStripe('pk_test_51KjKIpD9HgsUwBcnewdBsCZ681PI9i4MOgmBhNt5CC4CKYdhVD7uY53wfl8Brtghjs6qxnnta5hIRRq0pp8LQ7Wg00RwxuBIT5');

const Pago2 = props => {


  const CheckoutForm =  () => {

    const stripe = useStripe();
    const elements = useElements();
    
    const handleSubmit = async (e) => {
      e.preventDefault();

    const {error, paymentMethod} = await  stripe.createPaymentMethod({

        type: 'card',
        card: elements.getElement(CardElement)

      })


      if(!error) {
         const {id} = paymentMethod;

         const {data} = await axios.post('https://f0c4845c2c9c.ngrok.io/check', {
           id,
           amount: 50000,
         }).then((response) => {
          if (response.data == "recibido"){
            SweetAlert.fire({title:"Pago Exitoso", text:"Bienvenido a los beneficios PREMIUM", icon:"success"}); 
          }
        })  ;
         

      }

    }

    return (
      <form onSubmit={handleSubmit}>
         <CardElement />   
         <CardBody>
                <Row className="theme-form" form>
                  <FormGroup className="col-6 p-r-0">
 
                  </FormGroup>
                  <FormGroup className="col-12">
                    <ul className="payment-opt">
                      <li>
                        <img
                          src={require("../../assets/images/ecommerce/mastercard.png")}
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/ecommerce/visa.png")}
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/ecommerce/paypal.png")}
                          alt=""
                        />
                      </li>
                    </ul>
                  </FormGroup>
                  
                </Row>
              </CardBody>
                  <div className="col-12">
                    <Button color="primary-gradien" className="btn-block">
                      {"pagar"}
                    </Button>
                  </div>
      </form>
    );
  };

  
  const options = {
    // passing the client secret obtained from the server
    clientSecret: '{{CLIENT_SECRET}}',
  };



  return (
    <div className="page-wrapper">
      <Container fluid={true}>
        <div className="authentication-main mt-0">
          <Row>
            <Col md="12" className="p-0">
              <Card>

              </Card>
              <Col xl="4" lg="6" className="box-col-6" style={{ position: "relative", left: "485.01px"}}>
        <Card>
        <font color="#FFFFFF">                                           
                            <h2>{'|'}</h2>
                                </font>
                <div className="authentication-box auth-minibox1">
                  <div className="text-center"><img src={require("../../assets/images/other-images/creative-logo1.png")} alt=""/></div>
                  </div>
                  <h5 style={{position: "relative", left: "104.995px"}}>{"Ingresa tus datos de pago"}</h5>
                  <font color="#FFFFFF">                                           
                            <h2>{'|'}</h2>
                                </font>
                  <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
              
            </Card>
       </Col>
        

                
                <div>
                
                      </div>
                    
            </Col>
          </Row>
        </div>
      </Container>
    </div>
    
  );
};

export default Pago2;
