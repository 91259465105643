import React, {useState,Fragment,useEffect} from 'react'
import BreadCrumb from '../../layout/Breadcrumb'
import { Container, Row, Col, Card,CardHeader, CardBody, Media,Button, ButtonModal, ModalHeader, ModalBody, ModalFooter, Modal , FormGroup,Input} from 'reactstrap'
import Tour from 'reactour'
import {disableBodyScroll,enableBodyScroll} from 'body-scroll-lock'
import blog from "../../assets/images/blog/Bienvenido.jpeg";
import {useSelector} from 'react-redux'
import { sassSmallChartOptions, sassSmallChartListener, sassUserChart, sassUserChartOptions, sassUserChartListener } from '../dashboard/chartsData/chartist'
import ChartistGraph from 'react-chartist';
import {sassSmallChart1, sassSmallChart2, sassSmallChart3, sassSmallChart4, sassSmallChart5 } from '../dashboard/chartsData/chartist'
import { toast } from 'react-toastify';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import { Search} from 'react-feather';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CategoryUnspc from '../widgets/CategoryUnspc'
const sas = [
  { id: 1,title:"Proveedores",total:"2,000",class:"primary",chartData:sassSmallChart1},
  { id: 2,title:"Compradores",total:"25",class:"secondary",chartData:sassSmallChart2},
  { id: 3,title:"RFQS creados",total:"1",class:"info",chartData:sassSmallChart3},
  // { id: 4,title:"Total Revenue",total:"45,01,046",monthly:"40,00,00",weekly:"215",class:"warning",chartData:sassSmallChart4},
  // { id: 5,title:"Total Shipment",total:"50,000",monthly:"40,000",weekly:"215",class:"success",chartData:sassSmallChart5}
]
const steps = [
  {
      selector: '.step1',
      content: 'Este es el perfil de usuario',
  },
  {
      selector: '.step2',
      content: 'Este es el menu principal',
  },
  {
    selector: '.step3',
    content: 'Abre el menu y selecciona una opción, podrás encontrar un tutorial para cada una de las secciones',
}
]

const Bienvenida = () => {



  const [modalb, setModalb] = useState(false);
    
  const toggleb = () => {
    setModalb(!modalb)
    localStorage.setItem("isUser",null)
  }

useEffect(() => {
    
const LoadingMsg = () => (
  <div>
   <i className="fa fa-bell-o" ></i><strong className="ml-2">{"Cargando"}</strong>    {" estadisticas y servicios"}
  </div>
)
// if(localStorage.getItem("isUser") === "true"){
//   setModalb(true)
//   toast(<LoadingMsg />, {
//     position: toast.POSITION.TOP_RIGHT,
//     autoClose: 3000,
//     });
// }else{
//   setModalb(false)
// }

}, []);







  const currentUser= useSelector(state => state.Common.currentUser)
  const [opentour,setopentour] = useState(false)
    const closeTour = () => {
        setopentour(false);
      };

      const tuto = () =>{
        setTimeout(() => {
          setopentour(true);
        }, 350);
      }

      const disableBody = target => disableBodyScroll(target);
      const enableBody = target => enableBodyScroll(target);

      
      const cotizaaqui = () => {
        window.location.href = (`${process.env.PUBLIC_URL}/#/widgets/crearRFQ`)

      }
      
      const vende = () => {
        window.location.href = (`${process.env.PUBLIC_URL}/#/widgets/RFQSList`)

      }



      useEffect(() => {
        if(currentUser.role == "Proveedor"){
document.getElementById('comprador').style.display = 'none';
        }if(currentUser.role == "Comprador"){
          document.getElementById('proveedor').style.display = 'none';
        }
    }, );
  

    useEffect(() => {
      if(currentUser.role == "Proveedor"){
document.getElementById('comprador').style.display = 'none';
      }if(currentUser.role == "Comprador"){
        document.getElementById('proveedor').style.display = 'none';
      }
  }, );
      


    const [modal, setmodal] = useState(false);
    const [modal2, setmodal2] = useState(false);
    const [modal3, setmodal3] = useState(false);
    const [modal4, setmodal4] = useState(false);
    const [modal5, setmodal5] = useState(false);
    const id = currentUser.id;
    const [nombreus, setnombreus] = useState ("");
    const [ape, setape] = useState ("");
    const [num, setnum] = useState ("");
    const [emp, setemp] = useState ("");
    const [catup, setcatup] = useState ([]);

   
   

    const toggle = () => {
      setmodal(false)
    }
    const toggle2 = () => {
      setmodal2(false)
    }
    const toggle3 = () => {
      setmodal3(false)
      setcatup([])
      
      localStorage.removeItem('selectedc')
    }
    const toggle4 = () => {
      setmodal4(false)
    }
    const toggle5 = () => {
      setmodal5(false)
      setcatup([])
      
      localStorage.removeItem('selectedc')
    }
       
    const edcat =() => {
      setmodal2(true)

      setTimeout(() => {
        setmodal2(false)
        setmodal3(true)
      }, 900);
    }

    const [options,setOptions] = useState("")
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [autocompleteValues, setAutocompleteValues] = useState([]);

  
  

   

    const buscar = () => {
      
      const cat = JSON.parse(localStorage.getItem('selectedc'))
     
      if(cat == null) {
        SweetAlert.fire({title:"No has seleccionado ninguna categoria", text:"Escribe en el campo para buscar una categoria ", icon:"error"});
      }else {
        
        cat.map(data =>{
          const opcion = data.code + " - " + data.esp
          setcatup(opciones=>[...opciones, opcion])  
          
        })
    
        catup.forEach(function(elemento, indice, array) {   
          
        })
       
       
     

      setmodal2(true)
      setTimeout(() => {
        
        setmodal2(false)
        setmodal5(true)
      }, 1800);
    }
    }

  

    const Guardarcat = () => {
     
      const todo = {
        "id":currentUser.id,
       "Nombre":currentUser.Nombre,
       "Apellido":currentUser.Apellido,
       "Empresa":currentUser.Empresa,
       "Telefono":currentUser.Telefono,
       "Categoria":catup.join(),
       "role":currentUser.role,
       "Correo":currentUser.Correo,
       "Contraseña":currentUser.Contraseña,
     }
      setmodal3(false)
      setmodal5(false)
      toast.error("Se recargara la pagina en breve");

     setTimeout(() => {
      axios.put("https://70ae6ed1cdbe.ngrok.io/gbn/gc2", {
         Categoria:catup.join(),
         id:currentUser.id,
        }).then(res => {
          setmodal(false)
          SweetAlert.fire({title:"Completado", text:"Se han efectuado los cambios exitosamente", icon:"success"}); 
          sessionStorage.setItem('currentuser', JSON.stringify(todo));
        })
  }, 500);

       setTimeout(() => {
        
        window.location.reload();
      }, 3000);

      
    }





    return (
      <Fragment>
        <Container fluid={true}>
       <Card>
         </Card>
       </Container>
        <Tour
          steps={steps}
          rounded={5}
          isOpen={opentour}
          disableInteraction={true}
          disableKeyboardNavigation={false}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
           />
            <Container fluid={true}>
                <Row>
                  <Col xl="12" className="xl-100 box-col-12">
                  </Col>  
                    <Col xl="6 box-col-12">
                        <Card>
                            <div className="blog-box blog-shadow">
                                <Media className="img-fluid" src={blog} alt="" />
                                <div className="blog-details">
                                    <p className="digits">{"Ultima Actualización 09/05/2022"}</p>
                                    <h4>{"Te damos la bienvenida a GBN cotiza"}</h4>
                                </div>
                            </div>
                        </Card>
                    </Col>
                                               
               
                    <Col xl="6 box-col-12">
                                
                        <Card>
                        <Card>
                        </Card>
                        <Card>
                        <Button className="btn-pill" sz="lg" color="secondary" id="proveedor" onClick={vende}>{"VENDE TUS PRODUCTOS AQUI"}</Button>        
                                                 
                                                 <Button className="btn-pill" sz="lg" color="primary" id="comprador"onClick={cotizaaqui}>{"COTIZA   AQUI"}</Button>        
                                                 </Card>
                            <div className="blog-box blog-list row">
                                <Col sm="5">
                                <CardBody className="btn-showcase">
                <Button className="btn-pill" size="lg" color="primary" onClick={tuto}>{"Tutorial"}</Button>
                </CardBody>
                                </Col>
                                <Col sm="7">
                                    <div className="blog-details">
                                        <h6>{"Gestiona los procesos de tu negocio ABASTECIMIENTO y VENTAS."} </h6>
                                        <div className="blog-bottom-content">                                            
                                        </div>
                                    </div>
                                    
                                    
                                </Col>
                                 <Col sm="5">
                                <CardBody className="btn-showcase">
                <Button className="btn-pill" size="lg" color="secondary" onClick={edcat}>{"Elegir categoria"}</Button>
                </CardBody>
                                </Col>
                                <Modal isOpen={modal2} toggle2={toggle2} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <Col md="12">
                      <div className="loader-box">
                        <div  className="loader-19"></div>
                      </div>
                    </Col>
                        </ModalBody>
                        </Modal>
                        <Modal isOpen={modal3} toggle3={toggle3} size="lg" style={{width:'2000px'}}  centered>
                        <ModalHeader toggle={toggle3}>{"Categorias disponibles"}
                        </ModalHeader>
                        <ModalBody>
                       <CategoryUnspc/>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="secondary" onClick={toggle3}>{"Cancelar"}</Button>
                        <Button color="primary" onClick={buscar}>{"Siguiente"}</Button> 
                        </ModalFooter>
                    </Modal>
                   
                        <Modal isOpen={modal5} toggle5={toggle5} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <h4 className="mb-4">{"Estas seguro de guardar los siguientes cambios: "}</h4>
                        <h6 className="mb-1">{catup.join()}</h6>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle5}>{"cancelar"}</Button>
                        <Button color="secondary" onClick={Guardarcat}>{"Guardar"}</Button>
                        </ModalFooter>
                    </Modal>
                                <Col sm="7">
                                    <div className="blog-details">
                                        <h6>{"¿Quieres llegar a mas clientes?, elige una categoria de productos"} </h6>
                                        <div className="blog-bottom-content">                                            
                                        </div>
                                    </div>
                                    
                                    
                                </Col>
                                
                                {/* {sas.map(data => (
                <Col key={data.id}>
                <Card className="sass-widgets o-hidden">
                    <CardBody className="p-0">
                    <div className="media">
                        <div className="media-body">
                        <p className="f-w-600">{data.title}</p>
                        <h2 className="f-w-600 mb-0">{data.total}</h2>
                        </div>
                        <div className="setting-dot d-inline-block">
                        <div className={`setting-bg setting-bg-${data.class}`}><i className={`fa fa-spin fa-cog font-${data.class}`}></i></div>
                        </div>
                    </div>
                    <div className={`bg-gradient-${data.class} footer-shape`}>
                        <div className="sass-footer">
                        <p className="mb-0 d-inline-block mr-3">{data.monthly}</p><span><span className="d-inline-block"><i className="fa fa-sort-up mr-4"></i></span></span>
                        <p className={`mb-0 d-inline-block b-l-${data.class} pl-4 mr-3`}>{data.weekly}</p><span className="down-arrow-align"><span className="d-inline-block"><i className="fa fa-sort-down"></i></span></span>
                        <div className="small-sass">
                            <div className="small-sasschart flot-chart-container">
                            <ChartistGraph data={data.chartData} listener={sassSmallChartListener} options={sassSmallChartOptions} type={'Bar'} />
                            </div>
                        </div>
                        </div>
                    </div>
                    </CardBody>
                </Card>
                </Col>
            ))} */}
                            </div>
                        </Card>
                        
                        </Col> 
                       
           

                </Row>
                <Modal  isOpen={modalb} className="welcome-popup modal-dialog-centered ">
            <button  onClick={toggleb} className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <ModalBody>
              <ModalHeader></ModalHeader>
              <div className="contain p-50">
                <div className="text-center">
                  <h3>{"Bienvenido a B2Buysel"}</h3>
                  <p>{"..."} </p>
                  <button 
                    onClick={toggleb}
                    className="btn btn-primary btn-lg txt-white" 
                    type="button" data-dismiss="modal" 
                    aria-label="Close">{"Comienza Ahora"}</button>
                </div>
              </div>
            </ModalBody>
          </Modal>

            </Container>            
        </Fragment>
    )
                        }
                        
export default Bienvenida;
