import React, {useState,Fragment} from 'react'
import BreadCrumb from '../../layout/Breadcrumb'
import { Container, Row, Col, Card, CardBody, Table,Button, ButtonModal, ModalHeader, ModalBody, ModalFooter, Modal , FormGroup} from 'reactstrap'
import { RFQS, RFQ, } from '../../constant';
import axios from 'axios';
import { useEffect } from 'react';
import {Link} from 'react-router-dom'
import { toast } from 'react-toastify';
import {useSelector} from 'react-redux'
import Tour from 'reactour'
import {disableBodyScroll,enableBodyScroll} from 'body-scroll-lock'



const Url ="https://8751991ba23d.ngrok.io/gbn/segundalista"
const getdata = async () =>{
  const response = axios.get(Url);
  console.log(response);
  return response;
  
}
const steps = [
  {
      selector: '.ep1',
      content: 'Esta es la lista de RFQs creados por nuestros compradores',
  },
  {
      selector: '.ep2',
      content: 'Pulsa este boton para ver los detalles, asignar un precio y aceptar un RFQ',
  },
//   {
//     selector: '.ep3',
//     content: 'Abre el menu y selecciona una opción, podrás encontrar un tutorial para cada una de las secciones',
// }
]
const RFQSList = () => {

  //tour
  const [opentour,setopentour] = useState(false)
  const closeTour = () => {
      setopentour(false);
    };

    const tuto = () =>{
      setTimeout(() => {
        setopentour(true);
      }, 350);
    }

    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);

//lo demas

  const currentUser= useSelector(state => state.Common.currentUser)


 const [modale, setModale] = useState();


  const [list, setlist] = useState([])


  const [seglist, setseglist] = useState([])

  useEffect(() => {
    getdata().then(( response ) =>{
      setseglist(response.data);
  
    })
  }, [])
    const notificar = (datos) =>{ //llamar api para datos, conseguir email del creador del rfq
      console.log('notificar a', datos) //aqui es donde se manda el email al creador del RFQ notificandole que alguien acepto su RFQ
    }
    const [seleccionado, setseleccionado] = useState({
        RFQsl:'',
        Empresa_Solicitante:'',
        Descripcion:'',
        Cantidad_Solicitada:'',

        Fechavigencia:'',
        Estatussl:'',
        iduc:''
        
    });
    const [pre, setpre] = useState ("");
 
    const enviar = () => { 
      toast.warn("Has aceptado este RFQ, se ha enviado un Email al comprador, espera una respuesta pronto", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3150
        });

       
      axios.post('https://70ae6ed1cdbe.ngrok.io/gbn/aceptarprov', 
      
      {RFQsl:seleccionado.RFQsl,
      Empresa_solicitante:seleccionado.Empresa_solicitante,
      Categoria:seleccionado.Categoria,
      Descripcion:seleccionado.Descripcion,
      Cantidad_solicitada:seleccionado.Cantidad_Solicitada,
      Unidadmedida:seleccionado.Unidadmedida,
      Precioobj:pre,
      diasdecredito:seleccionado.diasdecredito, 
      Moneda:seleccionado.Moneda, 
      Fechavigencia:seleccionado.Fechavigencia, 
      lugaentrega:seleccionado.lugaentrega, 
      Estatussl:seleccionado.Estatussl,
      idui:currentUser.id,
      iduc:seleccionado.iduc
      }).then((response) => {
         
        setTimeout(()=>{
          window.location.href = `${process.env.PUBLIC_URL}/#/widgets/dashboardproveedor`
          window.location.reload();                  
          }, 300)

      console.log(response);
    });
    setModale(!modale)
    };

  const toggle = (data) => {
    setseleccionado(data)
    setModale(!modale)
   }
    return (
      <Fragment>
        <Tour
          steps={steps}
          rounded={5}
          isOpen={opentour}
          disableInteraction={true}
          disableKeyboardNavigation={false}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
           />
            <Container fluid={true}>
                <Row>
                  <Col xl="12" className="xl-100 box-col-12">
                    <Card className="widget-joins">
                      <Row>

                      </Row>
                    </Card>
                  </Col>
                  <Col >
                                <CardBody className="blockquote text-center">
                <Button size="lg" color="primary" onClick={tuto} >{"Tutorial"}</Button>
                </CardBody>
                                </Col>
                </Row>
                <Card>
                  <CardBody>
                    <div className="user-status table-responsive">
                      <Table className="ep1">                      
                        <thead>
                          <tr>
                            <th scope="col">{"RFQ"}</th>
                            <th scope="col">{"RFQ Invitado"}</th>
                            <th scope="col">{"Empresa Solicitante"}</th>
                            <th scope="col">{"Producto Solicitado"}</th>
                            <th scope="col">{"Cantidad Solicitada"}</th>
                            <th scope="col">{"Fecha de Solicitud"}</th>
                            <th scope="col">{"Estatus"}</th>
                            <th scope="col">{"Acciones"}</th>
                          </tr>
                        </thead>
                        <tbody>                      
                        {seglist.map(data => (
                        <tr >
                         <td>{data.RFQsl}</td>
                         <td>{"Propuesta abierta para todo el público"}</td>
                         <td>{data.Empresa_solicitante}</td>
                         <td>{data.Descripcion}</td>
                         <td>{data.Cantidad_Solicitada}</td>
                         <td>{data.Fechavigencia}</td>
                         <td>
                           <div className={`span badge badge-pill badge-primary`}>{data.Estatussl}</div>
                         </td>
                         <td className={`ep2`} ><Button  onClick={() => toggle(data)} >{"Aceptar"} {RFQ}</Button></td> 
                         <Modal isOpen={modale} toggle={toggle} className="modal-body"  centered={true}>
                         <ModalHeader toggle={toggle}>{'Aceptar RFQ'}
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                        <label> Precio: </label>
                        <input className="form-control" onChange= {(e) => {setpre(e.target.value)}}  placeholder='$'/>
                        </FormGroup>
                        <FormGroup>
                        <label> RFQ: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.RFQsl}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Categoria: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Categoria}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Empresa Solicitante:</label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Empresa_solicitante}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Producto Solicitado: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Descripcion}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Cantidad Solicitada: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Cantidad_Solicitada}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Fecha de vigencia: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Fechavigencia}/>
                        </FormGroup>
                        <FormGroup>
                        <label>Estatus: </label>
                        <input className="form-control"   readOnly type="text" value={seleccionado && seleccionado.Estatussl}/>
                        {/* añadir campo de attachment */}
                        </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="secondary" onClick={toggle}>{'Cerrar'}</Button>
                        <Button color="primary" onClick= {enviar}>{'Aceptar y enviar'}</Button> 
                        
                        </ModalFooter>
                    </Modal>
                      </tr>
                      ))}                         
                                              
                    </tbody>
                      </Table>
                    </div>
                  </CardBody>
              </Card>
            </Container>            
        </Fragment>
    )
                        }
                        
export default RFQSList;
