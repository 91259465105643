import React, { Fragment, useState, useEffect, } from 'react';
import BreadCrumb from '../../layout/Breadcrumb'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {cartProducts} from '../widgets/common/data'
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Form,FormGroup,Label,Input,Button, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap'
import Axios from 'axios';
import {useSelector} from 'react-redux'
import { Search } from 'react-feather';
import { Typeahead } from 'react-bootstrap-typeahead';
import emailjs from 'emailjs-com'
import SweetAlert from 'sweetalert2'

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CategoryUnspc from '../widgets/CategoryUnspc'

const CrearRFQ = () => { 
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  
  const[alert,setalert] = useState(false)
  const [modalcam, setmodalcam] = useState(false)
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => {
    setOpen(false)
    setTimeout(() => {  
    setModal2(!modal2);  
  }, 100);
  
  }
  const togglecam = () => {
     setmodalcam(false)
  }
  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);
  const [category,setcategory] = useState([]);
  const [open, setOpen] = useState(false);


  const modales = () =>{
    
    

    setModal3(!modal3)
    
    setTimeout(() => {
      setModal3(false);
      setModal2(!modal2)
  }, 1900);
 

  }
 const [texsto, settexsto] =useState('');


 const seleccionando = () =>{
  const cat = JSON.parse(localStorage.getItem('selectedc'))
   if (cat == null) {
     
    SweetAlert.fire({title:"No se encontraron resultados", text:"Escribe en el campo para buscar una categoria", icon:"error"});        
    
   }else{
    cat.map(data =>{
      const opcion = data.code + " - " + data.esp
      setcategory(opciones=>[...opciones, opcion])  
      
    })

    category.forEach(function(elemento, indice, array) {   
      
    })
  setOpen(false)
  setModal3(!modal3)
  setModal2(false)
  setTimeout(() => {
    setModal3(false)
    setmodalcam(true)

}, 1000);
}
 }
 const Guardarcam = () => {
  setcatreg(category.join())
  setModal3(true)
  setTimeout(() => {
    setModal3(false)
    setmodalcam(false)
    settexsto(category.join())
    document.getElementById('hola2').style.display = 'none';
    SweetAlert.fire({title:"¡Felicidades!", text:"Has seleccionado tu categoria con exito", icon:"success"});

}, 1000);

 }

const [autocompleteValues, setAutocompleteValues] = useState([]);

  const handleChange = (event, value) => {

    setAutocompleteValues(value);
  };


  const multiple = false
    const [options,setOptions] = useState([])
    const currentUser= useSelector(state => state.Common.currentUser)
    
   
   

  const [empresa, setempresa] = useState("");
  useEffect(()=>{ 
    setempresa(currentUser.Empresa)
   
},[])



  const [modale, setModale] = useState();

  const toggle = () => {
    setModale(!modale)
   }


  const [RFQreg, setRFQreg] = useState("");
  const [edreg, setedreg] = useState("");
  const [esreg, setesreg] = useState("");
  const [catreg, setcatreg] = useState("");
  const [desreg, setdesreg] = useState("");
  const [csreg, setcsreg] = useState("");
  const [umreg, setumreg] = useState("");
  const [preoreg, setpreoreg] = useState("");
  const [diasreg, setdiasreg] = useState("");
  const [monreg, setmonreg] = useState("");
  const [fechareg, setfechareg] = useState("");
  const [lugreg, setlugreg] = useState("");
  const [estreg, setestreg] = useState("");
  const [archivosreg, setarchivosreg] = useState("");
  
  
  


  const crearrfq = () => { 
    
    toast.warn("Tu RFQ ha sido posteado y ofertado entre los proveedores :)", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2500
      });

        
        setTimeout(()=>{
          window.location.href = `${process.env.PUBLIC_URL}/#/widgets/dashboardcomprador`
          window.location.reload();                  
          }, 3500)
        

    Axios.post('https://70ae6ed1cdbe.ngrok.io/gbn/crearrfq', 
     
    {RFQsl:RFQreg,
    Empresa_solicitante:empresa,
    Categoria:category.join(),
    Descripcion:desreg,
    Cantidad_Solicitada:csreg,
    Unidadmedida:umreg,
    Precioobj:preoreg,
    diasdecredito:diasreg, 
    Moneda:monreg, 
    Fechavigencia:fechareg, 
    lugaentrega:lugreg, 
    Estatussl:estreg,
    iduc:currentUser.id,
    // creadopor:currentuser.id
    }).then((response) => {
      Axios.get('https://70ae6ed1cdbe.ngrok.io/gbn/usuariosanotificar', { 
 params: {
  foo: catreg
}}    
        ).then((response) => {
            var usuariosanotificar = response.data
      usuariosanotificar.forEach(usuario=>{
        var params = {
          fromName:currentUser.Nombre, 
          toName:usuario.Nombre, 
          toEmail:usuario.Correo,
          RFQreg:RFQreg,
          empresa:empresa,
          catreg:catreg,
          desreg:desreg,
          csreg:csreg,
          umreg:umreg,
          preoreg:preoreg,
          diasreg:diasreg, 
          monreg:monreg, 
          fechareg:fechareg, 
          lugreg:lugreg, 
          estreg:estreg,
  
        }
          emailjs.send('service_ruxs3u7', 'template_np892ov', params, 'user_6Yiy6j6dBtbUgO2vEhYPP').then((res)=>{
             console.log('emailjs', res)
          })
        console.log('notificar a', usuario)
      })
      console.log('1', response)
  }); });
  setModale(!modale)
};

const cancel = () =>{

  window.location.href = `${process.env.PUBLIC_URL}/#/widgets/dashboardcomprador`
 
 setTimeout(()=>{
  window.location.reload();                  
  }, 800)
}

  return (
    
    <Fragment>
      <Container fluid={true}>
        <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h4>{"Crear y enviar un RFQ"}</h4>
                </CardHeader>
                <CardBody>
                  <Form className="theme-form mega-form">
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Nombre del Producto (RFQ)'}</Label>
                        <Input type={"text"} onChange= {(e) => {setRFQreg(e.target.value)}}placeholder={""} required/>
                    </FormGroup>                  
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Nombre de mi empresa'}</Label>
                        <Input type={"text"} onChange= {(e) => {setesreg(e.target.value)}} value={empresa}/>
                    </FormGroup>    
                    <Input type={"text"} value={texsto}></Input>                  
                      <FormGroup>
                      <Button id="hola2" color="primary" className="sweet-12" name="advanceSuccess" onClick={modales}>{"Elegir categoria"}</Button>
                    <Modal isOpen={modal2} toggle={toggle2}  size="lg" style={{width:'2000px'}} centered>
                        <ModalHeader toggle={toggle2}>{"Elige uno o varios productos"}
                        </ModalHeader>
                        <ModalBody>
                        <CategoryUnspc/>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle2}>{"Cerrar"}</Button>
                        <Button id="presionxd" color="secondary" onClick={seleccionando}>{"Seleccionar"}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={modal3} toggle3={toggle3} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <Col md="12">
                      <div className="loader-box">
                        <div  className="loader-19"></div>
                      </div>
                    </Col>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={modalcam} toggle={togglecam} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <h4 className="mb-4">{"Estas seguro de guardar los siguientes cambios: "}</h4>
                        <h6 className="mb-1">{category.join()}</h6>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={togglecam}>{"cancelar"}</Button>
                        <Button color="secondary" onClick={Guardarcam}>{"Guardar"}</Button>
                        </ModalFooter>
                    </Modal>

                      </FormGroup>
            
                    <FormGroup>
                      <Label className="col-form-label">{"Cantidad"}</Label>
                      <Input className="form-control" type="number"onChange= {(e) => {setcsreg(e.target.value)}} placeholder="0" />
                    </FormGroup>
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Unidad de medida'}</Label>
                        <Input type="select" name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7" onChange= {(e) => {setumreg(e.target.value)}} >
                        <option>{""}</option>
                          <option>{"Pieza (PZA)"}</option>
                          <option>{"Kilogramo (KG)"}</option>
                          <option>{"Litro (LTO)"}</option>
                          <option>{"Tonelada (TON)"}</option>
                          <option>{"Galón (GL)"}</option> 
                          <option>{"Caja"}</option>
                          <option>{"Conjunto"}</option>
                          <option>{"Kit"}</option>
                          <option>{"Unidad"}</option>
                          <option>{"Docena de piezas"}</option>     
                          <option>{"Lote"}</option>        
                        </Input>
                    </FormGroup>
                    {/* <FormGroup>
                    <Label className="col-form-label pt-0">{"Precio Objetivo/Autorizado"}</Label>
                        <Input type={"text"}onChange= {(e) => {setpreoreg(e.target.value)}}  />
                    </FormGroup> */}
                    <FormGroup>
                    <Label className="col-form-label pt-0">{"Días de crédito"}</Label>
                        <Input type={"number"} onChange= {(e) => {setdiasreg(e.target.value)}} placeholder={""} />
                    </FormGroup>  
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Moneda de Cotización'}</Label>
                        <Input type="select" name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7"onChange= {(e) => {setmonreg(e.target.value)}} >
                        <option>{""}</option>
                          <option>{"MXN"}</option>
                          <option>{"USD($)"}</option>
                          <option>{"EUR(€)"}</option>
                          <option>{"JPY(¥)"}</option>
                          <option>{"(RMB)"}</option>
                        </Input>                        
                    </FormGroup>
                    <FormGroup>
                    <Label className="col-form-label pt-0">{"Fecha de vigencia de la cotización"}</Label>
                        <Input  type={"date"} onChange= {(e) => {setfechareg(e.target.value)}}placeholder={""} />
                    </FormGroup>  
                    <FormGroup>               
                    <Label className="col-form-label pt-0">{"Lugar de entrega"}</Label>
                        <Input type={"address"} onChange= {(e) => {setlugreg(e.target.value)}}placeholder={""} /> 
                    </FormGroup>  
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Descripción del producto'}</Label>
                        <Input type="textarea" className="form-control" onChange= {(e) => {setdesreg(e.target.value)}} />
                    </FormGroup> 
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Estado del RFQ (Seleccione una opción)'}</Label>
                        <Input type="select" name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7" onChange= {(e) => {setestreg(e.target.value)}}>
                        <option>{""}</option>
                          <option>{"Normal"}</option>
                          <option>{"Prioritario"}</option>
                          <option>{"Urgente"}</option>
                        </Input>                        
                    </FormGroup>
                    {/* <div className="dz-message needsclick">
                                  <Dropzone maxFiles={1} multiple={false} canCancel={false} inputContent="Aqui importa tus archivos"
                                      styles={{
                                          dropzone: {height: 200 },
                                          dropzoneActive: { borderColor: 'green' },
                                        }}
                                       onChange= {(e) => {setarchivosreg(e.target.value)}}
                                  />
                              </div> */}
                              <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">{"Adjunta archivos a tu RFQ"}</Label>
                        <Col sm="9">
                          <Input className="form-control" type="file" onChange= {(e) => {setarchivosreg(e.target.value)}}/>
                        </Col>
                      </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter>
                  {/* <td>
                   <Link to={`${process.env.PUBLIC_URL}/widgets/RFQSList`}> 
                    <Button color="success" className="sweet-8" name="alertSuccess" onClick= {crearrfq}>{'Enviar'}</Button> {''}
                    <Button color="primary"  href="RFQSLIst">{"Cancelar envio"}</Button>                  
                    </Link>    
                  </td> */}
                  <td>
                  <Button color="primary" onClick={cancel} >{"Cancelar envio"}</Button>  {''}                 
                  <Button onClick={() => setModale(!modale)} >{"Crear RFQ"}</Button> {''}
                  </td>
                  <Modal isOpen={modale} toggle={toggle} className="modal-body"  centered={true}>
                         <ModalHeader toggle={toggle}>{'¿Estas seguro de crear y enviar un nuevo RFQ?'}
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                        <label>
                 <h6> </h6>
                 <h6> Si aceptas y envias, este RFQ será enviado a la lista de proveedores</h6>
                 <h7> Espera la mejor oferta en tu sección de comprador</h7>
              </label>
                        </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle}>{'Cerrar'}</Button>
                       <Button color="success" className="sweet-8" name="alertSuccess" onClick= {crearrfq}>{'Crear y Enviar'}</Button>                       
                        </ModalFooter>
                    </Modal>
                </CardFooter>            
              </Card>
            </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CrearRFQ;