import React, {useState,Fragment} from 'react'
import BreadCrumb from '../../layout/Breadcrumb'
import { Container, Row, Col, Card, CardBody, CardHeader, Button, CardFooter, Table, Input, Label, Form, FormGroup } from 'reactstrap'
import axios from 'axios';
import { useEffect } from 'react';
import {Link} from 'react-router-dom'
import { toast } from 'react-toastify';
import {useSelector} from 'react-redux'
import Tour from 'reactour'
import {disableBodyScroll,enableBodyScroll} from 'body-scroll-lock'

const steps = [
  {
      selector: '.p1',
      content: 'Esta es la lista de Proveedores dentro de B2Buysel',
  },
  {
      selector: '.p2',
      content: 've a tu sección de compradores y crea un RFQ dependiendo la categoria que te interese',
  },

]

      const URL ="https://3dd8376333ed.ngrok.io/gbn/listprov"
      const getData = async () =>{
        const response = axios.get(URL);
        console.log(response);
        return response;
        
      }
const RFQSprov = () => {
 //tour
 const [opentour,setopentour] = useState(false)
 const closeTour = () => {
     setopentour(false);
   };

   const tuto = () =>{
     setTimeout(() => {
       setopentour(true);
     }, 350);
   }

   const disableBody = target => disableBodyScroll(target);
   const enableBody = target => enableBodyScroll(target);

   //lod emas
    const [date, setDate] = useState({date: new Date()});
    const handleChange = date => {
        setDate(date)
      };

      const [seglist, setseglist] = useState([])

  useEffect(() => {
    getData().then(( response ) =>{
      setseglist(response.data);
  
    })
  }, [])

    return (
      <Fragment>
        <Tour
          steps={steps}
          rounded={5}
          isOpen={opentour}
          disableInteraction={true}
          disableKeyboardNavigation={false}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
           />
        <Container fluid={true}>
                <Row>
                  <Col xl="12" className="xl-100 box-col-12">
                    <Card className="widget-joins">
                      <Row>

                      </Row>
                    </Card>
                  </Col>
                  <CardBody className="blockquote text-center">
            <Button className="btn-pill" color="primary" size="lg"  onClick={tuto}>{"Tutorial"}</Button>
            </CardBody>
                </Row>
                <Card>
                  <CardBody>
                    <div className="user-status table-responsive">
                      <Table className="p1" >                      
                        <thead>
                          <tr>
                            <th scope="col">{"Empresa"}</th>
                            <th scope="col">{"Categoria de productos"}</th>
                            {/* <th scope="col">{"Correo"}</th> */}
                          </tr>
                        </thead>
                        <tbody>                      
                        {seglist.map(data => (
                        <tr >
                         <td>{data.Empresa}</td>
                         <td className={`p2`}>{data.Categoria}</td>
                         {/* <td>{data.Correo}</td> */}
                         <td>
                           <div className={`span badge badge-pill badge-primary`} >{"Activo"}</div>
                         </td>                     
                      </tr>
                      ))}                                                                       
                    </tbody>
                      </Table>
                    </div>
                  </CardBody>
              </Card>
            </Container>   
        
        </Fragment>
    )
}

export default RFQSprov
