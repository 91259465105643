// Dashboard
import Default from '../components/dashboard/Default'
import Hospital from '../components/dashboard/Hospital'
import Sass from '../components/dashboard/Sass'
import Crm from '../components/dashboard/Crm'
import Crypto from '../components/dashboard/Crypto'

// Base
import Listaprov from '../components/widgets/RFQSprov'
import RFQSList from '../components/widgets/RFQSList'
import DashboardProveedor from '../components/widgets/DashboardProveedor'
import DashboardComprador from '../components/widgets/DashboardComprador'
import CrearRFQ from '../components/widgets/CrearRFQ'
import Bienvenida from '../components/widgets/Bienvenida'
import Pago from '../components/widgets/Pago'
import Check from '../components/widgets/Check'
import Pago2 from '../components/widgets/Pago2'
import Check2 from '../components/widgets/Check2'
import Resetpwd from "../pages/authentication/Resetpwd";


import ChartWidget from '../components/widgets/Charts'
import StateColor from '../components/base/Statecolor'
import Typography from '../components/base/Typography'
import Avatars from '../components/base/Avatars'
import HelperClasses from '../components/base/HelperClasses'
import Grid from '../components/base/Grid'
import TagAndPills from '../components/base/TagAndPills'
import Progress from '../components/base/Progress'
import Modal from '../components/base/Modal'
import Alert from '../components/base/Alert/Alert'
import Popover from '../components/base/Popover/Popover'
import Tooltip from '../components/base/Tooltip/Tooltip'
import Spinner from '../components/base/Spinner'
import Dropdown from '../components/base/DropDown/Dropdown'
import TabBootstrap from '../components/base/Tabs/TabBootstrap'
import TabLine from '../components/base/Tabs/TabLine'
import Accordian from '../components/base/Accordian/Accordian'
import Navs from '../components/base/Nav/Navs'
import Shadow from '../components/base/Shadow'
import List from '../components/base/List'

// Advance 
import Scrolling from '../components/advance/Scrolling'
import BootstrapNotify from '../components/advance/BootstrapNotify'
import Rating from '../components/advance/Rating'
import Dropzone from '../components/advance/Dropzone'
import SweetAlert from '../components/advance/SweetAlert'
import Tour from '../components/advance/Tour'
import Sticky from '../components/advance/Sticky'
import Breadcrumb from '../components/advance/Breadcrumb'
import Imagecrop from '../components/advance/Imagecrop'
import RangeSlider from '../components/advance/RangeSlider'
import Carousel from '../components/advance/carousel/Carousel'
import Pagination from '../components/advance/Pagination'
import Steps from '../components/advance/Steps/Steps'
import DragAndDrop from '../components/advance/DragAndDrop'
import UploadImage from '../components/advance/uploadImage'

// todo-app
 import Todo from '../components/application/todo-app/todo'

 // todo-firebase-app
 import TodoFirebase from '../components/application/todo-firebase-app/todo-firebase'

 // E-commerce-app 
 import Product from '../components/application/ecommerce-app/product'
 import ProductDetail from '../components/application/ecommerce-app/productpage'
 import Cart from '../components/application/ecommerce-app/Cart'
 import Wishlist from '../components/application/ecommerce-app/Wishlist'
 import Productlist from '../components/application/ecommerce-app/productlist'
 import Paymentdetails from '../components/application/ecommerce-app/paymentdetails'
 import OrderHistory from '../components/application/ecommerce-app/OrderHistory'
 import Checkout from '../components/application/ecommerce-app/Checkout'
 import Invoice from '../components/application/ecommerce-app/Invoice'

 // Chat 
 import Chat from '../components/application/chat-app/Homepage'

// Social App 
import SocialApp from '../components/social-app/SocialApp'

// Job Search 
import CardView from '../components/jobSearch/CardView'
import JobList from '../components/jobSearch/Job-list'
import JobDetail from '../components/jobSearch/Job-detail'
import JobApply from '../components/jobSearch/Job-apply'

 // Email
 import Email from '../components/application/email-app/emailDefault'

 // Contact app
 import ContactApp from '../components/application/contact-app/contactApp'
 import NewUser from '../components/application/contact-app/new-user' 
 import EditUser from '../components/application/contact-app/edit-user'

 // Forms
import FormValidation from '../components/forms/form-control/form-validation'
import BaseInput from '../components/forms/form-control/baseInput'
import InputGroup from '../components/forms/form-control/InputGroup'
import MegaOption from '../components/forms/form-control/MegaOption'
import CheckboxandRadio from '../components/forms/form-control/CheckboxandRadio'

// Form Layout
import FormDefault from '../components/forms/form-layout/formDefault'
import FormWizard1 from '../components/forms/form-layout/form-wizard-1/Formwizard1'

 // Forms widgets
import Datepicker from '../components/forms/form-widget/datepicker'
import Timepicker from '../components/forms/form-widget/timepickerComponent/timepicker'
import Typeahead from '../components/forms/form-widget/typeaheadComponent/typeahead'

// Tabels
import BasicTabels from '../components/tables/BasicTable'
import BorderTable from '../components/tables/BorderTable'
import SizingTable from '../components/tables/SizingTable'
import StylingTable from '../components/tables/StylingTable'
import DataTable from '../components/tables/DataTable'

// Cards
import BasicCards from '../components/cards/BasicCards'
import CreativeCards from '../components/cards/CreativeCards'
import TabCard from '../components/cards/TabCard'
import DraggingCards from '../components/cards/DraggingCards'

// Users
import UserProfile from '../components/users/UserProfile'
import UserEdit from '../components/users/UserEdit'
import UserCards from '../components/users/UserCards'

// Calendar
import BasicCalender from '../components/calendar/BasicCalendar'
import DraggableCalendar from '../components/calendar/DraggableCalendar'

// Icons
import FlagIcons from '../components/icons/FlagIcons'
import FontAwsomeIcon from '../components/icons/FontAwsomeIcon'
import IcoIcons from '../components/icons/IcoIcons'
import ThemifyIcons from '../components/icons/ThemifyIcons'
import FeatherIcons from '../components/icons/FeatherIcons'
import WeatherIcons from '../components/icons/WeatherIcons'

import Materialdesignicon from '../components/icons/Materialdesignicon'
import Pe7icons from '../components/icons/Pe7icons'
import Typicon from '../components/icons/Typicon'
import Simplelineicon from '../components/icons/Simplelineicon'
import Ionicicon from '../components/icons/Ionic-icon'

// Buttons 
import DefaultButton from '../components/buttons/Default-Button'
import EdgeButton from '../components/buttons/EdgeButton'
import FlatButton from '../components/buttons/FlatButton'
import GroupButton from '../components/buttons/GroupButton'
import RaisedButton from '../components/buttons/RaisedButton'

// Gallary
import ImageGallery from '../components/gallery/ImageGallery'
import ImageWithDesc from '../components/gallery/ImageWithDesc'
import MesonryGallery from '../components/gallery/MesonryGallery'
import MesonryDesc from '../components/gallery/MesonryDesc'
import ImageHover from '../components/gallery/ImageHover'


// Timelines
import Timeline1 from '../components/timelines/Timeline'
import Timeline2 from '../components/timelines/Timeline2'


// Charts 
import Apexcharts from "../components/charts/ApexCharts/Apexcharts";
import GoogleCharts from "../components/charts/GoogleCharts/GoogleCharts";
import KnobChart from "../components/charts/KnobChart/KnobChart";
import Chartsjs from "../components/charts/Chartsjs/Chartsjs";
import Chartist from "../components/charts/ChartistCharts/Chartist";

// Maps
import GoogleMap from '../components/map/GoogleMap'
import LeafletMap from '../components/map/LeafletMap'


// Editor
import CkEditor from '../components/editor/CkEditor'
import MdeEditor from '../components/editor/MdeEditor'

// Blog
import BlogDetail from '../components/blog/BlogDetail'
import BlogSingle from '../components/blog/BlogSingle'
import BlogPost from '../components/blog/BlogPost'

// Learning

import Learninglist from '../components/learning/Learning-list'
import LearningDeatil from "../components/learning/Learning-deatil";


// Pricing
import Pricing from '../components/price/Pricing'

// Sample page 
import Sample from "../components/sample/Sample";


// Search page 
import Search from "../components/search/Search";

// FAQ page 
import FaqComponent from "../components/faq/FaqComponent";

// knowledgebase page 
import KnowledgebaseComponent from "../components/knowledgebase/KnowledgebaseComponent";

// Support Ticket
import SupportTicket from "../components/support-ticket/supportTicket";

export const routes = [
  { path: '/dashboard/default', Component: Default },
  { path: "/dashboard/hospital",Component: Hospital },
  { path: "/dashboard/sass",Component: Sass},
  { path: "/dashboard/crm",Component: Crm},
  { path: "/dashboard/crypto",Component: Crypto},
  { path: "/widgets/RFQSprov",Component: Listaprov},
  { path: "/widgets/RFQSList",Component: RFQSList},
  { path: "/widgets/dashboardproveedor",Component: DashboardProveedor},
  { path: "/widgets/dashboardcomprador",Component: DashboardComprador},
  { path: "/widgets/CrearRFQ",Component: CrearRFQ},
  { path: "/widgets/Bienvenida",Component: Bienvenida},
  { path: "/widgets/Pago",Component: Pago},
  { path: "/widgets/Check",Component: Check},
  { path: "/widgets/Pago2",Component: Pago2},
  { path: "/widgets/Check2",Component: Check2},


  { path: "/authentication/Resetpwd",Component: Resetpwd},

  { path: "/base/statecolor",Component: StateColor},
  { path: "/base/typography",Component: Typography},
  { path: "/base/avatars",Component: Avatars},
  { path: "/base/helperclasses",Component: HelperClasses},
  { path: "/base/grid",Component: Grid},
  { path: "/base/tag-pills",Component: TagAndPills},
  { path: "/base/progress-bar",Component: Progress},
  { path: "/base/modal",Component: Modal},
  { path: "/base/alert",Component: Alert},
  { path: "/base/popover",Component: Popover},
  { path: "/base/tooltips",Component: Tooltip},
  { path: "/base/spinner",Component: Spinner},
  { path: "/base/dropdown",Component: Dropdown},
  { path: "/base/tab-bootstrap",Component: TabBootstrap},
  { path: "/base/tab-line",Component: TabLine},
  { path: "/base/accordion" ,Component: Accordian},
  { path: "/base/navs" ,Component: Navs},
  { path: "/base/shadow" ,Component: Shadow},
  { path: "/base/list" ,Component: List},

  { path: "/advance/scrollable" ,Component: Scrolling},
  { path: "/advance/bootstrap-notify" ,Component: BootstrapNotify},
  { path: "/advance/rating" ,Component: Rating},  
  { path: "/advance/dropzone" ,Component: Dropzone},
  { path: "/advance/sweetAlert" ,Component: SweetAlert},
  { path: "/advance/tourComponent" ,Component: Tour},
  { path: "/advance/breadcrumb" ,Component: Breadcrumb},
  { path: "/advance/rangeSlider" ,Component: RangeSlider},
  { path: "/advance/imageCropper" ,Component: Imagecrop},
  { path: "/advance/stickyNotes" ,Component: Sticky},
  { path: "/advance/carousel" ,Component: Carousel},
  { path: "/advance/pagination" ,Component: Pagination},
  { path: "/advance/steps" ,Component: Steps},
  { path: "/advance/dragNDropComp" ,Component: DragAndDrop},
  { path: "/advance/image-upload" ,Component: UploadImage},

  { path: "/todo-app/todo" ,Component: Todo},

  { path: "/todo-app/todo-firebase" ,Component: TodoFirebase},

  { path: "/ecommerce/product" ,Component: Product},
  { path: "/ecommerce/product-detail/:id" ,Component: ProductDetail},
  { path: "/ecommerce/cart" ,Component: Cart},
  { path: "/ecommerce/wishlist" ,Component: Wishlist},
  { path: "/ecommerce/checkout" ,Component: Checkout},
  { path: "/ecommerce/invoice" ,Component: Invoice},
  { path: "/ecommerce/product-list" ,Component: Productlist},
  { path: "/ecommerce/payment-details" ,Component: Paymentdetails},
  { path: "/ecommerce/orderhistory" ,Component: OrderHistory},

  { path: "/Chat-app/chat" ,Component: Chat},

  { path: "/social-app/socialApp" ,Component: SocialApp},

  { path: "/jobSearch/cardView" ,Component: CardView},
  { path: "/jobSearch/job-list" ,Component: JobList},
  { path: "/jobSearch/job-detail" ,Component: JobDetail},
  { path: "/jobSearch/job-apply" ,Component: JobApply},

  { path: "/Email-app/emailDefault" ,Component: Email},

  { path: "/contact-app/contact" ,Component: ContactApp},
  { path: "/contact-app/new-user" ,Component: NewUser},
  { path: "/contact-app/edit-user/:id" ,Component: EditUser},

  { path: "/forms/form-validation" ,Component: FormValidation},
  { path: "/forms/baseInput" ,Component: BaseInput},
  { path: "/forms/inputGroup" ,Component: InputGroup},
  { path: "/forms/megaOptions" ,Component: MegaOption},
  { path: "/forms/radio-checkbox" ,Component: CheckboxandRadio},
  { path: "/forms/formDefault" ,Component: FormDefault},
  { path: "/forms/formWizard" ,Component: FormWizard1},

  { path: "/form-widget/datepicker" ,Component: Datepicker},
  { path: "/form-widget/timepicker" ,Component: Timepicker},
  { path: "/form-widget/typeahead" ,Component: Typeahead},

  { path: "/table/basic" ,Component: BasicTabels},
  { path: "/table/border" ,Component: BorderTable},
  { path: "/table/sizing" ,Component: SizingTable},
  { path: "/table/styling" ,Component: StylingTable},
  { path: "/table/datatable" ,Component: DataTable},

  { path: "/cards/basicCards" ,Component: BasicCards},
  { path: "/cards/creativeCards" ,Component: CreativeCards},
  { path: "/cards/tabCard" ,Component: TabCard},
  { path: "/cards/draggingCards" ,Component: DraggingCards},
  
  { path: "/users/userProfile" ,Component: UserProfile},
  { path: "/users/userEdit" ,Component: UserEdit},
  { path: "/users/userCards" ,Component: UserCards},

  { path: "/calendar/basic-calendar" ,Component: BasicCalender},
  { path: "/calendar/draggable-calendar" ,Component: DraggableCalendar},

  { path: "/icons/flagIcons" ,Component: FlagIcons},
  { path: "/icons/fontAwsomeIcon" ,Component: FontAwsomeIcon},
  { path: "/icons/icoIcons" ,Component: IcoIcons},
  { path: "/icons/featherIcons" ,Component: FeatherIcons},
  { path: "/icons/themifyIcons" ,Component: ThemifyIcons},
  { path: "/icons/weatherIcons" ,Component: WeatherIcons},

  { path: "/icons/material-design-icon" ,Component: Materialdesignicon},
  { path: "/icons/pe7-icon" ,Component: Pe7icons},
  { path: "/icons/typicons-icon" ,Component: Typicon},
  { path: "/icons/simplelineIcon" ,Component: Simplelineicon},
  { path: "/icons/ionic-icon" ,Component: Ionicicon},

  { path: "/buttons/default-btn" ,Component: DefaultButton},
  { path: "/buttons/flatBtn" ,Component: FlatButton},
  { path: "/buttons/edgeBtn" ,Component: EdgeButton},
  { path: "/buttons/raisedBtn" ,Component: RaisedButton},
  { path: "/buttons/groupBtn" ,Component: GroupButton},

  { path: "/gallery/imageGallery" ,Component: ImageGallery},
  { path: "/gallery/imageWithDesc" ,Component: ImageWithDesc},
  { path: "/gallery/mesonryGallery" ,Component: MesonryGallery},
  { path: "/gallery/mesonryDesc" ,Component: MesonryDesc},
  { path: "/gallery/imageHover" ,Component: ImageHover},

  { path: "/timelines/timeline1" ,Component: Timeline1},
  { path: "/timelines/timeline2" ,Component: Timeline2},

  { path: "/charts/apexCharts" ,Component: Apexcharts},
  { path: "/charts/googleChart" ,Component: GoogleCharts},
  { path: "/charts/knobChart" ,Component: KnobChart},
  { path: "/charts/chartJs" ,Component: Chartsjs},
  { path: "/charts/chartistComponent" ,Component: Chartist},

  { path: "/map/googleMap" ,Component: GoogleMap},
  { path: "/map/LeafletMap" ,Component: LeafletMap},

  { path: "/editor/ckEditor" ,Component: CkEditor},
  { path: "/editor/mdeEditor" ,Component: MdeEditor},

  { path: "/blog/blogDetail" ,Component: BlogDetail},
  { path: "/blog/blogSingle" ,Component: BlogSingle},
  { path: "/blog/blogPost" ,Component: BlogPost},

  { path: "/learning/learning-list" ,Component: Learninglist},
  { path: "/learning/learning-detail" ,Component: LearningDeatil},

  { path: "/price/pricing" ,Component: Pricing},
  { path: "/sample/samplepage" ,Component: Sample},
  { path: "/search/searchpage" ,Component: Search},

  { path: "/faq/faqComponent" ,Component: FaqComponent},
  { path: "/knowledgebase/knowledgebaseComponent" ,Component: KnowledgebaseComponent},
  { path: "/support-ticket/supportTicket" ,Component: SupportTicket},
]
