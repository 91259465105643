import React, {useState} from 'react'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Bell, MessageCircle,ThumbsUp, Unlock } from 'react-feather';
import {All,Notification} from '../../constant'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

const BellDropdown = () => {
const [dropdownOpen, setDropdownOpen] = useState(false);
const toggle = () => setDropdownOpen(prevState => !prevState);


function notificationsLabel(count) {
  if (count === 0) {
    return 'no notifications';
  }
  if (count > 99) {
    return 'more than 99 notifications';
  }
  return `${count} notifications`;
}


const [contador, setcontador] = useState("1")

 const funcionres = () => {
   setcontador("0")
 }

return(
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      
    <DropdownToggle>
    
    
      {/* <Badge badgeContent={contador} color="error" onClick={funcionres}  > */}
        <Bell  />
      {/* </Badge> */}

    {/* <Bell/><span className="notification badge-pill badge-danger" style={{ height: "19px", width: "8px",background:"#cc0b0a"}}>{"1"}</span> */}
     </DropdownToggle>
     <DropdownMenu className="p-0">
     <ul className="notification-dropdown">
            <li className="gradient-primary-1">
              <h6>{"Notificaciones"}</h6>
            </li>
            <li>

              <div className="media">
                <div className="notification-icons bg-success mr-3"><Unlock className="mt-0" /></div>
                <div className="media-body">
                  <h6>{"Sin notificaciones"}</h6>
                  <p className="mb-0"> {"Comienza a interactuar para recibir notificaciones"}</p>
                </div>

              </div>
          
            </li>


            {/* <li className="pt-0">
              <div className="media">
                <div className="notification-icons bg-info mr-3"><MessageCircle className="mt-0" /></div>
                <div className="media-body">
                  <h6>{"3 New Comments"}</h6>
                  <p className="mb-0"> {"1 Hours Ago"}</p>
                </div>
              </div>
            </li> */}
            <li className="bg-light txt-dark"><a href="/#">{""}</a></li>
          </ul>
         
  

     </DropdownMenu>
      
 </Dropdown>
)
}

export default BellDropdown